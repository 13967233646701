import moment from "moment";
import numeral from "numeral";

// sintactic sugar
export const openPage = (ruta, history_explicit) => {
    return (ev) => {
        history_explicit.push(ruta)
        if(ev){ // for <a> links
            ev.preventDefault();
        }
    }
}

// sintactic sugar
export const openPageNow = (ruta, history_explicit, event) => {
    openPage(ruta, history_explicit)(event)
}

export const esRuta = (location, ruta) => {
    return location.pathname === ruta;
}

export const modificar_item_array_por_clave = (campo, valor, clave, array, clave_filtrar) => {
    // Helper para modificar algun objeto dentro de un array mediante clave_filtrar para poder filtrar
    const item_filtrado = array.find(item => item[clave_filtrar] === clave)
    if (item_filtrado) {
        const new_array =  [...array]
        item_filtrado[campo] = valor
        return new_array
    }
    return []
}

export const modificar_array = (array, index) => {
    // Crea un nuevo array con ?
    const item = array.splice(index, 1)
    const arr1 = array.splice(0, index)
    const arr2 = array.splice(index, array.length)
    return arr1.concat(item).concat(arr2)
}

export const cambiar_formato_numero = (numero) => {
    return parseFloat(numero.toString().replace(',', '.'))
}


export const fecha_fmt = (fecha, formato_opcional) => {
    const mm = moment(fecha);
    return mm.format(formato_opcional ? formato_opcional : 'DD/MM - HH:mm')
}

export const fechahora_fmt = (fecha, formato_opcional) => {
    return fecha_fmt(fecha, formato_opcional ? formato_opcional : 'DD/MM/YYYY HH:mm')
}

export const plata_fmt = (monto) => {
    return numeral(monto).format('0,00 $')
}

export const cantidad_fmt = (monto) => {
    return numeral(monto).format('0,0')
}

export const ciudades_to_str = (ciudades) => {
    return ciudades ? ciudades.map((c) => c.nombre).join(' • ') : '-'
}


export const minutos_to_str = (tiempo, compact) => {
    if (compact === undefined){
        compact = true
    }
    tiempo = parseInt(tiempo * 10.0 ) / 10.0
    let m = tiempo % 60
    let h = (tiempo - m) / 60
    if (compact) {
        return h > 0 ? `${h}h ${m.toFixed(0)}min` : `${m.toFixed(0)}min`
    }else{
        let h_str = h > 0 ? (h > 1 ? `${h} hs ` : `${h} h `) : ``
        let m_str = (m === 0 || m > 1)  ? `${m.toFixed(0)} min` : `${m.toFixed(0)} min`
        return h_str + m_str
    }
}

export const segundos_to_str = (tiempo, compact) => {
    if (compact === undefined){
        compact = true
    }
    let segs = parseInt(tiempo)
    let d = ''
    if (segs >= 86400){
        d = `${parseInt('' + segs/86400)}${compact?"d":'dias'} `
        segs = segs % 86400
    }
    return `${d}${minutos_to_str(parseInt('' + segs/60), compact)} ${segs%60}s`
}

export const distancia_km_fmt = (distancia_en_km) => {
    let ds = (distancia_en_km > 1.0 ? distancia_en_km : distancia_en_km * 1000.0).toLocaleString(undefined, {maximumFractionDigits: 1})
    return distancia_en_km > 1.0 ? `${ds} km` : `${ds} m`
}



export const titulos_pag = {}

export const getTituloPag = (match_fn) => {
    let match = match_fn()
    if (match !== undefined){
        let path = match.path
        let titulo = titulos_pag[path]
        if (titulo !== undefined && titulo !== '')
            return "ABi | " + titulo
    }
    return 'ABi'
}

export const string2leaflet_location = (strval) => {
    let ubicacion = strval.split(',')
    return {lng: parseFloat(ubicacion[1]), lat: parseFloat(ubicacion[0])}
}

export const leaflet_location2string = (loc) => `${loc.lat}, ${loc.lng}`

export const nroTelefonoLocal = (nroTelefono) => {
    if (nroTelefono) {
        if (nroTelefono.startsWith('+5959')) {
            nroTelefono = '09' + nroTelefono.substring(5)
        } else if (nroTelefono.startsWith('5959')) {
            nroTelefono = '09' + nroTelefono.substring(4)
        }
    }
    return nroTelefono
}

export const nroTelefonoInternacional = (nroTelefono) => {
    if (nroTelefono && nroTelefono.startsWith('09')) {
        nroTelefono = '595' + nroTelefono.substring(2).replaceAll('-', '')
    }
    return nroTelefono
}

export const formatCantidadCarrito = (cantidad)=>{ 
    if( cantidad < 10){ 
        return "0" + cantidad.toString()
    }else{ 
        return cantidad.toString()
    }
}

export const formatHtmlText = (text) => {
    // If the text has html tags remove them
    if (text.includes('<')) {
        text = text.replace(/(<([^>]+)>)/gi, '')
    }
    // If the text has weird symbols remove them
    if (text.includes('&#')) {
        text = text.replace(/(&#(\d+);)/gi, '')
    }
    // If the text has :&nbsp; remove it
    if (text.includes(':&nbsp;')) {
        text = text.replace(/(:&nbsp;)/gi, '')
    }
    if (text.includes('&nbsp')) {
        text = text.replace(/(&nbsp)/gi, '')
    }

    // If the text has &amp; remove it
    if (text.includes('&amp;')) {
        text = text.replace(/(&amp;)/gi, '')
    }

    // If the text has &quot; remove it
    if (text.includes('&quot;')) {
        text = text.replace(/(&quot;)/gi, '')
    }

    // If the text has &lt; remove it
    if (text.includes('&lt;')) {
        text = text.replace(/(&lt;)/gi, '')
    }

    // If the text has &gt; remove it
    if (text.includes('&gt;')) {
        text = text.replace(/(&gt;)/gi, '')
    }

    // If the text has strange symbols remove them
    if (text.includes('�')) {
        text = text.replace(/(�)/gi, '')
    }
    return text
}
