import {types} from "mobx-state-tree"


export const PuntoRetiro = types
  .model("PuntoRetiro")
  .props({
    id: types.maybeNull(types.number),
    nombre: types.string,
    calle_principal: types.string,
    calle_secundaria: types.string,
    numero: types.string,
    referencia: types.string,
    ubicacion: types.string,
    default: types.boolean,
    ciudad: types.number,
    nro_telefono: types.maybeNull(types.string),
  })
  .views(self => ({
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default PuntoRetiro
