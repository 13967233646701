import { useIonToast } from '@ionic/react'
import imageCompression from 'browser-image-compression'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { OneSignal as OneSignalCapacitor } from '@ionic-native/onesignal'

import { ConfirmModal } from '../components/ConfirmModal'
import { RUTAS, RUTAS_APP, RUTAS_APP_FABRICANTE, RUTAS_WEB_TRANSPORTISTA } from '../constants'
import { useStores } from '../models/root-store/root-store-context'
import { CategoriaArbol, GenericResponse } from '../services/api'
import { Capacitor } from '@capacitor/core'
import { Camera as NativeCamera } from '@ionic-native/camera'
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera'
import { getSnapshot } from 'mobx-state-tree'
import { RootStore } from '../models'

const moment = require('moment-timezone')

export * from './helpers-leaflet'

export const useApiToaster = () => {
  const [present, dismiss] = useIonToast()

  return (
    result: GenericResponse,
    success_msg = 'La acción se completó exitosamente',
    accion_infinitivo_para_error_message = 'guardar',
    autoDismiss = true,
  ) => {
    if (result.kind === 'ok') {
      present({
        buttons: [{ text: '✖', handler: () => dismiss() }],
        duration: autoDismiss ? 5000 : undefined,
        message: success_msg,
        color: 'success',
      })
    } else if (result.kind === 'bad-data') {
      // @ts-ignore
      if (result.errors !== undefined && result?.errors.non_field_errors) {
        // @ts-ignore
        const msg = result.errors.non_field_errors[0]
        present({
          buttons: [{ text: '✖', handler: () => dismiss() }],
          duration: autoDismiss ? 5000 : undefined,
          message: msg,
          color: 'warning',
        })
      } else {
        present({
          buttons: [{ text: '✖', handler: () => dismiss() }],
          duration: autoDismiss ? 5000 : undefined,
          message: 'Se han encontrado errores de validación',
          color: 'warning',
        })
      }
    } else {
      present({
        buttons: [{ text: '✖', handler: () => dismiss() }],
        duration: autoDismiss ? 5000 : undefined,
        message: `Ocurrió un error al ${accion_infinitivo_para_error_message}, vuelva a intentar en unos momentos`,
        color: 'danger',
      })
    }
  }
}
export const useToggleNotificaciones = () => ToggleNotificaciones

const ToggleNotificaciones = (activo: boolean, tipo: string) => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const apiToaster = useApiToaster()
  const addToast = useToaster()
  if (tipo === 'notificacion') {
    const success_msg = activo ? 'Notificaciones activadas' : 'Notificaciones desactivadas'
    const error_msg = activo ? 'activar las notificaciones' : 'desactivar las notificaciones'
    if (Capacitor.getPlatform() !== 'web') {
      OneSignalCapacitor.getIds().then((ids) => {
        loginStore.setOneSignal(ids.userId, ids.pushToken)
        loginStore.environment.api.setDevice(ids.userId, ids.pushToken, activo).then((result) => {
          apiToaster(result, success_msg, error_msg)
          if (result.kind === 'ok') {
            loginStore.setNotificacionesActivas(activo)
          }
        })
      })
    }
  } else if (tipo === 'sonido') {
    loginStore.setSonidosActivos(activo)
    const success_msg = activo ? 'Sonidos activados' : 'Sonidos desactivados'
    addToast(success_msg, 'success')
  }
}
export const useTryGoBack = () => {
  const rootStore = useStores()
  const history = useHistory()

  return (fallback: string) => {
    // Helper para solucionar problema de goBack (en caso de que entró directamente desde el navegador y no hay history
    // hacer location.href)
    if (rootStore.previousPath) {
      history.goBack()
      // Fix para bug que duplica el entry en el history
      if (rootStore.previousPath === rootStore.currentPath) {
        history.goBack()
      }
    } else {
      document.location.href = fallback
    }
  }
}

export const useToaster = () => {
  const [present, dismiss] = useIonToast()

  return (msg = 'La acción se completó exitosamente', tipo = 'dark', autoDismiss = true, cssClass = '') => {
    if (tipo === 'error') {
      tipo = 'danger'
    }
    present({
      buttons: [{ text: '✖', handler: () => dismiss() }],
      duration: autoDismiss ? 5000 : undefined,
      message: msg,
      color: tipo,
      cssClass: cssClass,
    })
  }
}

export const useNotification = () => {
  const [present, dismiss] = useIonToast()

  return (msg = 'Ocurrió un error en el servidor', tipo = 'dark', autoDismiss = false) => {
    if (tipo === 'error') {
      tipo = 'danger'
    }
    present({
      buttons: [{ text: '✖', handler: () => dismiss() }],
      duration: autoDismiss ? 5000 : undefined,
      message: msg,
      color: tipo,
      position: 'top',
    })
  }
}

export const useConfirm = (
  onAceptar: any,
  onCancelar?: any,
  titulo?: string,
  msg?: string,
  showCancelar?: boolean,
  color?: string,
  class_boton?: string,
  class_boton_cancelar?: string,
): [any, JSX.Element] => {
  const [Visible, setVisible] = useState(false)
  const [Arg, setArg] = useState<any | null>(null)
  const [Msg, setMsg] = useState<string>(msg || '')
  const colorEstilos = color || '#303AB2'
  const class_boton_aceptar = class_boton || 'btn-outline-primary'
  const class_boton_cancel = class_boton_cancelar || 'btn-outline-secondary'

  const onAceptarI = () => {
    setVisible(false)
    if (onAceptar) onAceptar(Arg)
  }

  const onCancelarI = () => {
    setVisible(false)
    if (onCancelar) onCancelar(Arg)
  }

  const mostrarDialogo = (msg?: string, arg?: any) => {
    if (msg) setMsg(msg)
    setArg(arg)
    setVisible(true)
  }

  return [
    mostrarDialogo,
    <ConfirmModal
      titulo={titulo ? titulo : 'Confirmar'}
      texto={Msg || ''}
      mostrarCancelar={showCancelar === undefined ? true : showCancelar}
      visible={Visible}
      onAceptar={onAceptarI}
      onCancelar={onCancelarI}
      color={colorEstilos}
      class_boton_aceptar={class_boton_aceptar}
      class_boton_cancelar={class_boton_cancel}
    />,
  ]
}

export const useOpenPage = () => {
  const history = useHistory()
  return (ruta: string, now?: boolean) => {
    const ret = (ev?: any) => {
      history.push(ruta)
      if (ev) {
        // for <a> links
        ev.preventDefault()
      }
    }
    if (!!now) {
      ret(null)
    }
    return ret
  }
}

export function protectedRef<T>(refvar: any) {
  function internal_func(el?: any): T | null {
    if (!!el) {
      refvar.current = el
    }
    return null
  }

  return internal_func
}

export const useClearStore = () => {
  const history = useHistory()
  const rootStore = useStores()
  return () => {
    rootStore.reset()
    // Para asegurar que se descarguen todos los componentes
    document.location.replace(RUTAS.ROOT)
  }
}

const get_orden_jerarquico = (actual: string, indice: number) =>
  actual + String.fromCharCode(97 + indice).padEnd(15, 'a')

const popular_nodo = (nodo: CategoriaArbol, data_raw: CategoriaArbol[], nivel: number, orden_s: string) => {
  const hijos: CategoriaArbol[] = []
  data_raw.forEach((posibleHijoc, idx) => {
    const posibleHijo = { ...posibleHijoc, ...{ hijos: [] } }
    if (nodo.id === posibleHijo.superior) {
      const orden = get_orden_jerarquico(orden_s, idx) // orden jerarquico con strings
      popular_nodo(posibleHijo, data_raw, nivel + 1, orden)
      nodo.orden = orden
      hijos.push(posibleHijo)
    }
  })
  nodo.hijos = [...hijos]
  nodo.nivel = nivel
  if (!nodo.hijos.length) {
    // nodos hoja
    nodo.orden = get_orden_jerarquico(orden_s, 0) // orden jerarquico con strings
  }
}

export const armar_arbol = (data_raw: CategoriaArbol[]): CategoriaArbol[] => {
  const res = [] as CategoriaArbol[]
  data_raw.forEach((nodoc, idx) => {
    const nodo = { ...nodoc, ...{ hijos: [] } }
    if (!nodo.superior) {
      const orden = get_orden_jerarquico('', idx) // orden jerarquico con strings
      popular_nodo(nodo, data_raw, 0, orden)
      nodo.orden = orden
      res.push(nodo)
    }
  })
  return res
}

export const prepararInfoCategorias = (data_raw: CategoriaArbol[]): CategoriaArbol[][] => {
  const flatFiltered = data_raw.filter((cat: CategoriaArbol) => cat.tiene_productos)
  const arbol = armar_arbol(flatFiltered)
  const flatSorted = flatFiltered.sort(function (a: CategoriaArbol, b: CategoriaArbol) {
    // @ts-ignore
    if (a.orden > b.orden) {
      return 1
    }
    // @ts-ignore
    if (a.orden < b.orden) {
      return -1
    }
    // a must be equal to b
    return 0
  })
  return [arbol, flatSorted]
}

export const comprimirImagen = async (event: any, tipo: string, showPorcentaje?: any) => {
  const imageFile = event
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    onProgress: showPorcentaje,
  }
  return imageCompression(imageFile, options)
}

export function usePrevious<T>(value: T | undefined): T | undefined {
  const ref = useRef()
  useEffect(() => {
    // @ts-ignore
    ref.current = value
  })
  return ref.current
}

export type DetailViewProps = {
  history: any
  match: any
  router: any
}

export type VerificacionRespuesta = {
  case: number
  title: string
  message: string
  enabled: boolean
  action: any
}

export const useObtenerDatosInicio = () => {
  return ObtenerDatosInicio
}

export const ObtenerDatosInicio = () => {
  const { BASE_URL } = require('../config/env')
  const rootStore = useStores()
  const { carritoStore, favoritoStore } = rootStore
  rootStore.environment.api.inicio().then((result) => {
    if (result.kind === 'ok') {
      rootStore.setTerminos(BASE_URL + result.data.terminos)
    }
  })

  rootStore.environment.api.inicio().then((result) => {
    if (result.kind === 'ok') {
      rootStore.setTerminos(BASE_URL + result.data.terminos)
    }
  })

  rootStore.environment.api.getFavoritos().then((result) => {
    if (result.kind === 'ok') {
      favoritoStore.setFavoritosAPI(result.data)
    }
  })

  rootStore.environment.api.carrito().then((result) => {
    if (result.kind === 'ok') {
      carritoStore.setProductosAPI(result.data)
    }
  })

  rootStore.environment.api.rubros().then((result) => {
    if (result.kind === 'ok') {
      rootStore.setRubros(result.rubros)
    }
  })

  rootStore.environment.api.getCategoriasHeader().then((result) => {
    // Solo las categorías con productos se van a mostrar en el header, si no tienen no aparecen
    if (result.kind === 'ok') {
      const info = prepararInfoCategorias(result.data)
      rootStore.setArbol(info[0], info[1])
    }
  })

  rootStore.environment.api.categorias().then((result) => {
    if (result.kind === 'ok') {
      rootStore.setCategorias(result.categorias)
    }
  })

  rootStore.environment.api.fabricantesList().then((result) => {
    if (result.kind === 'ok') {
      rootStore.setFabricantesVistaCliente(result.data)
    }
  })
}

export const useVerificarLoginEdad = () => {
  const rootStore = useStores()
  const openPage = useOpenPage()
  const { loginStore } = rootStore
  let respuesta: VerificacionRespuesta = { case: 10, title: '', message: '', enabled: true, action: null }
  return () => {
    if (!loginStore.isLoggedIn) {
      respuesta.case = 20
      respuesta.title = 'Iniciar sesión'
      respuesta.message = 'Este producto no es apto para menores de edad, por favor inicie sesión para continuar.'
      respuesta.enabled = false
      respuesta.action = () => openPage(`${RUTAS.ROOT}?login=1`)
      return respuesta
    }
    if (loginStore.administrador) {
      return respuesta
    }
    if (loginStore.fecha_nacimiento === null) {
      respuesta.case = 30
      respuesta.title = 'Editar perfil'
      respuesta.message =
        'Este producto no es apto para menores de edad, por favor complete en su perfil su fecha de nacimiento para continuar.'
      respuesta.enabled = false
      respuesta.action = () => openPage(RUTAS.CLIENTE)
      return respuesta
    }
    const edad = moment().diff(loginStore.fecha_nacimiento, 'years')
    if (edad >= 21) {
      return respuesta
    } else {
      respuesta.case = 40
      respuesta.title = ''
      respuesta.message = 'Lo sentimos, este producto no es apto para menores de edad.'
      respuesta.enabled = false
      respuesta.action = null
      return respuesta
    }
  }
}

export const format_numero_telefono_input = (numero: string | null) => {
  // Helper para formatear números de teléfono recibimos del backend (595981123456)

  if (numero && numero?.indexOf('-') >= 0) {
    return numero
  }

  if (!numero || !numero.startsWith('5959')) {
    return ''
  }
  try {
    let numero_formateado = numero.substring(3)
    numero_formateado =
      '0' + numero_formateado.slice(0, 3) + '-' + numero_formateado.slice(3, 6) + '-' + numero_formateado.slice(6, 9)
    return numero_formateado
  } catch (err) {
    console.log('error en format_numero_telefono_input', err)
  }
  return ''
}

export const format_numero_telefono_verificar = (numero: string | null) => {
  if (!numero || !numero.startsWith('09')) {
    return ''
  }
  // Helper par formatear números de teléfono para verificar con firebase
  return '595' + numero.replace(/-/g, '').substring(1)
}

export const format_fecha_back_to_front = (fecha: string) => {
  // Helper par fechas con formato del backend al del frontend
  let fecha_nuevo_formato = moment(fecha, 'YYYY/MM/DD')
  return fecha_nuevo_formato.format('DD/MM/YYYY')
}
export const loadFile = async () => {
  try {
    if (Capacitor.getPlatform() === 'web') {
      // Camera de capacitor tiene problemas con algunos teléfonos
      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        direction: CameraDirection.Front,
        allowEditing: true,
        quality: 100,
        webUseInput: true, // usar dialogo nativo del OS
      })

      return (await capturedPhoto.webPath) || ''
    }
  } catch (err) {
    console.log(err)
  }
}
export const launchCam = async () => {
  try {
    let image = await NativeCamera.getPicture({
      quality: 90,
      sourceType: 1, // camera
      destinationType: 1, // File uri
      allowEdit: false,
      correctOrientation: false,
    })
    return image || ''
  } catch (err) {
    console.log(err)
  }
}

export const useDireccionDefault = () => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  return () => {
    const defaultDir = loginStore.direcciones.find((e) => e.default)
    if (!defaultDir) {
      return loginStore.direcciones[0]
    }
    return getSnapshot(defaultDir)
  }
}

export function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value],
  )

  return debouncedValue
}

export const iconoTarjeta = (card_brand: string) => {
  const brand = card_brand?.slice(0, 4).toLocaleLowerCase()

  if (brand === 'mast') {
    return 'assets/icon/mastercard.png'
  }

  if (brand === 'visa') {
    return 'assets/icon/visa.png'
  }

  if (brand === 'amer') {
    return 'assets/icon/american_express.png'
  }

  if (brand === 'banc') {
    return 'assets/icon/bancard.png'
  }

  return null
}

export const cambiarModo = (rootStore: any, history: any, modo: number) => {
  const { loginStore } = rootStore
  // Si estaba como driver apagar el background tracking
  if (modo !== 30 && loginStore.modo_uso_app === 30) {
    rootStore.environment.locationService.stopUserTracking()
  }
  //
  if (modo === 30) {
    if (!loginStore.transportista) {
      // Modal de registro transportista
      let currentUrlParams = new URLSearchParams(window.location.search)
      currentUrlParams.set('registrot', '1')
      history.push(window.location.pathname + '?' + currentUrlParams.toString())
    } else if (Capacitor.getPlatform() !== 'web') {
      rootStore.environment.api.cambiar_modo_app(modo)
      loginStore.setModoUsoApp(modo)
      // Para transportista solo cuando es Android/iOS llevar a su vista app
      history.push(RUTAS_APP.HOME)
    } else {
      rootStore.environment.api.cambiar_modo_app(modo)
      loginStore.setModoUsoApp(modo)
      //
      history.push(RUTAS_WEB_TRANSPORTISTA.INICIO)
    }
  } else if (modo === 20) {
    if (!loginStore.fabricante) {
      // Levar al registro
      history.push(RUTAS_APP_FABRICANTE.HOME)
    } else {
      rootStore.environment.api.cambiar_modo_app(modo)
      loginStore.setModoUsoApp(modo)
      //
      //history.push(RUTAS_APP_FABRICANTE.PEDIDOS_PENDIENTES)
      // do the same that above but with window
      window.location.href = RUTAS_APP_FABRICANTE.PEDIDOS_PENDIENTES
    }
  } else if (modo === 10) {
    rootStore.environment.api.cambiar_modo_app(modo)
    loginStore.setModoUsoApp(modo)
    // Forzar reload
    document.location.href = RUTAS.ROOT
  }
}

export const autocompletar_calles = async (rootStore: any, ubicacion: any, valor: string | null) => {
  if (!valor) {
    return []
  }
  const result = await rootStore.environment.api.searchInfoCalle(valor, !!ubicacion ? ubicacion : undefined)
  if (result.kind === 'ok' && result.response.info) {
    return result.response.info.map((item: any) => {
      return typeof item === 'string' ? { label: item, value: item } : { label: item.label, value: item.label }
    })
  }
  return []
}

export const setearSoloCalle = async (
  rootStore: any,
  setDato: any,
  setDatos: any,
  Datos: any,
  campo: string,
  valor: any,
) => {
  if (valor) {
    if (!(valor instanceof String) && !(valor.value instanceof String)) {
      const ciudad = valor.value.ciudad ? rootStore.ciudades.find((c: any) => c.id === valor.value.ciudad.id) : null
      const datos = {} as any
      if (ciudad) {
        datos.ciudad = ciudad
      }
      datos[campo] = valor
      setDatos(Object.assign({}, Datos, datos))
    }
  } else {
    setDato(campo, '')
  }
}

export const useGetCiudadNombre = () => {
  const rootStore = useStores()
  return (id: number) => {
    const ciudad = rootStore.ciudades.find((ciu) => ciu.id === id)
    return ciudad ? ciudad.nombre : ''
  }
}

export const isMovil = () => {
  return Capacitor.getPlatform() !== 'web' || window.innerWidth <= 500 || /Mobi/.test(navigator.userAgent)
}

export const formatTextoURL = (s: string) =>
  encodeURIComponent(
    s
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .split(/\s|%20/)
      .filter(Boolean)
      .join('-'),
  )

export const actualizarDatosInicioFabricante = (rootStore: RootStore) => {
  rootStore.environment.api.simple_get(`/inicio-fabricante/`).then((result) => {
    if (result.kind === 'ok') {
      const data = result.data
      rootStore.setAbierto(data.abierto)
      rootStore.setPendientes(data.pendientes)
      rootStore.setRetiro(data.retiro)
      rootStore.setMensajes(data.mensajes)
      rootStore.setNotificaciones(data.notificaciones)
      if (data.alertas_stock && data.alertas_stock.length) {
        rootStore.setAlertasStock(data.alertas_stock)
      }
    }
  })
}

export const setDevice = (loginStore: any) => {
  if (Capacitor.getPlatform() !== 'web') {
    OneSignalCapacitor.getIds().then((ids) => {
      loginStore.environment.api.setDevice(ids.userId, ids.pushToken, true).then((result: any) => {
        console.log('Datos de onesignal enviados al backend', result)
      })
    })
  } else {
    //  set device para web
    const OneSignal = (window as any).OneSignal || []
    OneSignal.push(function () {
      OneSignal.getUserId(function (ids: any) {
        loginStore.environment.api.setDevice(ids, '', true).then((result: any) => {
          console.log('Datos de onesignal enviados al backend', result)
        })
      })
    })
  }
}

/**
 * Retorna la versión singular o plural de una palabra dependiendo de la cantidad
 */
export const pluralize = (count: number, noun: string, suffix = 's') => {
  return `${noun}${count !== 1 ? suffix : ''}`
}
