import { types } from 'mobx-state-tree'
import Transportista from './Transportista'
import Vehiculo from './Vehiculo'
import TipoVehiculo from './TipoVehiculo'
import ItinerarioModel from './ItinerarioModel'
import { Ciudad } from './Ciudad'
import Pedido from './Pedido'

export const HojaRutaModel = types.model('HojaRutaModel').props({
  id: types.number,
  fecha: types.string,
  tipo_vehiculo: types.maybeNull(TipoVehiculo),
  transportista: types.maybeNull(Transportista),
  vehiculo: types.maybeNull(Vehiculo),
  pedidos: types.array(Pedido),
  pedido: types.maybeNull(Pedido),
  tarifa_transportista: types.number,
  cantidad_retiros: types.number,
  cantidad_entregas: types.number,
  cantidad_paradas: types.number,
  minutos_trabajo: types.number,
  kilometros_recorrido: types.number,
  inicio: ItinerarioModel,
  fin: ItinerarioModel,
  itinerarios: types.array(ItinerarioModel),
  ciudades: types.maybeNull(types.array(Ciudad)),
  next: types.maybeNull(ItinerarioModel),
  finalizado: types.boolean,
  monto: types.maybeNull(types.number),
})

export default HojaRutaModel
