import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { AdminStoreModel, withEnvironment } from '..'
import { LoginStoreModel } from '../login-store/login-store'
import { Ciudad, Configuracion, Fabricante, Producto, Rubro, TipoVehiculo, Familia, Linea, Refrigeracion } from '../types'
import { CarritoStoreModel } from '../carrito-store/carrito-store'
import { BuscarStoreModel } from '../buscar-store/buscar-store'
import { Categoria, CategoriaArbolModel } from '../types/Categoria'
import { TarjetaStoreModel } from '../tarjeta-store/tarjeta-store'
import { TransportistaStoreModel } from '../transportista-store/transportista-store'
import PedidoCalificacionModel from '../types/PedidoCalificacionModel'
import ProductoVistaCliente from '../types/ProductoVistaCliente'
import FabricanteVistaCliente from '../types/FabricanteVistaCliente'
import { RegistroStoreModel } from '../registro-store/registro-store'
import { ChatStoreModel } from '../chat-store/chat-store'
import { FavoritoStoreModel } from '../favorito-store/favorito-store'
import { runInAction } from 'mobx'
import { BASE_URL } from '../../config/env'
import { prepararInfoCategorias } from '../../helpers-ts'
import { CategoriaArbol } from '../../services/api/api.types'
import { Capacitor } from '@capacitor/core'

/**
 * A RootStore model.
 */
// @ts-ignore
export const RootStoreModel = types
  .model('RootStore')
  .extend(withEnvironment)
  .props({
    esMovil: types.optional(types.boolean, false),
    tieneInternet: types.optional(types.boolean, true),
    terminos: types.optional(types.string, ''),
    promo: types.optional(types.string, ''),
    animacionPromo: types.optional(types.string, ''),
    moderadoPendiente: types.optional(types.string, ''),
    checkout: types.optional(types.boolean, false),
    //
    adminStore: types.optional(AdminStoreModel, {}),
    loginStore: types.optional(LoginStoreModel, {}),
    carritoStore: types.optional(CarritoStoreModel, {}),
    favoritoStore: types.optional(FavoritoStoreModel, {}),

    buscarStore: types.optional(BuscarStoreModel, {}),
    tarjetaStore: types.optional(TarjetaStoreModel, {}),
    transportistaStore: types.optional(TransportistaStoreModel, {}),
    registroStore: types.optional(RegistroStoreModel, {}),
    //
    latitud: types.maybeNull(types.number),
    longitud: types.maybeNull(types.number),
    //
    arbol: types.array(CategoriaArbolModel),
    arbolFlat: types.array(CategoriaArbolModel),
    ciudades: types.array(Ciudad),
    rubros: types.array(Rubro),
    fabricantes: types.array(Fabricante),
    configuraciones: types.array(Configuracion),
    tiposVehiculos: types.array(TipoVehiculo),
    refrigeraciones: types.array(Refrigeracion),
    categorias: types.array(Categoria),
    familias: types.array(Familia),
    lineas: types.array(Linea),
    productosOportunidadDia: types.array(Producto),
    productosMasVistos : types.array(Producto),
    prodsABi : types.array(Producto),
    productosMasVendidos : types.array(Producto),
    // Productos del inicio
    productos: types.array(Producto),
    productosVistaCliente: types.array(ProductoVistaCliente),
    fabricantesVistaCliente: types.array(FabricanteVistaCliente),
    fabricantesVistaClienteFiltrados: types.array(FabricanteVistaCliente),
    // Pedidos del cliente
    pedidoCalificar: types.maybeNull(PedidoCalificacionModel),
    //chat
    chatStore: types.optional(ChatStoreModel, {}),
    timestampCache: types.maybeNull(types.string),
    minBuild: types.optional(types.number, 0),
    cantidadErrores: types.optional(types.number, 0)
  })
  .volatile((self) => ({
    currentPath: '',
    previousPath: '',
    locationSearch: '',
    hasLocationPermission: true,
    // fabricante
    abierto: false,
    pendientes: 0,
    retiro: 0,
    notificaciones: 0,
    mensajes: 0,
    alertas_stock: [] as any,
  }))
  .views((self) => ({
    get isDebug() {
      return process.env.NODE_ENV === 'development'
    },
    get configuracionClaveValor() {
      const array = JSON.parse(JSON.stringify(self.configuraciones))
      let dict = {}
      for (let i = 0; i <= array.length - 1; i++) {
        // @ts-ignore
        dict[array[i]['clave']] = array[i]['valor']
      }
      return dict
    },
    get categoriasWeb() {
      return self.categorias.filter(cat => cat.tiene_productos)
    },
    get categoriasTotal() {
      return self.categorias.filter(cat => cat.id != 0)
    }
  }))
  .actions((self) => ({
    setTimestampCache(timestamp: string) {
      self.timestampCache = timestamp
    },
    updateCurrentPath(location: any) {
      if (self.currentPath) {
        self.previousPath = self.currentPath
      }
      self.currentPath = location.pathname
      self.locationSearch = location.search
    },
    setHasLocationPermission(hasLocationPermission: boolean) {
      self.hasLocationPermission = hasLocationPermission
    },
    setEsMovil(esMovil: boolean) {
      self.esMovil = esMovil
    },
    setTieneInternet(tieneInternet: boolean) {
      self.tieneInternet = tieneInternet
    },
    setPosicion(latitud: number, longitud: number) {
      self.latitud = latitud
      self.longitud = longitud
    },
    setCiudades(ciudades: any) {
      self.ciudades = ciudades || []
    },
    setRubros(rubros: any) {
      self.rubros = rubros || []
    },
    setArbol(arbol: any, arbolFlat: any) {
      self.arbol = arbol || []
      self.arbolFlat = arbolFlat
    },
    setTiposVehiculos(tiposVehiculos: any) {
      self.tiposVehiculos = tiposVehiculos || []
    },
    setRefrigeraciones(refrigeraciones : any){
      self.refrigeraciones = refrigeraciones || []
    },
    setConfiguraciones(configuraciones: any) {
      self.configuraciones = configuraciones || []
    },
    setFabricantes(fabricantes: any) {
      self.fabricantes = fabricantes || []
    },
    setProductosInicio(productos: any) {
      self.productos = productos || []
    },
    pushProductosInicio(productos: any) {
      self.productos.push(...productos)
    },
    setProductosVistaCliente(productos: any) {
      self.productosVistaCliente = productos || []
    },
    setFabricantesVistaCliente(fabricantes: any) {
      self.fabricantesVistaCliente = fabricantes || []
    },
    setFabricantesVistaClienteFiltrados(fabricantes: any) {
      self.fabricantesVistaClienteFiltrados = fabricantes || []
    },
    setTerminos(terminos: string) {
      self.terminos = terminos
    },
    setPromo(imagen : string) {
      self.promo = imagen
    },
    setAnimacionPromo(animacion : string) {
      self.animacionPromo = animacion
    },
    setCategorias(categorias: any) {
      self.categorias = categorias || []
    },
    setFamilias(familias: any) {
      self.familias = familias
    },
    setLineas(lineas: any) {
      self.lineas = lineas
    },
    setProductosMasVistos(productos: any){
      self.productosMasVistos = productos
    },
    setProdsRandABi(productos: any){
      self.prodsABi = productos
    },
    setProductosMasVendidos(productos: any){
      self.productosMasVendidos = productos
    },
    setProductosOportunidadDia(productos: any){
      self.productosOportunidadDia = productos
    },
    setModeradoPendiente(moderadoPendiente: string) {
      self.moderadoPendiente = moderadoPendiente
    },
    setHeaderCheckout(checkout: boolean) {
      self.checkout = checkout
    },
    setPedidoCalificar(itinerario: any) {
      if (itinerario) {
        self.pedidoCalificar = { ...itinerario }
      } else {
        self.pedidoCalificar = null
      }
    },
    setAbierto(abierto: boolean) {
      self.abierto = abierto
    },
    setPendientes(pendientes: number) {
      self.pendientes = pendientes
    },
    setRetiro(retiro: number) {
      self.retiro = retiro
    },
    setMensajes(mensajes: number) {
      self.mensajes = mensajes
    },
    setAlertasStock(alertas_stock: any) {
      self.alertas_stock = alertas_stock
    },
    setNotificaciones(notificaciones: number) {
      self.notificaciones = notificaciones
    },
    setCantidadErrores(errores: number){
      self.cantidadErrores = errores
    },
    setMinBuild(minBuild: number) {
      const upgradeRequired = self.minBuild !== 0 && self.minBuild < minBuild
      self.minBuild = minBuild
      //
      if (Capacitor.getPlatform() === 'web' && upgradeRequired) {
        setTimeout(() => {
          // El parámetro true en teoría fuerza actualizar la caché el navegador
          // @ts-ignore
          document.location.reload(true)
        }, 2000)
      }
    },
    reset() {
      self.adminStore.reset()
      self.loginStore.reset()
      self.carritoStore.reset()
      self.buscarStore.reset()
      self.tarjetaStore.reset()
      self.transportistaStore.reset()
      self.favoritoStore.reset()
    },
  })).actions(self => ({
    async actualizarDatosInicio(opcional: boolean): Promise<any | null> {
      if (opcional && self.timestampCache) {
        return null
      }
      const result = await self.environment.api.inicio()
      if (result.kind === 'ok') {
        const { data } = result
        const [      rubros,      categorias,      ciudades,      fabricantes,      lineas,    ] = await Promise.all([
          data.rubros,
          data.categorias,
          data.ciudades,
          data.fabricantes,
          data.lineas,
        ])
    
        runInAction(() => {
          self.setMinBuild(parseInt(data.min_build || '0'))
          self.favoritoStore.setFavoritosAPI(data.favoritos)
          self.carritoStore.setProductosAPI(data.carrito ? data.carrito.productos : [])
          self.setTerminos(BASE_URL + data.terminos)
          if(data.promocion && data.promocion.imagen){
            self.setPromo(BASE_URL + data.promocion.imagen)
          } else {
            self.setPromo('')
          }
          if(data.promocion && data.promocion.animacion){
            if (data.promocion.animacion.includes('http')) {
              self.setAnimacionPromo(data.promocion.animacion)
            } else {
              self.setAnimacionPromo(BASE_URL + data.promocion.animacion)
            }
          } else {
            self.setAnimacionPromo('')
          }
          self.setRubros(rubros)
          self.chatStore.setMensajesPrededeterminados(data.mensajes_predeterminados)
          self.setFabricantesVistaCliente(fabricantes)
          self.setFabricantesVistaClienteFiltrados(fabricantes) // (buscador)
          self.setCategorias(categorias)
          self.setCiudades(ciudades)
          self.setFamilias(data.familias)
          self.setLineas(lineas)
          self.setTimestampCache(data.timestamp)
          self.setProductosMasVistos(data.mas_vistos)
          self.setProdsRandABi(data.prods_abi)
          self.setProductosMasVendidos(data.mas_vendidos)
          self.setProductosOportunidadDia(data.compra_mejorada)
          const info = prepararInfoCategorias(categorias as CategoriaArbol[])
          self.setArbol(info[0], info[1])
        })
        return data
      } else if (result.kind === "unauthorized") {
        // el token ya no es válido, cerrar sesión
        self.reset()
      }
      return null
    }

  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
