import { types } from 'mobx-state-tree'
import PuntoRetiro from './PuntoRetiro'
import Cliente from './Cliente'
import { Rubro } from './Rubro'
import moment from 'moment'

export const Fabricante = types
  .model('Fabricante')
  .props({
    id: types.number,
    nombre: types.string,
    imagen_perfil: types.maybeNull(types.string),
    aprobado: types.maybeNull(types.boolean),
    activo: types.maybeNull(types.boolean),
    fecha_agregado: types.maybeNull(types.string),
    aprobado_por: types.maybeNull(types.string),
    puntos_retiro: types.maybeNull(types.array(PuntoRetiro)),
    usuarios: types.maybeNull(types.array(Cliente)),
    rubros: types.maybeNull(types.array(Rubro)),
    nro_telefono: types.maybeNull(types.string),
    ruc: types.maybeNull(types.string),
    razon_social: types.maybeNull(types.string),
    resena_empresa: types.maybeNull(types.string),
    rating: types.number,
    ratings: types.optional(types.number, 0),
    tipo_proveedor: types.optional(types.number, 10),
    fecha_aceptacion_contrato: types.maybeNull(types.string),
    constancia_ruc: types.maybeNull(types.string),
    dias_apertura: types.maybeNull(types.array(types.string)),
    hora_apertura: types.maybeNull(types.string),
    hora_cierre: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setPuntosRetiro(puntosRetiro: any) {
      // @ts-ignore
      self.puntos_retiro = [...puntosRetiro]
    },
  }))
  .views((self) => ({
    get abierto() {
      if (self.dias_apertura && self.dias_apertura.indexOf(moment().format('E')) !== -1) {
        const format = 'hh:mm:ss'
        const beforeTime = moment(self.hora_apertura, format),
          afterTime = moment(self.hora_cierre, format)
        if (moment().isBetween(beforeTime, afterTime)) {
          return true
        }
      }
      return false
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Fabricante
