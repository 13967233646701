import { types } from 'mobx-state-tree'

export const PreMensajePredeterminado = types.model('MensajePredeterminado').props({
    id: types.number,
    mensaje: types.string,
    estado: types.maybeNull(types.integer),
    tipo: types.maybeNull(types.integer),
})


export const MensajePredeterminado = types.snapshotProcessor(PreMensajePredeterminado, {
    // from snapshot to instance
    preProcessor(sn: any) {
        return {
            id: sn.id || 0,
            mensaje: sn.mensaje,
            tipo: sn.tipo,
            estado: sn.estado,
        }
    },
    // from instance to snapshot
    // postProcessor(sn): any {
    //   return {
    //     text: !sn.text ? null : sn.text
    //   }
    // }
})
