import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {Cliente, Direccion, Fabricante, Producto, PuntoRetiro, TipoVehiculo} from "../types"
import Transportista from "../types/Transportista";
import Vehiculo from "../types/Vehiculo";
import ItinerarioModel from "../types/ItinerarioModel";

/**
 * A AdminStore model.
 */
export const AdminStoreModel = types.model("AdminStore")
  .props({
    sidebarType: types.optional(types.string, 'full'),
    clienteDetalle: types.maybeNull(Cliente),
    fabricanteDetalle: types.maybeNull(Fabricante),
    tipoVehiculoDetalle: types.maybeNull(TipoVehiculo),
    puntoRetiroDetalle: types.maybeNull(PuntoRetiro),
    transportistaDetalle: types.maybeNull(Transportista),
    direccionDetalle: types.maybeNull(Direccion),
    vehiculoDetalle: types.maybeNull(Vehiculo),
    productoDetalle: types.maybeNull(Producto),
    itinerario: types.maybeNull(ItinerarioModel),
  })
  .actions(self => ({
    setSidebarType(sidebarType: string) {
      self.sidebarType = sidebarType
    },
    setClienteDetalle(cliente: any) {
      self.clienteDetalle = cliente
    },
    setTipoVehiculoDetalle(tipoVehiculo: any) {
      self.tipoVehiculoDetalle = tipoVehiculo
    },
    setFabricanteDetalle(fabricante: any) {
      self.fabricanteDetalle = fabricante
    },
    setTransportistaDetalle(transportista: any) {
      self.transportistaDetalle = transportista
    },
    setDireccionDetalle(direccion: any) {
      if (direccion === null) {
        self.direccionDetalle = null
        return
      }
      self.direccionDetalle = {...direccion}
    },
    setPuntoRetiroDetalle(puntoRetiro: any) {
      if (puntoRetiro === null) {
        self.puntoRetiroDetalle = null
        return
      }
      self.puntoRetiroDetalle = {...puntoRetiro}
    },
    setVehiculoDetalle(vehiculo: any) {
      if (vehiculo === null) {
        self.vehiculoDetalle = null
        return
      }
      self.vehiculoDetalle = {...vehiculo}
    },
    setProductoDetalle(producto: any) {
      self.productoDetalle = producto
    },
    setItinerario(itinerario: any) {
      self.itinerario = itinerario
    },
    reset(){
      self.itinerario = null
      self.productoDetalle = null
      self.vehiculoDetalle = null
      self.puntoRetiroDetalle = null
      self.direccionDetalle = null
      self.transportistaDetalle = null
      self.fabricanteDetalle = null
      self.tipoVehiculoDetalle = null
      self.clienteDetalle = null
      self.sidebarType = 'full'
    }
  }))
  // .postProcessSnapshot(omit(["clienteDetalle", "tipoVehiculoDetalle", "fabricanteDetalle"]))

type AdminStoreType = Instance<typeof AdminStoreModel>
export interface AdminStore extends AdminStoreType {}
type AdminStoreSnapshotType = SnapshotOut<typeof AdminStoreModel>
export interface AdminStoreSnapshot extends AdminStoreSnapshotType {}
