import { types } from 'mobx-state-tree'
import { Ciudad } from './Ciudad'
import Vehiculo, { convertVehiculo } from './Vehiculo'
import Cliente, { convertCliente } from './Cliente'

export const Transportista = types
  .model('Transportista')
  .props({
    id: types.number,
    usuario: Cliente,
    registro_frente: types.maybeNull(types.string),
    registro_dorso: types.maybeNull(types.string),
    registro_vencimiento: types.maybeNull(types.string),
    antecedentesj: types.maybeNull(types.string),
    antecedentesp: types.maybeNull(types.string),
    poliza_seguro: types.maybeNull(types.string),
    aprobado: types.boolean,
    ciudades: types.array(Ciudad),
    vehiculos: types.array(Vehiculo),
    cantidad_viajes: types.maybeNull(types.number),
    ultimo_viaje: types.maybeNull(types.string),
    rating: types.maybeNull(types.number),
    ratings: types.optional(types.number, 0),
    fecha_aceptacion_contrato: types.maybeNull(types.string),
    constancia_ruc: types.maybeNull(types.string),
    balance: types.maybeNull(types.number),
    activo: types.maybeNull(types.boolean),
    bloqueado: types.maybeNull(types.boolean),
    has_products: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    setPuntosRetiro(puntosRetiro: any) {
      // @ts-ignore
      self.puntos_retiro = [...puntosRetiro]
    },
    setVehiculos(vehiculos: any) {
      // @ts-ignore
      self.vehiculos = [...vehiculos]
    },
    setActivo(activo: boolean) {
      self.activo = activo
    },
    setDocumento(data: any, tipo: string) {
      if (tipo === 'registro_frente') {
        self.registro_frente = data
      }
      if (tipo === 'registro_dorso') {
        self.registro_dorso = data
      }
      if (tipo === 'antecedentesp') {
        self.antecedentesp = data
      }
      if (tipo === 'antecedentesj') {
        self.antecedentesj = data
      }
      if (tipo === 'constancia_ruc') {
        self.constancia_ruc = data
      }   
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Transportista

export const convertTransportista = (tra: any) => {
  if (!tra) {
    return null
  }
  return {
    id: tra.id,
    usuario: convertCliente(tra.usuario),
    registro_frente: tra.registro_frente,
    registro_dorso: tra.registro_dorso,
    registro_vencimiento: tra.registro_vencimiento,
    antecedentesj: tra.antecedentesj || null,
    antecedentesp: tra.antecedentesp || null,
    aprobado: tra.aprobado,
    ciudades: tra.ciudades,
    vehiculos: tra.vehiculos.map(convertVehiculo),
    cantidad_viajes: tra.cantidad_viajes,
    ultimo_viaje: tra.ultimo_viaje,
    rating: tra.rating,
    ratings: tra.ratings,
    fecha_aceptacion_contrato: tra.fecha_aceptacion_contrato,
    constancia_ruc: tra.constancia_ruc,
    activo: tra.activo,
    has_products: tra.has_products,
  } as any
}
