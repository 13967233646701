import { Instance, types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import ProductoCarrito from '../types/ProductoCarrito'
import Producto from '../types/Producto'
import { Environment } from '../../models/environment'
import { AnyCnameRecord } from 'dns'

export interface iProducto extends SnapshotIn<typeof Producto> {}

export const Favorito = types
  .model('Favorito')
  .props({
    id: types.maybeNull(types.number),
    usuario: types.maybeNull(types.number),
    producto: Producto,
    fecha: types.maybeNull(types.string),
    activo: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    toggleActivo() {
      self.activo = !self.activo
      return self.activo
    },
  }))

export interface iFavorito extends SnapshotOut<typeof Favorito> {}

export const FavoritoStoreModel = types
  .model('FavoritoStore')
  .props({ favoritos: types.array(Favorito) })
  .views((self) => ({
    esFavorito(producto: iProducto) {
      const fav = self.favoritos.find((fav) => fav.producto.id === producto.id && fav.activo)
      if (!!fav && fav.activo) {
        return true
      }
      return false
    },
    get cantidadFavoritos() {
      return self.favoritos.filter((fav) => fav.activo).length
    },
    get productos() {
      const fav = self.favoritos.filter((fav) => fav.activo)
      return fav.map((fav) => fav.producto)
    },
    favorito(producto: iProducto) {
      return self.favoritos.find((fav) => fav.producto.id === producto.id)
    },
  }))
  .actions((self) => ({
    setFavoritosAPI(data: any) {
      if (data) {
        self.favoritos = data.map((fav: any) => {
          return {
            id: fav.id,
            usuario: fav.usuario,
            producto: fav.producto,
            fecha: fav.fecha,
            activo: fav.activo,
          }
        })
      }
    },
    addFavorito(prod: iProducto) {
      const fav = self.favoritos.find((fav) => fav.producto.id === prod.id)
      if (!!fav) {
        fav.toggleActivo()
      } else {
        self.favoritos.concat({
          id: null,
          usuario: null,
          fecha: null,
          producto: prod,
          activo: true,
        })
      }
    },
    toggleFavorito(producto: iProducto) {
      const fav = self.favoritos.find((fav) => fav.producto.id === producto.id)

      if (!!fav) {
        fav.toggleActivo()
      }
    },
    reset() {
      self.favoritos = [] as any
    },
  }))
