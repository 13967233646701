import {types} from "mobx-state-tree"

export const FabricanteVistaCliente = types
  .model("FabricanteVistaCliente")
  .props({
    id: types.number,
    nombre: types.string,
    imagen_perfil: types.maybeNull(types.string),
    rating: types.number,
    categorias: types.array(types.number)
  })

export default FabricanteVistaCliente
