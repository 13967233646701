import { cast, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import Transportista from '../types/Transportista'
import Vehiculo, { VehiculoType } from '../types/Vehiculo'
import HojaRutaModel from '../types/HojaRutaModel'
import ItinerarioModel from '../types/ItinerarioModel'
import { LatLngLiteral } from 'leaflet'
import { withRootStore } from '..'

const moment = require('moment-timezone')

const LatLngLiteralModel = types.model({ lat: types.number, lng: types.number })



/**
 * A TransportistaStore model.
 */
export const TransportistaStoreModel = types
  .model('TransportistaStore')
  .extend(withRootStore)
  .props({
    transportista: types.maybeNull(Transportista),
    vehiculos: types.array(Vehiculo),
    vehiculoDetalle: types.maybeNull(Vehiculo),
    oportunidades: types.array(HojaRutaModel),
    hojaRutaActiva: types.maybeNull(HojaRutaModel),
    itinerarioActivo: types.maybeNull(ItinerarioModel),
    balance: types.optional(types.number, 0),
    lastLoc: types.maybeNull(LatLngLiteralModel),
    gpsPermisosBackground: types.optional(types.boolean, false),
  })
  .volatile((self) => ({
    oportunidadDetalle: null as any
  }))
  .views((self) => ({
    get vehiculoSeleccionado(): VehiculoType | null {
      const res = self.vehiculos.find((c) => c.activo === true)
      return res ? (res as any as VehiculoType) : null
    },

    get getOportunidadDetalle(): Instance<typeof self.oportunidadDetalle> | null {
      return self.oportunidadDetalle
    },


    get documentosFaltantes(): string[] {
      const documentos: string[] = []
      const now = moment()

      if (self.transportista?.registro_vencimiento && now.isAfter(self.transportista?.registro_vencimiento)) {
        documentos.push('registro_vencimiento')
      } else if (!self.transportista?.antecedentesp) {
        documentos.push('antecedentesp')
      } else if (!self.transportista?.antecedentesj) {
        documentos.push('antecedentesj')
      } else if (!self.transportista?.constancia_ruc) {
        documentos.push('constancia_ruc')
      }
      return documentos
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setTransportista(transportista: any) {
      self.transportista = transportista
      self.transportista?.usuario.setNroTelefono(transportista.usuario.nro_telefono)
      self.vehiculos = transportista.vehiculos
    },
    setVehiculoDetalle(vehiculo: Instance<typeof Vehiculo>) {
      if (vehiculo) {
        const nuevo_vehiculo = { ...vehiculo }
        const tipo_vehiculo = { ...vehiculo.tipo_vehiculo }
        nuevo_vehiculo.tipo_vehiculo = tipo_vehiculo
        self.vehiculoDetalle = nuevo_vehiculo
      } else {
        self.vehiculoDetalle = null
      }
    },
    setOportunidadDetalle(oportunidad: any) {
      self.oportunidadDetalle = oportunidad ? { ...oportunidad } : null
    },
    setHojaRutaActiva(hojaRuta: any) {
      self.hojaRutaActiva = hojaRuta ? { ...hojaRuta } : null
    },
    setItinerarioActivo(itinerario: any) {
      self.itinerarioActivo = itinerario ? { ...itinerario } : null
    },
    setBalance(balance: number) {
      self.balance = balance
    },
    setGpsPermisosBackground(gpsPermisosBackground: boolean) {
      self.gpsPermisosBackground = gpsPermisosBackground
    },
    setLocation(loc?: LatLngLiteral) {
      self.lastLoc = loc ? { ...loc } : null
      if (loc) {
        self.rootStore.setPosicion(loc.lat, loc.lng)
      }
    },
    reset() {
      self.transportista = null
      self.vehiculos = cast([])
      self.vehiculoDetalle = null
      self.oportunidades = cast([])
      self.oportunidadDetalle = null
      self.hojaRutaActiva = null
      self.itinerarioActivo = null
      self.balance = 0
    },
  }))

type TransportistaStoreType = Instance<typeof TransportistaStoreModel>

export interface TransportistaStore extends TransportistaStoreType {}

type TransportistaStoreSnapshotType = SnapshotOut<typeof TransportistaStoreModel>

export interface TransportistaStoreSnapshot extends TransportistaStoreSnapshotType {}
