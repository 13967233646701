import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { withEnvironment, withRootStore } from '..'
import { CallePrincipal } from '../types'

type camposImagen =
  | 'registro_frente'
  | 'registro_dorso'
  | 'constancia_ruc'
  | 'antecedentesj'
  | 'antecedentesp'
  | 'habilitacion_frente'
  | 'habilitacion_dorso'
  | 'poliza_seguro'
  | 'registro_vencimiento'
  | 'habilitacion_vencimiento'

/**
 * Model description here for TypeScript hints.
 */
// @ts-ignore
export const RegistroStoreModel = types
  .model('RegistroStore')
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({
    tipo_formulario: types.maybeNull(types.number),
    // Usuario
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    nombre: types.maybeNull(types.string),
    apellido: types.maybeNull(types.string),
    nro_documento: types.maybeNull(types.string),
    nro_telefono: types.maybeNull(types.string),
    razon_social: types.maybeNull(types.string),
    razon_social_cliente: types.maybeNull(types.string),
    ruc: types.maybeNull(types.string),
    ruc_cliente: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    fecha_nacimiento: types.maybeNull(types.string),
    // Direccion
    nombre_direccion: types.maybeNull(types.string),
    calle_principal: types.maybeNull(CallePrincipal),
    calle_secundaria: types.maybeNull(CallePrincipal),
    numero: types.maybeNull(types.string),
    referencia: types.maybeNull(types.string),
    ubicacion: types.maybeNull(types.string),
    ciudad: types.maybeNull(types.number),
    // Empresa
    nombre_empresa: types.maybeNull(types.string),
    razon_social_empresa: types.maybeNull(types.string),
    resena_empresa: types.maybeNull(types.string),
    ruc_empresa: types.maybeNull(types.string),
    nro_telefono_empresa: types.maybeNull(types.string),
    rubros: types.array(types.number),
    tipo_proveedor: types.maybeNull(types.number),
    imagen_perfil: types.maybeNull(types.string),
    // Punto de retiro
    nombre_punto_retiro: types.maybeNull(types.string),
    dias_apertura: types.maybeNull(types.array(types.string)),
    hora_apertura: types.maybeNull(types.string),
    hora_cierre: types.maybeNull(types.string),
    // Datos conductor
    ciudades: types.array(types.number),
    registro_frente: types.maybeNull(types.string),
    registro_dorso: types.maybeNull(types.string),
    constancia_ruc: types.maybeNull(types.string),
    antecedentesj: types.maybeNull(types.string),
    antecedentesp: types.maybeNull(types.string),
    //Datos vehiculo
    tipo_vehiculo: types.array(types.number),
    marca: types.maybeNull(types.string),
    modelo: types.maybeNull(types.string),
    ano: types.maybeNull(types.string),
    numero_chapa: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    kilometraje: types.maybeNull(types.string),
    numero_chassis: types.maybeNull(types.string),
    // Fotos vehiculo
    habilitacion_frente: types.maybeNull(types.string),
    habilitacion_dorso: types.maybeNull(types.string),
    poliza_seguro: types.maybeNull(types.string),
    registro_vencimiento: types.maybeNull(types.string),
    habilitacion_vencimiento: types.maybeNull(types.string),
    //ofertas
    email_oferta: types.maybeNull(types.boolean),
    notificacion_oferta: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    getPreview(campo: camposImagen) {
      if (campo === 'registro_frente') {
        return self.registro_frente
      } else if (campo === 'registro_dorso') {
        return self.registro_dorso
      } else if (campo === 'constancia_ruc') {
        return self.constancia_ruc
      } else if (campo === 'antecedentesj') {
        return self.antecedentesj
      } else if (campo === 'antecedentesp') {
        return self.antecedentesp
      } else if (campo === 'habilitacion_frente') {
        return self.habilitacion_frente
      } else if (campo === 'habilitacion_dorso') {
        return self.habilitacion_dorso
      } else if (campo === 'poliza_seguro') {
        return self.poliza_seguro
      }

      return ''
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setTipoFormulario(tipo: number) {
      self.tipo_formulario = tipo
    },
    setDatoRegistroStore(campo: string, dato: any) {

      if( typeof dato !== 'object' && ( campo === 'calle_principal' || campo === 'calle_secundaria')){ 
        return
      }

      if (
        (typeof dato === 'object' &&
          !Array.isArray(dato) &&
          campo !== 'calle_principal' &&
          campo !== 'calle_secundaria' &&
          campo !== 'kilometraje') ||
        dato === undefined
      ) {
        return
      }
      if (campo === 'nombre') {
        self.first_name = dato
      }
      if (campo === 'apellido') {
        self.last_name = dato
      }
      if (campo === 'ruc') {
        self.ruc_empresa = dato
        if (self.tipo_formulario === 20) return
      }
      if (campo === 'razon_social') {
        self.razon_social_empresa = dato
        if (self.tipo_formulario === 20) return
      }
      if (campo === 'ruc_cliente') {
        self.ruc = dato
        if (self.tipo_formulario === 20) return
      }
      if (campo === 'razon_social_cliente') {
        self.razon_social = dato
        if (self.tipo_formulario === 20) return
      }
      if (campo === 'kilometraje') {
        if (dato) {
          self.kilometraje = dato + ''
        } else {
          self.kilometraje = null
        }
        return
      }
      if (campo === 'email_oferta') {
        if (dato) {
          self.email_oferta = dato
        } else {
          self.email_oferta = null
        }
      }
      if (campo === 'notificacion_oferta') {
        if (dato) {
          self.notificacion_oferta = dato
        } else {
          self.notificacion_oferta = null
        }
      }
      // @ts-ignore
      self[campo] = dato
    },
    reset() {
      self.first_name = null
      self.last_name = null
      self.nro_documento = null
      self.nro_telefono = null
      self.razon_social = null
      self.ruc = null
      self.email = null
      self.nombre_direccion = null
      self.calle_principal = null
      self.calle_secundaria = null
      self.numero = null
      self.referencia = null
      self.ubicacion = null
      self.ciudad = null
      self.nombre_empresa = null
      self.razon_social_empresa = null
      self.resena_empresa = null
      self.ruc_empresa = null
      self.nro_telefono_empresa = null
      self.rubros = cast([])
      self.tipo_proveedor = null
      self.nombre_punto_retiro = null
      self.ciudades = cast([])
      self.registro_frente = null
      self.registro_dorso = null
      self.antecedentesj = null
      self.antecedentesp = null
      self.tipo_vehiculo = cast([])
      self.marca = null
      self.modelo = null
      self.ano = null
      self.numero_chapa = null
      self.color = null
      self.kilometraje = null
      self.numero_chassis = null
      self.habilitacion_frente = null
      self.habilitacion_dorso = null
      self.poliza_seguro = null
      self.registro_vencimiento = null
      self.habilitacion_vencimiento = null
      self.imagen_perfil = null
      self.constancia_ruc = null
      self.fecha_nacimiento = null
      self.notificacion_oferta = null
      self.email_oferta = null
      self.dias_apertura = null
      self.hora_apertura = null
      self.hora_cierre = null
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type RegistroStoreType = Instance<typeof RegistroStoreModel>
export interface RegistroStore extends RegistroStoreType {}
type RegistroStoreSnapshotType = SnapshotOut<typeof RegistroStoreModel>
export interface RegistroStoreSnapshot extends RegistroStoreSnapshotType {}
