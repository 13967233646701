import {types} from "mobx-state-tree"

export const Tarjeta = types
  .model("Tarjeta")
  .props({
    card_id: types.maybeNull(types.number),
    alias_token: types.maybeNull(types.string),
    card_masked_number: types.maybeNull(types.string),
    expiration_date: types.maybeNull(types.string),
    card_brand: types.maybeNull(types.string),
    card_type: types.maybeNull(types.string),
    exists: types.maybeNull(types.boolean),
    default: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get numeroFormatted() {
      // @ts-ignore
      const arr = self.card_masked_number.match(/.{4}/g)
      if (!arr) {
        return ''
      }
      return arr.join('  ').replace(/\*/g, '•')
    },
    get brand() {
      // @ts-ignore
      if (self.card_brand.indexOf('MAESTRO') !== -1) {
        return 'MAESTRO'
      }
      // @ts-ignore
      return self.card_brand.split(' - ')[0]
    },
    get shortNumber() {
      // @ts-ignore
      return self.card_masked_number.substring(self.card_masked_number.length - 4)
    },
    get shortName() {
      // @ts-ignore
      return self.brand + " " + self.shortNumber
    },
    get styleContainer() {
      if (self.card_brand){
        if (self.card_brand.indexOf('MASTERCARD') !== -1) {
          return { backgroundColor: '#0047CC' }
        } else if (self.card_brand.indexOf('VISA') !== -1) {
          return { backgroundColor: '#000000' }
        }
        return { backgroundColor: '#77869E' }
      }

    },
    get image() {
      if (self.card_brand){
        if (self.card_brand.indexOf('MAESTRO') !== -1) {
          return require("../../assets-ap/images/maestro.png")
        } else if (self.card_brand.indexOf('MASTERCARD') !== -1) {
          return require("../../assets-ap/images/mastercard.png")
        } else if (self.card_brand.indexOf('VISA') !== -1) {
          return require("../../assets-ap/images/visa.png")
        }
      }
      return ''
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Tarjeta
