import { types } from 'mobx-state-tree'
import { VarianteCarrito } from './VarianteCarrito'

export const ProductoCarrito = types
  .model('ProductoCarrito')
  .props({
    id: types.number,
    fabricante: types.string,
    fabricante_id: types.optional(types.number, 0),
    nombre: types.string,
    descripcion: types.string,
    precio: types.number,
    precio_mayorista: types.number,
    cantidad_disponible: types.number,
    imagenes: types.maybeNull(types.string),
    categoria: types.maybeNull(types.string),
    cantidad: types.optional(types.number, 0),
    variante: types.optional(types.maybeNull(VarianteCarrito), null),
  })
  .views((self) => ({}))
  .actions((self) => ({
    setCantidad(cantidad: number) {
      self.cantidad = cantidad
    },
    setVariante(variante : any) {
      self.variante = variante
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default ProductoCarrito
