import {types} from "mobx-state-tree"

export const ImagenProducto = types
  .model("ImagenProducto")
  .props({
    id: types.number,
    imagen: types.maybeNull(types.string),
    producto: types.number,
    portada: types.boolean,
  })

export default ImagenProducto
