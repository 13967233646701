import { types } from 'mobx-state-tree'
import { withRootStore } from '../extensions/with-root-store'

export const Direccion = types
  .model('Direccion')
  .extend(withRootStore)
  .props({
    id: types.maybeNull(types.number),
    nombre: types.maybeNull(types.string),
    calle_principal: types.string,
    calle_secundaria: types.string,
    numero: types.string,
    referencia: types.string,
    ubicacion: types.string,
    default: types.maybeNull(types.boolean),
    ciudad: types.maybeNull(types.number),
    ciudad_nombre: types.maybeNull(types.string),
    icono: types.maybeNull(types.string),
  })
  .actions((self) => ({
    toggleDefault() {
      self.default = !self.default
    },
  }))
  .views((self) => ({
    get ciudadO() {
      return self.rootStore.ciudades.find((ciu: any) => ciu.id === self.ciudad)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Direccion

export const convertDireccion = (dir: any) => {
  if (!dir) {
    return null
  }
  return {
    id: dir.id,
    nombre: dir.nombre,
    calle_principal: dir.calle_principal,
    calle_secundaria: dir.calle_secundaria,
    numero: dir.numero,
    referencia: dir.referencia,
    ubicacion: dir.ubicacion,
    default: dir.default,
    ciudad: dir.ciudad,
    ciudad_nombre: dir.ciudad_nombre,
    icono: dir.icono,
  } as any
}

