import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import * as Sentry from "@sentry/capacitor";
import { Integrations } from "@sentry/tracing";
import * as SentryReact from "@sentry/react";
import { Capacitor } from '@capacitor/core';

export const RELEASE = 'abi@1.125'
export const BUILD = 125

if (process.env.NODE_ENV !== 'development') {
  Sentry.init(
    {
      dsn: 'https://dfc99dc20fe9469a9a1774d7ebf4b5b1@sentry.softec.com.py/21',
      // @ts-ignore
      integrations: [new Integrations.BrowserTracing()],
      release: RELEASE,
      enableNative: Capacitor.getPlatform() !== 'web',
      tracesSampleRate: 1.0, // Subir 100% de los errores
    },
    SentryReact.init,
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
defineCustomElements(window);
serviceWorkerRegistration.unregister();
