import { SnapshotOut, types } from 'mobx-state-tree'
import { SelectorModal } from '../../components'
import { MensajePredeterminado as MensajePredeterminadoApi } from '../../services/api'
import { MensajePredeterminado } from '../types/Chat'

export const TIPO = {
  comprador: 0,
  driver: 10,
}
export const ESTADO = {
  pendiente: 0,
  enProceso: 10,
  finalizado: 20,
}
export const ChatStoreModel = types
  .model('ChatStoreModel')
  .props({
    mensajesPredeterminados: types.array(MensajePredeterminado),
  })
  .views((self) => ({
    get mensajesPredeterminadosDriver() {
      const mensajes: SnapshotOut<typeof MensajePredeterminado>[] = []
      self.mensajesPredeterminados.forEach((mensaje) => {
        if (mensaje.tipo === TIPO.driver || mensaje.tipo === null) {
          mensajes.push(mensaje)
        }
      })
      return mensajes
    },
    get mensajesPredeterminadoCliente() {
      const mensajes: SnapshotOut<typeof MensajePredeterminado>[] = []
      self.mensajesPredeterminados.forEach((mensaje) => {
        if (mensaje.tipo == TIPO.comprador || mensaje.tipo == null) {
          mensajes.push(mensaje)
        }
      })
      return mensajes
    },
  }))
  .actions((self) => ({
    setMensajesPrededeterminados(data: any) {
      if (data) {
        self.mensajesPredeterminados = data.map((mensaje: any) => {
          return {
            id: mensaje.id,
            mensaje: mensaje.mensaje,
            tipo: mensaje.tipo,
            estado: mensaje.estado,
          }
        })
      }
    },
    setMensajesPredeterminadosDriver() {
      const mensajes: any[] = []
      self.mensajesPredeterminados.forEach((mensaje) => {
        if (mensaje.tipo === TIPO.driver || mensaje.tipo === null) {
          mensajes.push(mensaje)
        }
      })
      return mensajes
    },
    getMensajesPredeterminadoCliente() {
      const mensajes: any[] = []
      self.mensajesPredeterminados.forEach((mensaje) => {
        if (mensaje.tipo == TIPO.comprador || mensaje.tipo == null) {
          mensajes.push(mensaje)
        }
      })
      return mensajes
    },
  }))
