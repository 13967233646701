// RUTAS
export const RUTAS = {
  ROOT: '/',
  LOGOUT: '/logout',
  ADMIN: '/admin',
  BUSCAR: '/buscar',
  CATEGORIAS: '/categorias',
  CATEGORIA_LISTADO_PRODUCTOS: (id, nombre) => `/categoria/${id}-${nombre}`,
  CLIENTE: '/cliente',
  CLIENTE_DIRECCION_UPDATE: '/cliente/direccion/update',
  TARJETA_AGREGAR: (process_id, bancard_port) => `/tarjeta/agregar/${process_id}/${bancard_port}`,
  PRODUCTO_DETAIL: (id, nombre) => `/producto/${id}-${nombre}`,
  PEDIDOS: '/pedido',
  PRIVACIDAD: '/privacidad',
  RASTREAR_PEDIDO: (id) => '/pedido/rastreo/' + id,
  CARRITO: '/carrito',
  REGISTRO: '/registro',
  RECUPERAR_CONTRASENA: '/recuperar_cuenta',
  PEDIDO_COMPLETADO: '/pedido',
  ADMIN_BUSQUEDA: '/admin/busqueda',
  ADMIN_PEDIDOS: '/admin/pedido',
  ADMIN_BALANCE: '/admin/balance',
  ADMIN_RENDICION: '/admin/rendicion',
  ADMIN_PAGO_PROVEEDOR: '/admin/pago/proveedor',
  ADMIN_PAGO_CLIENTE: '/admin/pago/cliente',
  ADMIN_FACTURA_CLIENTE: '/admin/factura/cliente',
  ADMIN_CLIENTE: '/admin/cliente',
  ADMIN_CLIENTE_DETAIL: (id) => `/admin/cliente/${id}`,
  ADMIN_CLIENTE_UPDATE: (id) => `/admin/cliente/${id}/update`,
  ADMIN_CREATE_DIRECCION_CLIENTE: (cliente) => `/admin/cliente/${cliente}/direccion/create`,
  ADMIN_UPDATE_DIRECCION_CLIENTE: (cliente, id) => `/admin/cliente/${cliente}/direccion/${id}/update`,
  ADMIN_FACTURA_PROVEEDOR: '/admin/factura/proveedor',
  ADMIN_PAGO_PROVEEDOR_CREATE: '/admin/pago/proveedor/create',
  ADMIN_FACTURA_PROVEEDOR_DETAIL: (id) => '/admin/factura/proveedor/' + id,
  ADMIN_FACTURA_CLIENTE_DETAIL: (id) => '/admin/factura/cliente/' + id,
  ADMIN_PRODUCTO: (estado) => `/admin/producto/${estado}`,
  ADMIN_COMENTARIO_PRODUCTO: `/admin/comentario-producto`,
  ADMIN_PRODUCTO_CREATE: (estado) => '/admin/producto/' + estado + '/create/',
  ADMIN_PRODUCTO_DETAILD: (id) => '/admin/producto/detail/' + id,
  ADMIN_PRODUCTO_DETAIL: (estado, id) => '/admin/producto/' + estado + '/detail/' + id,
  ADMIN_PRODUCTO_UPDATE: (estado, id) => '/admin/producto/' + estado + '/update/' + id,
  ADMIN_VEHICULOS: (estado) => `/admin/vehiculo/${estado}`,
  ADMIN_VEHICULO_CREATE: `/admin/vehiculo/pendiente/create`,
  ADMIN_VEHICULO_DETAIL: (estado, id) => `/admin/vehiculo/${estado}/detail/${id}`,
  ADMIN_VEHICULO_UPDATE: (estado, id) => `/admin/vehiculo/${estado}/update/${id}`,
  ADMIN_TRANSPORTISTAS: (estado) => '/admin/transportista/' + estado,
  ADMIN_TRANSPORTISTA_DETAIL: (estado, id) => '/admin/transportista/' + estado + '/detail/' + id,
  ADMIN_TRANSPORTISTA_UPDATE: (estado, id) => '/admin/transportista/' + estado + '/update/' + id,
  ADMIN_TRANSPORTISTA_CREATE: '/admin/transportista/pendiente/create',
  ADMIN_HOJA_RUTA_DETAIL: (id) => '/admin/hoja_ruta/detail/' + id,
  ADMIN_ORDEN_VENTA: '/admin/orden_venta/',
  ADMIN_ORDEN_VENTA_DETAIL: (id) => '/admin/orden_venta/detail/' + id,
  ADMIN_PEDIDO_DETAIL: (id) => '/admin/pedido/detail/' + id,
  REGISTRO_FABRICANTE: '/proveedor',
  ADMIN_TIPO_VEHICULO: '/admin/tipo-vehiculo',
  ADMIN_TIPO_VEHICULO_UPDATE: (id) => '/admin/tipo-vehiculo/update/' + id,
  ADMIN_TIPO_VEHICULO_CREATE: '/admin/tipo-vehiculo/create',
  ADMIN_RUBRO: '/admin/rubro',
  ADMIN_CATEGORIA: '/admin/categoria',
  ADMIN_FABRICANTES: (estado) => `/admin/proveedor/${estado}`,
  ADMIN_FABRICANTE_DETAIL: (estado, id) => `/admin/proveedor/${estado}/detail/${id}`,
  ADMIN_FABRICANTE_UPDATE: (estado, id) => `/admin/proveedor/${estado}/update/${id}`,
  ADMIN_FABRICANTE_CREATE: `/admin/proveedor/pendiente/create`,
  ADMIN_FABRICANTE_PUNTO_RETIRO_UPDATE: (estado, fabricante, id) =>
    `/admin/proveedor/${estado}/${fabricante}/punto-retiro/${id}/update`,
  ADMIN_FABRICANTE_PUNTO_RETIRO_CREATE: (estado, fabricante) =>
    `/admin/proveedor/${estado}/${fabricante}/punto-retiro/create`,
  ADMIN_PUNTO_RETIRO_UPDATE: (id) => `/admin/punto-retiro/update/${id}`,
  ADMIN_MARGENES: '/admin/margenes',
  ADMIN_REPORTES: '/admin/reportes',
  ADMIN_SOPORTE: '/admin/soporte',
  ADMIN_ERROR_DETAIL: (id) => `/admin/error/detalle/${id}`,
  ADMIN_TORRE_DE_CONTROL: '/admin/monitoreo',
  ADMIN_PREGUNTAS_FRECUENTES: '/admin/preguntas_frecuentes',

  PREGUNTAS_FRECUENTES: '/preguntas_frecuentes',

  ADMIN_API_CONFIG: '/admin/api-config',
  ADMIN_API_VIEW: '/admin/api-view',
  ADMIN_API_DOC: '/admin/api-documentacion',
  ADMIN_API_LOG: '/admin/api-log',

  FAVORITOS: '/favoritos',
  B2B: '/B2B',
}

export const RUTAS_APP = {
  WELCOME: '/driver/welcome',
  LOGIN: '/driver/login',
  REGISTER_DRIVER: '/driver/registro',
  HOME: '/driver/home',
  OPORTUNIDADES: '/driver/oportunidades',
  MIS_RUTAS: '/driver/mis-rutas',
  OPORTUNIDAD: '/driver/oportunidad',
  RUTA: '/driver/ruta',
  VEHICULOS: '/driver/vehiculos',
  VEHICULO: '/driver/vehiculos/detail',
  VEHICULO_CREATE: '/driver/vehiculos/create',
  PERFIL: '/driver/perfil',
  PERFIL_EDITAR: '/driver/perfil/update',
  GANANCIA: '/driver/ganancia',
  CALIFICACION: '/driver/calificacion',
  CONFIGURACION: '/driver/perfil/configuracion',
  DOCUMENTOS: '/driver/perfil/configuracion/documentos',
}

export const RUTAS_APP_FABRICANTE = {
  HOME: '/proveedor',
  REGISTRO_SUCCESS: '/proveedor/success',
  VENTAS: '/proveedor/ventas', // productos
  PEDIDOS: '/proveedor/pedidos/todo', // pedidos
  PEDIDOS_PENDIENTES: '/proveedor/pedidos/pendiente', // pedidos
  PEDIDOS_RETIRO: '/proveedor/pedidos/retiro', // pedidos
  BALANCE: '/proveedor/balance',
  CATALOGO: '/proveedor/catalogo',
  PRECIOS: '/proveedor/precio',
  INVENTARIO: '/proveedor/inventario',
  IMPORT_EXCEL: '/proveedor/importar',
  PERFIL: '/proveedor/perfil',
  PRODUCTO: (id) => '/proveedor/catalogo/' + id + '/',
  PRODUCTO_UPDATE: (id) => `/proveedor/catalogo/update/${id}/`,
  PROMOCION_CREATE: (producto) => `/proveedor/producto/${producto}/promocion/create/`,
  PROMOCION_UPDATE: (producto, id) => `/proveedor/producto/${producto}/promocion/${id}/`,
  PRODUCTO_CREATE: `/proveedor/catalogo/create/`,
  PUNTOS_RETIRO: '/proveedor/punto-retiro',
  PUNTOS_RETIRO_CREATE: '/proveedor/punto-retiro/create',
  PUNTOS_RETIRO_UPDATE: (id) => `/proveedor/punto-retiro/${id}/`,
  VENTA_DETAIL: (id) => `/proveedor/venta/${id}/`,
  ESTADISTICAS: '/proveedor/estadisticas',
  ATENCION_CLIENTE: '/proveedor/atencion_cliente',
  PREGUNTAS_FRECUENTES: `/proveedor/preguntas-frecuentes`,
  REPORTAR_ERROR: '/proveedor/reportar_error',
  COMENTARIOS_SUGERENCIAS: '/proveedor/comentarios_sugerencias',
  CONFIGURACION_NOTIFICACIONES: '/proveedor/configuracion_notificaciones',
  CONFIGURACION_SONIDO: '/proveedor/configuracion_sonido',
  ELIMINAR_CUENTA: '/proveedor/eliminar_cuenta',
  CONFIGURACION: '/proveedor/configuracion',
  DOCUMENTOS: '/proveedor/documentos',
  CAMBIO_MODO: '/proveedor/modo',
  API: '/proveedor/api',
  HORARIO_ATENCION: '/proveedor/horario_atencion',
  FERIADO: '/proveedor/feriado/create/',
  FERIADO_UPDATE: (id) => '/proveedor/feriado/update/' + id + '/',
  NOTIFICACIONES: '/proveedor/notificaciones/',
}

export const RUTAS_WEB_TRANSPORTISTA = {
  INICIO: '/adm_transportista/home',
  EDITAR: '/adm_transportista/editar/perfil',
  BALANCE: '/adm_transportista/balance',
  VEHICULOS: '/adm_transportista/vehiculos',
  VEHICULO: (id) => '/adm_transportista/vehiculo/' + id + '/',
  VEHICULO_CREATE: '/adm_transportista/vehiculo/create/',
  VEHICULO_UPDATE: (id) => '/adm_transportista/vehiculo/update/' + id + '/',
}

const to_select_format = (constant) => {
  return Object.entries(constant).map((arr) => Object({ value: arr[0], label: arr[1] }))
}

const fix4typescript = (constante) => {
  //envolver el objeto en una funcion confunde al ide de TS y deja de tirar warning
  // de paso le agrego un getter: constante.get(clave) == constante[clave]
  // constante.get = (key) => constante[key]
  return constante
}

export const TIPO_ITINERARIO = {
  ENTREGA: 10,
  RETIRO: 20,
}

export const TIPO_ITINERARIO_STR = fix4typescript({
  10: 'Entrega',
  20: 'Retiro',
})

export const TIPO_ITINERARIO_VERBO_STR = fix4typescript({
  10: 'Entregar',
  20: 'Retirar',
})

export const ESTADO_ITINERARIO = {
  PENDIENTE: 0,
  EN_PROCESO: 10,
  FINALIZADO: 20,
}

export const ESTADO_ITINERARIO_STR = fix4typescript({
  0: 'Pendiente',
  10: 'En proceso',
  20: 'Finalizado',
})

export const FABRICANTE_PRODUCTOS = {
  CATALOGO: 10,
  PRECIOS: 20,
  INVENTARIO: 30,
}

export const TIPOS_REFRIGERACION_STR = fix4typescript({
  0: 'No Refrigerado',
  10: 'Frío',
  20: 'Congelado',
})

export const TIPOS_REFRIGERACION_SELECT = to_select_format(TIPOS_REFRIGERACION_STR)

export const ORDEN_VENTA_ESTADOS_STR = fix4typescript({
  0: 'Creado',
  10: 'Confirmado',
  20: 'Finalizado',
})

export const ORDEN_VENTA_ESTADOS_SELECT = to_select_format(ORDEN_VENTA_ESTADOS_STR)

export const PEDIDO_ESTADOS = {
  creado: 0,
  pagado: 10,
  en_proceso: 20,
  enviado: 30,
  entregado: 40,
  incidente: 50,
}

export const PEDIDO_ESTADOS_STR = fix4typescript({
  0: 'Creado',
  10: 'Pagado',
  20: 'En proceso',
  30: 'Enviado',
  40: 'Entregado',
  50: 'Incidente',
})

export const DIAS_APERTURA = [
  { id: '1', dia: 'Lunes' },
  { id: '2', dia: 'Martes' },
  { id: '3', dia: 'Miércoles' },
  { id: '4', dia: 'Jueves' },
  { id: '5', dia: 'Viernes' },
  { id: '6', dia: 'Sábado' },
  { id: '7', dia: 'Domingo' },
]

export const DIAS_APERTURA_CHOICES = fix4typescript({
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
})

export const MODO_USO_APP = {
  cliente: 10,
  proveedor: 20,
  transportista: 30,
}

export const PEDIDO_ESTADOS_SELECT = to_select_format(PEDIDO_ESTADOS_STR)

export const PEDIDO_ESTADOS_RUTEO = {
  esperando_orden: 10,
  esperando_hora: 20,
  ruteando: 30,
  ruteado: 40,
  no_ruteable_fecha: 50,
  no_ruteable_ruta: 60,
  no_ruteable_vehiculo: 70,
  no_ruteable_no_delivery: 80,
}

export const PEDIDO_ESTADOS_RUTEO_STR = fix4typescript({
  10: 'Esperando orden/es',
  20: 'Esperando hora proceso',
  30: 'En proceso de ruteo',
  40: 'Ruteado',
  50: 'No ruteable: Reagendar',
  60: 'No ruteable: ruta no encontrada',
  70: 'No ruteable: sin vehiculos validos',
  80: 'No ruteable: no es delivery',
})

export const PEDIDO_ESTADOS_RUTEO_SELECT = to_select_format(PEDIDO_ESTADOS_RUTEO_STR)

export const TIPOS_INCIDENTE = {
  10: 'Problema con ruta',
  20: 'Desperfecto mecánico',
  30: 'Problema Pesonal',
}

export const TIPOS_INCIDENTE_INDEXES = {
  PROBLEMA_RUTA: 10,
  DESPERFECTO_MECANICO: 20,
  PROBLEMA_PERSONAL: 30,
}

export const TIPO_CHAT = {
  FABRICANTE_DRIVER: 0,
  CLIENTE_DRIVER: 10,
  STAFF: 20,
}

export const GENERO = {
  MUJER: 10,
  HOMBRE: 20,
  OTRO: 30,
}

export const ICONOS_DIRECCION = fix4typescript({
  casa: 'assets/icon/casa.png',
  empresa: 'assets/icon/empresa.png',
  maletin: 'assets/icon/maletin.png',
  alfiler: 'assets/icon/alfiler.png',
  corazon: 'assets/icon/corazon.png',
})

export const TIPOS_INCIDENTE_SELECT = to_select_format(TIPOS_INCIDENTE)

export const storeOutline =
  "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Storefront</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M448 448V240M64 240v208M382.47 48H129.53c-21.79 0-41.47 12-49.93 30.46L36.3 173c-14.58 31.81 9.63 67.85 47.19 69h2c31.4 0 56.85-25.18 56.85-52.23 0 27 25.46 52.23 56.86 52.23s56.8-23.38 56.8-52.23c0 27 25.45 52.23 56.85 52.23s56.86-23.38 56.86-52.23c0 28.85 25.45 52.23 56.85 52.23h1.95c37.56-1.17 61.77-37.21 47.19-69l-43.3-94.54C423.94 60 404.26 48 382.47 48zM32 464h448M136 288h80a24 24 0 0124 24v88h0-128 0v-88a24 24 0 0124-24zM288 464V312a24 24 0 0124-24h64a24 24 0 0124 24v152'/></svg>"

export const googleLogo =
  'data:image/svg+xmlutf8,<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" /></svg>'

export const FORMATO_FECHA_BACKEND = 'YYYY-MM-DD'
export const FORMATO_DATETIME_BACKEND = 'YYYY-MM-DD HH:MM'
