import { IAnyModelType, types } from 'mobx-state-tree'

export const Categoria = types.model('Categoria').props({
  id: types.number,
  nombre: types.string,
  comision: types.maybeNull(types.number),
  superior: types.maybeNull(types.number),
  icono: types.maybeNull(types.string),
  imagen: types.maybeNull(types.string),
  tiene_productos: types.optional(types.boolean, true),
})

export const CategoriaArbolModel = types.model('CategoriaArbol').props({
  id: types.number,
  nombre: types.string,
  comision: types.maybeNull(types.number),
  superior: types.maybeNull(types.number),
  nivel: types.maybeNull(types.number),
  orden: types.maybeNull(types.string),
  icono: types.maybeNull(types.string),
  imagen: types.maybeNull(types.string),
  hijos: types.array(types.late((): IAnyModelType => CategoriaArbolModel)),
  tiene_productos: types.optional(types.boolean, true),
})
