import {Api} from "../services/api"
import ServiciosUbicacion from "../services/ServiciosUbicacion";

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    this.api = new Api()
    this.locationService = new ServiciosUbicacion()
  }

  async setup() {
    await this.api.setup()
  }

  /**
   * Our api.
   */
  api: Api

  locationService: ServiciosUbicacion
}
