import {Instance, SnapshotOut, types} from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const BuscarStoreModel = types
  .model("BuscarStore")
  .props({
    texto_busqueda: types.optional(types.string, ''),
    empresa: types.optional(types.number, 0),
    rubro: types.optional(types.number, 0),
    categoria: types.optional(types.number, 0),
    calificacion: types.optional(types.string, ''),
  })
  .views(self => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions(self => ({
    setTextoBusqueda(texto_busqueda: string) {
      self.texto_busqueda = texto_busqueda
    },
    setEmpresaBusqueda(empresa: number) {
      self.empresa = empresa
    },
    setRubroBusqueda(rubro: number) {
      self.rubro = rubro
    },
    setCategoriaBusqueda(categoria: number) {
      self.categoria = categoria
    },
    setCalificacionBusqueda(calificacion: string) {
      self.calificacion = calificacion
    },
    reset(){
      self.texto_busqueda = ''
      self.empresa = 0
      self.rubro = 0
      self.categoria = 0
      self.calificacion = ''
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

  /**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type BuscarStoreType = Instance<typeof BuscarStoreModel>
export interface BuscarStore extends BuscarStoreType {}
type BuscarStoreSnapshotType = SnapshotOut<typeof BuscarStoreModel>
export interface BuscarStoreSnapshot extends BuscarStoreSnapshotType {}
