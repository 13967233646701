import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Capacitor, registerPlugin } from '@capacitor/core'
import { RootStore, setupRootStore } from './models'
import { RootStoreProvider } from './models/root-store/root-store-context'
import { RUTAS, RUTAS_APP, RUTAS_APP_FABRICANTE, RUTAS_WEB_TRANSPORTISTA } from './constants'
import { titulos_pag } from './helpers'
import { OneSignal as OneSignalCapacitor } from '@ionic-native/onesignal'
import { FacebookLogin } from '@capacitor-community/facebook-login'

// Firebase web
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'leaflet/dist/leaflet.css'
import 'react-phone-input-2/lib/style.css'

/* Theme variables */
import './theme/style.css'
import './theme/custom.css'
import './theme/variables.css'
import 'swiper/swiper-bundle.min.css'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import 'react-datepicker/dist/react-datepicker.css'
import { actualizarDatosInicioFabricante, useConfirm, setDevice } from './helpers-ts'
import { setInterval } from 'timers'
import LoadingApp from './components/LoadingApp'

registerPlugin('FacebookLogin', {
  web: () => FacebookLogin,
})

const { ID_ONE_SIGNAL, GOOGLE_PROJECT_NUMBER } = require('./config/env')

const App: React.FC = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  const onKeyUp = (event: { keyCode: number; key: string; target: any }) => {
    if (event.target.tagName === 'INPUT' && event.key === 'Enter') {
      const elementos = [...(document.querySelectorAll('INPUT,BUTTON') as any)]
      const elemento = elementos[elementos.indexOf(event.target) + 1]
      elemento && elemento.focus()
      return
    }
    if (event.keyCode === 27) {
      // Escape
      // state es nulo cuando esta en la página inicial
      window.history.state && window.history.back()
    }
  }

  const redireccionChat = (pedidoId: number) => {
    if (!rootStore) {
      return
    }
    const { loginStore } = rootStore
    let currentUrlParams = new URLSearchParams(window.location.search)
    currentUrlParams.set('chat', '1')
    currentUrlParams.set('pedido', String(pedidoId))
    if (loginStore.modo_uso_app === 10) {
      // cliente
      window.location.href = RUTAS.RASTREAR_PEDIDO(pedidoId) + '?' + currentUrlParams.toString()
    } else if (loginStore.modo_uso_app === 20) {
      //fabricante
      window.location.href = window.location.pathname + '?' + currentUrlParams.toString()
    } else if (loginStore.modo_uso_app === 30) {
      window.location.href = RUTAS_APP.HOME + '?' + currentUrlParams.toString()
    }
  }

  useEffect(() => {
    if (!rootStore) {
      return
    }
    const { loginStore } = rootStore
    // Setear el device del usuario en el backend para que le lleguen las notificaciones
    if (loginStore.isLoggedIn) {
      setDevice(loginStore)
    }
  }, [rootStore?.loginStore.isLoggedIn])

  const redirigirPush = (additionalData: any) => {
    if (!rootStore) {
      return
    }
    const { loginStore } = rootStore
    const random = Math.floor(1000 + Math.random() * 9000)
    if (additionalData.pedido_id && additionalData.chat) {
      redireccionChat(additionalData.pedido_id)
    } else if (loginStore.transportista !== null && additionalData.oportunidades) {
      window.location.href = `${RUTAS_APP.OPORTUNIDADES}/?r=${random}`
    } else if (additionalData.pedidos) {
      window.location.href = `${RUTAS_APP_FABRICANTE.PEDIDOS}?r=${random}`
    } else if (additionalData.vehiculo_aprobado) {
      window.location.href = `${RUTAS_APP.VEHICULOS}`
    } else if (additionalData.transportista_aprobado) {
      window.location.href = `${RUTAS_APP.PERFIL}`
    } else if (additionalData.fabricante_aprobado) {
      window.location.href = `${RUTAS_APP_FABRICANTE.PERFIL}`
    }
  }

  const acualizacionesAfterPush = (additionalData: any) => {
    if (!rootStore) {
      return
    }
    if (additionalData.transportista_aprobado || additionalData.vehiculo_aprobado) {
      const { transportistaStore } = rootStore
      if (transportistaStore && transportistaStore.transportista?.id) {
        rootStore.environment.api.adminGetTransportista(transportistaStore.transportista?.id).then((result) => {
          if (result.kind === 'ok') {
            transportistaStore.setTransportista(result.transportista)
          }
        })
      }
    } else if (additionalData.fabricante_aprobado) {
      const { loginStore } = rootStore
      if (loginStore.fabricante && loginStore.fabricante.id) {
        rootStore.environment.api.getFabricante(loginStore.fabricante.id).then((result) => {
          if (result.kind === 'ok') {
            loginStore.setFabricante(result.fabricante)
          }
        })
      }
    }
  }

  useEffect(() => {
    Capacitor.getPlatform() === 'web' && document.addEventListener('keyup', onKeyUp)
    return () => {
      Capacitor.getPlatform() === 'web' && document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  const setupFirebase = () => {
    try {
      firebase.initializeApp({
        apiKey: 'AIzaSyAtyOvDDxjv_jbTEV7o9u8Dna7ApjjJxBY',
        authDomain: 'abi-ecosystem.firebaseapp.com',
        projectId: 'abi-ecosystem',
        storageBucket: 'abi-ecosystem.appspot.com',
        messagingSenderId: '982900441673',
        appId: '1:982900441673:web:a4e070889a6d8da884b598',
        measurementId: 'G-QNM3CT2WV4',
      })
    } catch (err) {
      console.log('Error firebase.initializeApp')
    }
  }

  const setupFacebook = () => {
    if (Capacitor.getPlatform() === 'web') {
      // @ts-ignore
      window.fbAsyncInit = function () {
        // @ts-ignore
        FB.init({
          appId: '278092043691644',
          cookie: true, // enable cookies to allow the server to access the session
          xfbml: true, // parse social plugins on this page
          version: 'v5.0', // use graph api current version
        })
      }
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        // @ts-ignore
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        // @ts-ignore
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  }

  const setupAnalytics = () => {
    if (Capacitor.getPlatform() === 'web') {
      const dataLayer = (window as any).dataLayer || []

      // @ts-ignore
      function gtag() {
        dataLayer.push(arguments)
      }

      // @ts-ignore
      gtag('js', new Date())
      // @ts-ignore
      gtag('config', 'G-QNM3CT2WV4')
    }
  }

  const setupOneSignal = () => {
    if (!ID_ONE_SIGNAL) {
      if (Capacitor.getPlatform() === 'ios') {
        alert('ID_ONE_SIGNAL nulo, en iOS puede crear problemas')
      }
      return
    }
    if (Capacitor.getPlatform() === 'web') {
      const OneSignal = (window as any).OneSignal || []
      OneSignal.push(function () {
        OneSignal.init({
          appId: ID_ONE_SIGNAL,
          autoRegister: false,
          notifyButton: {
            enable: true,
          },
        })
      })
      OneSignal.push(function () {
        OneSignal.getSubscription(function (notOptedOut: any) {
          // console.log('OneSignal notOptedOut', notOptedOut)
          OneSignal.registerForPushNotifications()
        })
      })
    } else {
      OneSignalCapacitor.startInit(ID_ONE_SIGNAL, GOOGLE_PROJECT_NUMBER)
    }
    // Registrar listeners de eventos
    const Movil = Capacitor.getPlatform() !== 'web'

    if (Movil) {
      OneSignalCapacitor.inFocusDisplaying(OneSignalCapacitor.OSInFocusDisplayOption.None)
      OneSignalCapacitor.handleNotificationReceived().subscribe((data: any) => {
        const additionalData = data.payload.additionalData
        acualizacionesAfterPush(additionalData)
        if (data.isAppInFocus && data.payload) {
          const msg = data.payload.body
          const title = data.payload.title
          mostrarDialogo(title + '\r\n' + msg, additionalData)
        }
      })
      OneSignalCapacitor.handleNotificationOpened().subscribe((data) => {
        if (data.notification && data.notification.payload) {
          const additionalData = data.notification.payload.additionalData
          redirigirPush(additionalData)
        }
      })
      OneSignalCapacitor.endInit()
    } else {
      const OneSignal = (window as any).OneSignal || []
      OneSignal.push([
        'addListenerForNotificationOpened',
        function (event: any) {
          redirigirPush(event.data)
        },
      ])
    }
  }

  const setupFabricante = useCallback(
    (rootStore: RootStore) => {
      const { loginStore } = rootStore
      let intervalId: NodeJS.Timeout | null = null // Declare interval ID variable

      if (loginStore.modo_uso_app === 20 && loginStore.fabricante_id) {
        // Esta como fabricante
        intervalId = setInterval(() => {
          actualizarDatosInicioFabricante(rootStore)
        }, 30000)
      }

      // Clear the interval when the component unmounts
      return () => {
        if (intervalId !== null) {
          clearInterval(intervalId)
        }
      }
    },
    [actualizarDatosInicioFabricante],
  )

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    const setup = async () => {
      const numeral = require('numeral')
      const moment = require('moment-timezone')
      const esLocale = require('moment/locale/es')

      // Set up numeral
      try {
        numeral.register('locale', 'py_custom', {
          delimiters: {
            thousands: '.',
            decimal: ',',
          },
          abbreviations: {
            thousand: 'm',
            million: 'mm',
            billion: 'b',
            trillion: 't',
          },
          ordinal: function (number: number) {
            return number === 1 ? 'guaraní' : 'guaraníes'
          },
          currency: {
            symbol: 'Gs. ',
          },
        })
        numeral.locale('py_custom')
      } catch (e) {
        console.log('Error al configurar numeral', e)
      }

      // Set up moment
      moment.updateLocale('es', esLocale)
      moment.tz.setDefault('America/Asuncion')

      // Set up root store and other configurations
      const rootStore = await setupRootStore()
      setRootStore(rootStore)
      setupFabricante(rootStore)
      setupFirebase()
      setupOneSignal()
      setupFacebook()
      setupAnalytics()
    }

    setup()
  }, [])

  const ruta = (path: string, titulo: string, componente: any, exact = true) => {
    ;(titulos_pag as any)[path as any] = titulo
    return <Route key={titulo} path={path} component={componente} exact={exact} />
  }

  const [mostrarDialogo, ConfirmDialog] = useConfirm((additionalData: any) => {
    if (!rootStore) {
      return
    }
    const { loginStore } = rootStore
    if (rootStore && rootStore.loginStore) {
      if (loginStore.isLoggedIn) {
        redirigirPush(additionalData)
      }
    }
  })

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color. You can replace
  // with your own loading component if you wish.
  if (!rootStore) return null

  return (
    <IonApp>
      {ConfirmDialog}
      <Suspense fallback={<LoadingApp />}>
        <RootStoreProvider value={rootStore}>
          <IonReactRouter>
            <IonRouterOutlet>
              {ruta(
                RUTAS.ADMIN_BUSQUEDA,
                '',
                lazy(() => import('./pages/Admin/AdminBusqueda')),
              )}
              {ruta(
                RUTAS.B2B,
                '',
                lazy(() => import('./pages/B2B/B2B')),
              )}
              {ruta(
                RUTAS.ADMIN_TIPO_VEHICULO,
                '',
                lazy(() => import('./pages/Admin/AdminTipoVehiculo')),
              )}
              {ruta(
                RUTAS.ADMIN_TIPO_VEHICULO_UPDATE(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminTipoVehiculoUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_TIPO_VEHICULO_CREATE,
                '',
                lazy(() => import('./pages/Admin/AdminTipoVehiculoUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_CLIENTE,
                '',
                lazy(() => import('./pages/Admin/AdminCliente')),
              )}
              {ruta(
                RUTAS.ADMIN_CLIENTE_DETAIL(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminClienteDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_CLIENTE_UPDATE(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminClienteUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_CREATE_DIRECCION_CLIENTE(':cliente(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminDireccionUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_UPDATE_DIRECCION_CLIENTE(':cliente(\\d+)', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminDireccionUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_TRANSPORTISTAS(':estado'),
                'Drivers',
                lazy(() => import('./pages/Admin/AdminTransportista')),
              )}
              {ruta(
                RUTAS.ADMIN_TRANSPORTISTA_UPDATE(':estado', ':id'),
                'Editar Driver',
                lazy(() => import('./pages/Admin/AdminTransportistaUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_TRANSPORTISTA_CREATE,
                'Crear Driver',
                lazy(() => import('./pages/Admin/AdminTransportistaUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_TRANSPORTISTA_DETAIL(':estado', ':id'),
                'Ver Driver',
                lazy(() => import('./pages/Admin/AdminTransportistaDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_VEHICULOS(':estado'),
                '',
                lazy(() => import('./pages/Admin/AdminVehiculo')),
              )}
              {ruta(
                RUTAS.ADMIN_VEHICULO_DETAIL(':estado', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminVehiculoDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_VEHICULO_UPDATE(':estado', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminVehiculoUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_VEHICULO_CREATE,
                '',
                lazy(() => import('./pages/Admin/AdminVehiculoUpdate')),
              )}

              {ruta(
                RUTAS.ADMIN_FABRICANTES(':estado'),
                '',
                lazy(() => import('./pages/Admin/AdminFabricante')),
              )}
              {ruta(
                RUTAS.ADMIN_FABRICANTE_UPDATE(':estado', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminFabricanteUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_FABRICANTE_CREATE,
                '',
                lazy(() => import('./pages/Admin/AdminFabricanteUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_FABRICANTE_DETAIL(':estado', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminFabricanteDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_FABRICANTE_PUNTO_RETIRO_UPDATE(':estado', ':fabricante(\\d+)', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminPuntoRetiroUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_FABRICANTE_PUNTO_RETIRO_CREATE(':estado', ':fabricante(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminPuntoRetiroUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_MARGENES,
                '',
                lazy(() => import('./pages/Admin/AdminMargenes')),
              )}
              {ruta(
                RUTAS.ADMIN_SOPORTE,
                '',
                lazy(() => import('./pages/Admin/AdminSoporte')),
              )}
              {ruta(
                RUTAS.ADMIN_ERROR_DETAIL(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminErrorDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_PUNTO_RETIRO_UPDATE(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminPuntoRetiroUpdate')),
              )}
              {ruta(
                '/admin/staff',
                '',
                lazy(() => import('./pages/Admin/AdminStaff')),
              )}
              {ruta(
                RUTAS.ADMIN_ORDEN_VENTA,
                '',
                lazy(() => import('./pages/Admin/AdminOrdenVenta')),
              )}
              {ruta(
                RUTAS.ADMIN_ORDEN_VENTA_DETAIL(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminOrdenVentaDetail')),
              )}
              {ruta(
                '/admin/imprimir/:id',
                '',
                lazy(() => import('./pages/Admin/AdminImprimir')),
              )}
              {ruta(
                '/admin/hoja_ruta',
                '',
                lazy(() => import('./pages/Admin/AdminHojaRuta')),
              )}
              {ruta(
                '/admin/hoja_ruta/detail/:id',
                '',
                lazy(() => import('./pages/Admin/AdminHojaRutaDetail')),
              )}
              {ruta(
                '/admin/hoja_ruta/itinerario/:id',
                '',
                lazy(() => import('./pages/Admin/AdminHojaRutaItinerario')),
              )}
              {ruta(
                RUTAS.ADMIN_API_CONFIG,
                'Configurar acceso API',
                lazy(() => import('./pages/Admin/AdminApiConfig')),
              )}
              {ruta(
                RUTAS.ADMIN_API_VIEW,
                'Ver detalles de acceso API',
                lazy(() => import('./pages/Admin/AdminApiView')),
              )}
              {ruta(
                RUTAS.ADMIN_API_DOC,
                'Documentacion API',
                lazy(() => import('./pages/Admin/AdminApiDoc')),
              )}
              {ruta(
                RUTAS.ADMIN_API_LOG,
                'Registro de Requests API Proveedor',
                lazy(() => import('./pages/Admin/AdminApiLogs')),
              )}
              {ruta(
                RUTAS.ADMIN_PEDIDOS,
                '',
                lazy(() => import('./pages/Admin/AdminPedidos')),
              )}
              {ruta(
                RUTAS.ADMIN_BALANCE,
                '',
                lazy(() => import('./pages/Admin/AdminBalance')),
              )}
              {ruta(
                RUTAS.ADMIN_RENDICION,
                '',
                lazy(() => import('./pages/Admin/AdminRendicion')),
              )}
              {ruta(
                RUTAS.ADMIN_PAGO_PROVEEDOR_CREATE,
                '',
                lazy(() => import('./pages/Admin/AdminPagoCreate')),
              )}
              {ruta(
                RUTAS.ADMIN_PAGO_PROVEEDOR,
                '',
                lazy(() => import('./pages/Admin/AdminPago')),
              )}
              {ruta(
                RUTAS.ADMIN_PAGO_CLIENTE,
                '',
                lazy(() => import('./pages/Admin/AdminPago')),
              )}
              {ruta(
                RUTAS.ADMIN_FACTURA_CLIENTE,
                '',
                lazy(() => import('./pages/Admin/AdminFactura')),
              )}
              {ruta(
                RUTAS.ADMIN_FACTURA_PROVEEDOR,
                '',
                lazy(() => import('./pages/Admin/AdminFactura')),
              )}
              {ruta(
                RUTAS.ADMIN_FACTURA_PROVEEDOR_DETAIL(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminFacturaDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_FACTURA_CLIENTE_DETAIL(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminFacturaDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_PEDIDO_DETAIL(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminPedidoDetail')),
              )}
              {ruta(
                '/admin/staff/update',
                '',
                lazy(() => import('./pages/Admin/AdminStaffUpdate')),
              )}
              {ruta(
                '/admin/staff/detail',
                '',
                lazy(() => import('./pages/Admin/AdminStaffDetail')),
              )}
              {ruta(
                '/admin/configuracion',
                '',
                lazy(() => import('./pages/Admin/AdminConfiguracion')),
              )}
              {ruta(
                RUTAS.ADMIN_COMENTARIO_PRODUCTO,
                '',
                lazy(() => import('./pages/Admin/AdminComentarioProducto')),
              )}
              {ruta(
                RUTAS.ADMIN_PRODUCTO(':estado'),
                '',
                lazy(() => import('./pages/Admin/AdminProducto')),
              )}
              {ruta(
                RUTAS.ADMIN_PRODUCTO_CREATE(':estado'),
                '',
                lazy(() => import('./pages/Admin/AdminProductoUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_PRODUCTO_UPDATE(':estado', ':id'),
                '',
                lazy(() => import('./pages/Admin/AdminProductoUpdate')),
              )}
              {ruta(
                RUTAS.ADMIN_PRODUCTO_DETAILD(':id'),
                '',
                lazy(() => import('./pages/Admin/AdminProductoDetail')),
              )}
              {ruta(
                RUTAS.ADMIN_PRODUCTO_DETAIL(':estado', ':id'),
                '',
                lazy(() => import('./pages/Admin/AdminProductoDetail')),
              )}
              {ruta(
                '/admin/precio',
                '',
                lazy(() => import('./pages/Admin/AdminPrecio')),
              )}
              {ruta(
                '/admin/inventario',
                '',
                lazy(() => import('./pages/Admin/AdminInventario')),
              )}
              {ruta(
                RUTAS.ADMIN,
                '',
                lazy(() => import('./pages/Admin/AdminDashboard')),
              )}
              {ruta(
                RUTAS.RECUPERAR_CONTRASENA,
                '',
                lazy(() => import('./pages/Cliente/RecuperarContrasena')),
              )}
              {ruta(
                RUTAS.ADMIN_RUBRO,
                '',
                lazy(() => import('./pages/Admin/AdminRubro')),
              )}
              {ruta(
                RUTAS.ADMIN_CATEGORIA,
                '',
                lazy(() => import('./pages/Admin/AdminCategoria')),
              )}
              {ruta(
                RUTAS.ADMIN_REPORTES,
                '',
                lazy(() => import('./pages/Admin/AdminReportes')),
              )}
              {ruta(
                RUTAS.ADMIN_TORRE_DE_CONTROL,
                'Torre de control',
                lazy(() => import('./pages/Admin/AdminTorreDeControl')),
              )}
              {ruta(
                RUTAS.ADMIN_PREGUNTAS_FRECUENTES,
                'Preguntas frecuentes',
                lazy(() => import('./pages/Admin/AdminPreguntasFrecuentes')),
              )}
              {ruta(
                RUTAS.PREGUNTAS_FRECUENTES,
                'Preguntas frecuentes',
                lazy(() => import('./pages/Cliente/PreguntasFrecuentes')),
              )}
              {ruta(
                RUTAS.PRIVACIDAD,
                'Politicas de Privacidad',
                lazy(() => import('./pages/Documentos/privacidad')),
              )}

              {/*/!*============== Vistas restringidas solo para cliente ==============*!/*/}

              {ruta(
                RUTAS.ROOT,
                'Compra todo en un solo lugar | Tienda Online',
                lazy(() => import('./pages/Cliente/Home')),
              )}
              {ruta(
                RUTAS.LOGOUT,
                '',
                lazy(() => import('./pages/Cliente/Logout')),
              )}
              {ruta(
                RUTAS.CLIENTE,
                '',
                lazy(() => import('./pages/Cliente/ClienteDetail')),
              )}
              {ruta(
                RUTAS.CLIENTE_DIRECCION_UPDATE,
                '',
                lazy(() => import('./pages/Cliente/ClienteDireccionUpdate')),
              )}
              {ruta(
                RUTAS.BUSCAR,
                '',
                lazy(() => import('./pages/Cliente/Busqueda')),
                false,
              )}
              {ruta(
                RUTAS.CATEGORIAS,
                '',
                lazy(() => import('./pages/Cliente/Categorias')),
              )}
              {ruta(
                RUTAS.CATEGORIA_LISTADO_PRODUCTOS(':id', ':nombre'),
                '',
                lazy(() => import('./pages/Cliente/Categorias')),
              )}
              {ruta(
                RUTAS.CARRITO,
                'Carrito de compras',
                lazy(() => import('./pages/Cliente/Carrito')),
              )}
              {ruta(
                RUTAS.FAVORITOS,
                '',
                lazy(() => import('./pages/Cliente/Favoritos')),
              )}
              {ruta(
                RUTAS.PEDIDO_COMPLETADO,
                '',
                lazy(() => import('./pages/Cliente/Pedido')),
              )}
              {ruta(
                RUTAS.RASTREAR_PEDIDO(':id'),
                '',
                lazy(() => import('./pages/Cliente/PedidoRastreo')),
              )}
              {ruta(
                RUTAS.PRODUCTO_DETAIL(':id', ':nombre'),
                'Ver producto',
                lazy(() => import('./pages/Cliente/ProductoDetail')),
              )}

              {/*/!*============== Rutas de la app transportista ==============*!/*/}
              {ruta(
                RUTAS_APP.HOME,
                '',
                lazy(() => import('./pages/Transportista/HomeTransportista')),
              )}
              {ruta(
                RUTAS_APP.REGISTER_DRIVER,
                '',
                lazy(() => import('./pages/Driver/Driver')),
              )}
              {ruta(
                RUTAS_APP.OPORTUNIDADES,
                '',
                lazy(() => import('./pages/Transportista/OportunidadesTransportista')),
              )}
              {ruta(
                RUTAS_APP.OPORTUNIDAD,
                '',
                lazy(() => import('./pages/Transportista/OportunidadTransportista')),
              )}
              {ruta(
                RUTAS_APP.MIS_RUTAS,
                '',
                lazy(() => import('./pages/Transportista/RutasTransportista')),
              )}
              {ruta(
                RUTAS_APP.RUTA,
                '',
                lazy(() => import('./pages/Transportista/OportunidadTransportista')),
              )}
              {ruta(
                RUTAS_APP.VEHICULOS,
                '',
                lazy(() => import('./pages/Transportista/VehiculosTransportista')),
              )}
              {ruta(
                RUTAS_APP.VEHICULO,
                '',
                lazy(() => import('./pages/Transportista/VehiculoTransportista')),
              )}
              {ruta(
                RUTAS_APP.VEHICULO_CREATE,
                '',
                lazy(() => import('./pages/Transportista/VehiculoTransportistaCreate')),
              )}
              {ruta(
                RUTAS_APP.PERFIL,
                '',
                lazy(() => import('./pages/Transportista/PerfilTransportista')),
              )}
              {ruta(
                RUTAS_APP.DOCUMENTOS,
                '',
                lazy(() => import('./pages/Transportista/DocumentosTransportista')),
              )}
              {ruta(
                RUTAS_APP.PERFIL_EDITAR,
                '',
                lazy(() => import('./pages/Transportista/PerfilTransportistaUpdate')),
              )}
              {ruta(
                RUTAS_APP.GANANCIA,
                '',
                lazy(() => import('./pages/Transportista/GananciaTransportista')),
              )}
              {ruta(
                RUTAS_APP.CONFIGURACION,
                '',
                lazy(() => import('./pages/Transportista/ConfiguracionTransportista')),
              )}

              {/*/!*============== Rutas de la app fabricante ==============*!/*/}
              {ruta(
                RUTAS_APP_FABRICANTE.HOME,
                '',
                lazy(() => import('./pages/Fabricante/Home')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.REGISTRO_SUCCESS,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteRegistroSuccess')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.VENTAS,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteVenta')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PEDIDOS,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePedidos')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PEDIDOS_PENDIENTES,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePedidos')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PEDIDOS_RETIRO,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePedidos')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.BALANCE,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteBalance')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.CATALOGO,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteCatalogo')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.IMPORT_EXCEL,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteImportarExcel')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PERFIL,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePerfil')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PRECIOS,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteCatalogo')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.INVENTARIO,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteCatalogo')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PUNTOS_RETIRO,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePuntosRetiro')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PRODUCTO(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Fabricante/FabricanteProductoDetalle')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PRODUCTO_UPDATE(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Fabricante/FabricanteProductoUpdate')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PROMOCION_CREATE(':producto(\\d+)'),
                '',
                lazy(() => import('./pages/Fabricante/FabricantePromocionUpdate')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PROMOCION_UPDATE(':producto(\\d+)', ':id(\\d+)'),
                '',
                lazy(() => import('./pages/Fabricante/FabricantePromocionUpdate')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PRODUCTO_CREATE,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteProductoUpdate')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PUNTOS_RETIRO_CREATE,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePuntoRetiroUpdate')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PUNTOS_RETIRO_UPDATE(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Fabricante/FabricantePuntoRetiroUpdate')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.VENTA_DETAIL(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Fabricante/FabricanteVentaDetail')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.ESTADISTICAS,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteEstadisticas')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.ATENCION_CLIENTE,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteAtencionCliente')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.PREGUNTAS_FRECUENTES,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePreguntasFrecuentes')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.COMENTARIOS_SUGERENCIAS,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteComentariosReportar')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.REPORTAR_ERROR,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteComentariosReportar')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.CONFIGURACION_NOTIFICACIONES,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePermisos')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.CONFIGURACION_SONIDO,
                '',
                lazy(() => import('./pages/Fabricante/FabricantePermisos')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.ELIMINAR_CUENTA,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteEliminarCuenta')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.CONFIGURACION,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteConfiguracion')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.DOCUMENTOS,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteDocumentos')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.CAMBIO_MODO,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteCambioModo')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.API,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteApi')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.HORARIO_ATENCION,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteHorarioAtencion')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.FERIADO,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteFeriado')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.FERIADO_UPDATE(':id'),
                '',
                lazy(() => import('./pages/Fabricante/FabricanteFeriado')),
              )}
              {ruta(
                RUTAS_APP_FABRICANTE.NOTIFICACIONES,
                '',
                lazy(() => import('./pages/Fabricante/FabricanteNotificaciones')),
              )}
              {/*/!*============== Rutas de la  vista web del transportista ==============*!/*/}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.INICIO,
                '',
                lazy(() => import('./pages/Admin/AdminTransportistaPerfil')),
              )}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.EDITAR,
                '',
                lazy(() => import('./pages/Admin/AdminTransportistaUpdate')),
              )}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.BALANCE,
                '',
                lazy(() => import('./pages/Admin/AdminBalance')),
              )}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.VEHICULOS,
                '',
                lazy(() => import('./pages/Admin/AdminVehiculo')),
              )}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.VEHICULO_CREATE,
                '',
                lazy(() => import('./pages/Admin/AdminVehiculoUpdate')),
              )}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.VEHICULO_UPDATE(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminVehiculoUpdate')),
              )}
              {ruta(
                RUTAS_WEB_TRANSPORTISTA.VEHICULO(':id(\\d+)'),
                '',
                lazy(() => import('./pages/Admin/AdminVehiculoDetail')),
              )}
            </IonRouterOutlet>
          </IonReactRouter>
        </RootStoreProvider>
      </Suspense>
    </IonApp>
  )
}

export default App
