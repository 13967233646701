import {types} from "mobx-state-tree"

export const TipoVehiculo = types
  .model("TipoVehiculo")
  .props({
    id: types.number,
    nombre: types.string,
    subida_bandera: types.maybeNull(types.number),
    precio_minimo: types.maybeNull(types.number),
    precio_km: types.maybeNull(types.number),
    precio_minuto: types.maybeNull(types.number),
    refrigeracion: types.maybeNull(types.number),
    precio_parada: types.maybeNull(types.number),
    peso_maximo_kg: types.maybeNull(types.number),
    espacio_alto_cm: types.maybeNull(types.number),
    espacio_ancho_cm: types.maybeNull(types.number),
    espacio_profundidad_cm: types.maybeNull(types.number),
  })
  .views(self => ({
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default TipoVehiculo
