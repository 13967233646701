import './helpers-leaflet.css'

import { BackgroundGeolocationResponse } from '@ionic-native/background-geolocation'
import * as L from 'leaflet'
import React, { useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import * as FIcon from 'react-feather'
import { BsFillFlagFill } from 'react-icons/bs'

import { protectedRef } from '.'
import { LinkButton } from '../components/LinkButton'
import { ESTADO_ITINERARIO, RUTAS, TIPO_ITINERARIO, TIPO_ITINERARIO_STR } from '../constants'
import { fechahora_fmt, segundos_to_str, string2leaflet_location } from '../helpers'
import { ItinerarioApi } from '../services/api'
import { Marker, Polyline, Popup } from 'react-leaflet'

export const iconoGloboLeaflet = (
  children: JSX.Element,
  colorBorde = 'black',
  className = 'icono-globo',
  colorFondo = 'white',
) => {
  return L.divIcon({
    className: className,
    iconSize: [32, 32],
    html: renderToStaticMarkup(
      <div
        style={{
          background: colorFondo,
          width: '32px',
          height: '32px',
          borderColor: colorBorde,
          borderWidth: '2px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          borderStyle: 'solid',
        }}
      >
        {children}
      </div>,
    ),
  })
}

export const iconoImagen = (children: JSX.Element, className: string) => {
  return L.divIcon({
    className: className,
    iconSize: [48, 48],
    html: renderToStaticMarkup(<>{children}</>),
  })
}

export const ICONOS_ITINERARIO = {
  usuario: iconoImagen(<img src={'assets/icon/cliente-map-pin.png'} alt={'usuario'} />, 'icono-marker-pin-container'),
  fabricante: iconoImagen(<img src={'assets/icon/store-map-pin.png'} alt={'store'} />, 'icono-marker-pin-container'),
  transportista: iconoGloboLeaflet(
      <img src={'assets/icon/driver_gray.png'} alt={'driver'} height={32} width={32} />,
      '#777777',
  ),
}

export const ICONOS_PERFIL = {
  pin: iconoImagen(<img src={'assets/icon/ubicacion-red.png'} alt={'pin'} />, 'icono-marker-pin-container'),
}

export const ICONOS_GLOBO = {
  usuario: iconoGloboLeaflet(<FIcon.User size={26} style={{ color: 'blue' }} />, '#303AB2'),
  usuario_working: iconoGloboLeaflet(
    <div className={'icono-globo-container'}>
      <FIcon.User size={26} style={{ color: 'blue' }} />
      <div className={'icono-globo-check-interno'} style={{ background: '#ffa828' }}>
        <FIcon.Settings size={14} style={{ color: 'white' }} />
      </div>
    </div>,
    '#303AB2',
  ),
  usuario_done: iconoGloboLeaflet(
    <div className={'icono-globo-container'}>
      <FIcon.User size={26} style={{ color: 'blue' }} />
      <div className={'icono-globo-check-interno'}>
        <FIcon.Check size={16} style={{ color: 'white' }} />
      </div>
    </div>,
    '#303AB2',
  ),
  usuario_multiple: iconoGloboLeaflet(
    <div className={'icono-globo-container'}>
      <FIcon.User size={26} style={{ color: 'blue' }} />
      <div className={'icono-globo-check-interno'}>
        <FIcon.MoreHorizontal size={16} style={{ color: 'white' }} />
      </div>
    </div>,
    '#303AB2',
  ),
  fabricante: iconoGloboLeaflet(<img src={'assets/icon/store.png'} alt={'store'} height={28} width={28} />, '#fc4c02'),

  fabricante2: iconoGloboLeaflet(<img src={'assets/icon/store.png'} alt={'store'} height={28} width={28} />, '#303AB2'),
  fabricante_working: iconoGloboLeaflet(
    <div className={'icono-globo-container'}>
      <img src={'assets/icon/store.png'} alt={'store'} height={28} width={28} />
      <div className={'icono-globo-check-interno'} style={{ background: '#ffa828' }}>
        <FIcon.Settings size={14} style={{ color: 'white' }} />
      </div>
    </div>,
    '#fc4c02',
  ),
  fabricante_done: iconoGloboLeaflet(
    <div className={'icono-globo-container'}>
      <img src={'assets/icon/store.png'} alt={'store'} height={28} width={28} />
      <div className={'icono-globo-check-interno'}>
        <FIcon.Check size={16} style={{ color: 'white' }} />
      </div>
    </div>,
    '#fc4c02',
  ),
  fabricante_multiple: iconoGloboLeaflet(
    <div className={'icono-globo-container'}>
      <img src={'assets/icon/store.png'} alt={'store'} height={28} width={28} />
      <div className={'icono-globo-check-interno'} style={{ background: '#ffa828' }}>
        <FIcon.MoreHorizontal size={14} style={{ color: 'white' }} />
      </div>
    </div>,
    '#fc4c02',
  ),
  transportista: iconoGloboLeaflet(
    <img src={'assets/icon/driver.png'} alt={'driver'} height={32} width={32} />,
    '#303AB2',
  ),

  transportista2: iconoGloboLeaflet(
    <img src={'assets/icon/truck_orange.png'} alt={'truck'} height={30} width={30} />,
    '#303AB2',
  ),
  transportista_gris: iconoGloboLeaflet(
    <img src={'assets/icon/driver_gray.png'} alt={'driver'} height={32} width={32} />,
    '#777777',
  ),
}

export const get_icono_itinerario_multiple = (itinerario: any[]) => {
  if (itinerario.length && itinerario[0][0].fabricante) return ICONOS_GLOBO.fabricante_multiple
  return ICONOS_GLOBO.usuario_multiple
}

export const get_icono_itinerario = (itinerario: ItinerarioApi) => {
  if (itinerario.tipo == TIPO_ITINERARIO.RETIRO) {
    return ICONOS_ITINERARIO.fabricante
  } else {
    return ICONOS_ITINERARIO.usuario
  }
}

export const centroUbicaciones = (...ubicaciones: L.LatLngLiteral[]) => {
  if (ubicaciones.length > 0)
    return {
      lat: ubicaciones.reduce((suma, loc) => suma + loc.lat, 0) / ubicaciones.length,
      lng: ubicaciones.reduce((suma, loc) => suma + loc.lng, 0) / ubicaciones.length,
    }
  else return { lng: -57.63591, lat: -25.30066 }
}

export const centroItinerarios = (itinerarios: L.LatLngLiteral[]) => {
  if (itinerarios.length > 0)
    return {
      lat: itinerarios.reduce((suma, loc) => suma + loc.lat, 0) / itinerarios.length,
      lng: itinerarios.reduce((suma, loc) => suma + loc.lng, 0) / itinerarios.length,
    }
  else return { lng: -57.63591, lat: -25.30066 }
}

const polycolors = ['red', 'black', 'green', 'yellow', 'gray']
const get_polycolor = (idx: number) => polycolors[idx % polycolors.length]

export const itinerarios2polyline = (itinerarios: [], idx: number | null = null) => {
  let convertidos = itinerarios.map((i: any) => string2leaflet_location(i.ubicacion))
  return (
    <Polyline
      key={`polyline-${idx ? idx : 'uq'}`}
      positions={convertidos.map((ubi: L.LatLngLiteral): L.LatLngTuple => [ubi.lat, ubi.lng])}
      color={get_polycolor(idx ? idx : 0)}
    />
  )
}

export const itinerario_marker = (
  itinerario: ItinerarioApi,
  popup: JSX.Element | null = null,
  hoja: any | null = null,
) =>
  itinerario.ubicacion === '' ? null : (
    <Marker
      key={`itinerario-marker-${hoja ? hoja.id : ''}-${itinerario.orden}`}
      position={string2leaflet_location(itinerario.ubicacion)}
      icon={get_icono_itinerario(itinerario)}
    >
      {popup}
    </Marker>
  )

// export const transportista_popup = (itinerario: ItinerarioApi) => {
//     return <Popup className={'itinerario-popup'}>
//         <div className={'itinerario-columna-container'}>
//             <div className={'itinerario-columna1'}>
//                 <span className={'itinerario-popup-orden'}>{itinerario.orden}</span>
//                 <span className={''}>{TIPO_ITINERARIO_STR[itinerario.tipo]}</span>
//             </div>
//             <div className={'itinerario-columna2'}>
//                 <span>{fechahora_fmt(itinerario.fecha_hora_calculada_desde, 'DD/MM/YYYY')}{' '}
//                     <span style={{fontWeight: 'bold'}}>
//                         {fechahora_fmt(itinerario.fecha_hora_calculada_desde, 'HH:mm')}
//                     </span>
//                 </span>
//                 {itinerario.usuario && <span>{itinerario.usuario}</span>}
//                 {!itinerario.usuario && <span>{itinerario.fabricante}</span>}
//                 <span>{itinerario.direccion}</span>
//             </div>
//         </div>
//     </Popup>
// }

export const itinerario_popup = (itinerario: ItinerarioApi) => {
  return (
    <Popup className={'itinerario-popup'}>
      <div className={'itinerario-columna-container'}>
        <div className={'itinerario-columna1'}>
          <span className={'itinerario-popup-orden'}>{itinerario.orden}</span>
          <span className={''}>{TIPO_ITINERARIO_STR[itinerario.tipo]}</span>
        </div>
        <div className={'itinerario-columna2'}>
          <span>
            {fechahora_fmt(itinerario.fecha_hora_calculada_desde, 'DD/MM/YYYY')}{' '}
            <span style={{ fontWeight: 'bold' }}>{fechahora_fmt(itinerario.fecha_hora_calculada_desde, 'HH:mm')}</span>
          </span>
          {itinerario.usuario && <span>{itinerario.usuario}</span>}
          {!itinerario.usuario && <span>{itinerario.fabricante}</span>}
          <span>{itinerario.direccion}</span>
        </div>
      </div>
    </Popup>
  )
}

export const itinerario_popup_user = (itinerario: ItinerarioApi) => {
  return (
    <Popup className={'itinerario-popup'}>
      <div className={'itinerario-columna-container'}>
        <div className={'itinerario-columna1'}>
          <span className={'itinerario-popup-orden'}>{itinerario.orden}</span>
          <span className={''}>{TIPO_ITINERARIO_STR[itinerario.tipo]}</span>
        </div>
        <div className={'itinerario-columna2'}>
          <span>{fechahora_fmt(itinerario.fecha_hora_calculada_desde, 'DD/MM/YYYY')}</span>
          <span style={{ fontWeight: 'bold' }}>{fechahora_fmt(itinerario.fecha_hora_calculada_desde, 'HH:mm')}</span>
          {(itinerario as any).actor && <span>{(itinerario as any).actor}</span>}
        </div>
      </div>
    </Popup>
  )
}

export const itinerario_popup_old = (itinerario: ItinerarioApi) => {
  return (
    <Popup>
      <span>
        {fechahora_fmt(itinerario.fecha_hora_calculada_desde)} | {itinerario.orden} |{' '}
        {itinerario.tipo === 10 ? 'Entrega' : 'Retiro'}
        <br />
      </span>
      {itinerario.usuario && (
        <span>
          {itinerario.usuario} <br />
        </span>
      )}
      {!itinerario.usuario && (
        <span>
          {itinerario.fabricante} <br />
        </span>
      )}
      <span>{itinerario.direccion}</span>
    </Popup>
  )
}

export const direccion_popup = (direccion: any) => {
  return (
    <Popup className={'itinerario-popup'}>
      <div className={'itinerario-columna-container'}>
        <div className={'itinerario-columna-unique'}>
          <LinkButton href={RUTAS.ADMIN_CLIENTE_DETAIL(direccion.cliente_id)}>
            <span style={{ fontWeight: 'bold' }}>{direccion.cliente_nombre}</span>
          </LinkButton>
          <span>{direccion.calle_principal}</span>
          <span>{direccion.calle_secundaria}</span>
          <span>Numero {direccion.numero}</span>
          <LinkButton href={RUTAS.ADMIN_UPDATE_DIRECCION_CLIENTE(direccion.cliente_id, direccion.id)}>Ver</LinkButton>
        </div>
      </div>
    </Popup>
  )
}

export const punto_retiro_popup = (direccion: any) => {
  const estado = direccion.fabricante_aprobado ? 'moderado' : 'pendiente'
  return (
    <Popup className={'itinerario-popup'}>
      <div className={'itinerario-columna-container'}>
        <div className={'itinerario-columna-unique'}>
          <LinkButton href={RUTAS.ADMIN_FABRICANTE_DETAIL(estado, direccion.fabricante_id)}>
            <span style={{ fontWeight: 'bold' }}>{direccion.fabricante_nombre}</span>
          </LinkButton>
          <span>{direccion.calle_principal}</span>
          <span>{direccion.calle_secundaria}</span>
          <span>Numero {direccion.numero}</span>
          <LinkButton href={RUTAS.ADMIN_PUNTO_RETIRO_UPDATE(direccion.id)}>Ver</LinkButton>
        </div>
      </div>
    </Popup>
  )
}

export const abiatar_popup = (abiatar: any) => {
  const estado = abiatar.aprobado ? 'moderado' : 'pendiente'
  return (
    <Popup className={'itinerario-popup'}>
      <div className={'itinerario-columna-container'}>
        <div className={'itinerario-columna-unique'}>
          <LinkButton href={RUTAS.ADMIN_TRANSPORTISTA_DETAIL(estado, abiatar.id)}>
            <span style={{ fontWeight: 'bold' }}>{abiatar.usuario}</span>
          </LinkButton>
          <span>Rating: {abiatar.rating}</span>
          <span>Ultima actualizacion: {fechahora_fmt(abiatar.last_known_location.fecha)}</span>
          <span style={{ fontWeight: 'bold' }}>Hace: {segundos_to_str(abiatar.last_known_location.ago)}</span>
        </div>
      </div>
    </Popup>
  )
}

export const Loc2LatLng = (locin: BackgroundGeolocationResponse): L.LatLngLiteral => {
  return { lat: locin.latitude, lng: locin.longitude }
}

export const useMapRef = () => {
  const MapaRef = useRef(null as any)

  const mapGetter = (): L.Map | null => {
    if (MapaRef.current !== undefined && MapaRef.current) {
      // @ts-ignore
      return MapaRef.current.leafletElement as L.Map
    }
    return null
  }
  return [mapGetter, protectedRef<L.Map>(MapaRef)]
}
