import {types} from "mobx-state-tree"
import ImagenProducto from "./ImagenProducto";
import {Categoria} from "./Categoria";
import FabricanteVistaCliente from "./FabricanteVistaCliente";

export const Producto = types
  .model("Producto")
  .props({
    id: types.number,
    fabricante: FabricanteVistaCliente,
    nombre: types.string,
    rubros: types.maybeNull(types.string),
    descripcion: types.string,
    descripcion_extensa: types.string,
    precio: types.number,
    precio_mayorista: types.number,
    cantidad_disponible: types.number,
    imagenes: types.array(ImagenProducto),
    categoria: types.maybeNull(Categoria),
    cantidad: types.optional(types.number, 0),
    compra_mejorada: types.optional(types.boolean, false)
  })
  .views(self => ({
    get imagenPortada() {
      if (self.imagenes.length > 0) {
        return self.imagenes[0].imagen
      }
      return 'assets/abi-logo-producto.png'
    }
  })).actions(self => ({
    setImagenes(imagenes: any) {
      // @ts-ignore
      self.imagenes = [...imagenes]
    },
    setCompraMejorada(compraMejorada: boolean) {
      self.compra_mejorada = compraMejorada
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Producto
