import { types } from 'mobx-state-tree'
import ImagenProducto from './ImagenProducto'
import Fabricante from './Fabricante'
import { Categoria } from './Categoria'
import { Familia } from './Familia'
import { Linea } from './Linea'

export const Producto = types
  .model('Producto')
  .props({
    id: types.number,
    fabricante: Fabricante,
    nombre: types.string,
    rubros: types.maybeNull(types.string),
    descripcion: types.string,
    descripcion_extensa: types.string,
    precio: types.number,
    precio_mayorista: types.number,
    // disponible: types.boolean,
    cantidad_disponible: types.number,
    imagenes: types.array(ImagenProducto),
    categoria: types.maybeNull(Categoria),
    familia: types.maybeNull(Familia),
    linea: types.maybeNull(Linea),
    cantidad: types.optional(types.number, 0),
    compra_mejorada: types.optional(types.boolean, false),
    no_apto_menores: types.boolean,
    especificaciones: types.string,
    precio_variante: types.optional(types.number, 0),
  })
  .views((self) => ({
    get imagenPortada() {
      if (self.imagenes.length > 0) {
        return self.imagenes[0].imagen
      }
      return 'assets/abi-logo-producto.png'
    },
  }))
  .actions((self) => ({
    setImagenes(imagenes: any) {
      // @ts-ignore
      self.imagenes = [...imagenes]
    },
    setCompraMejorada(compraMejorada: boolean) {
      self.compra_mejorada = compraMejorada
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Producto
