import * as Sentry from '@sentry/browser'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { withEnvironment, withRootStore } from '..'
import { convertDireccion, Direccion, Fabricante } from '../types'
import DatoFacturacion, { convertDatoFacturacion } from '../types/DatoFacturacion'
import Transportista, { convertTransportista } from '../types/Transportista'

/**
 * Model description here for TypeScript hints.
 */
export const LoginStoreModel = types
  .model('LoginStore')
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({
    id: types.maybeNull(types.number),
    username: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    fecha_nacimiento: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    nro_documento: types.maybeNull(types.string),
    nro_telefono: types.maybeNull(types.string),
    razon_social: types.maybeNull(types.string),
    ruc: types.maybeNull(types.string),
    imagen_perfil: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    date_joined: types.maybeNull(types.string),
    administrador: types.maybeNull(types.boolean),
    transportista: types.maybeNull(Transportista),
    fabricante: types.maybeNull(Fabricante),
    fabricante_id: types.maybeNull(types.number),
    direcciones: types.array(Direccion),
    datos_facturacion: types.array(DatoFacturacion),
    perfil_completo: types.maybeNull(types.boolean),
    // Modo de uso de la app como 10 cliente, 20 fabricante o 30 transportista
    modo_uso_app: types.maybeNull(types.number),
    registro_redes_sociales: types.maybeNull(types.boolean),
    // OneSignal
    onesignal_id: types.maybeNull(types.string),
    onesignal_token: types.maybeNull(types.string),
    // Confirguracion
    notificaciones_activas: types.optional(types.boolean, true),
    sonidos_activos: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.token !== null && self.token !== undefined
    },
    get nombreFormatted() {
      return self.first_name + ' ' + self.last_name
    },
    get notificacionesActivas() {
      return self.notificaciones_activas
    },
    get sonidosActivos() {
      return self.sonidos_activos
    },
    get defaultDatoFacturacion() {
      return self.datos_facturacion.find(df => df.default === true)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setNotificacionesActivas(notificaciones_activas: boolean) {
      self.notificaciones_activas = notificaciones_activas
    },
    setSonidosActivos(sonidos_activos: boolean) {
      self.sonidos_activos = sonidos_activos
    },
    setUser(user: any) {
      self.id = user.id
      self.username = user.username
      self.first_name = user.first_name
      self.last_name = user.last_name
      self.email = user.email
      self.nro_documento = user.nro_documento
      self.nro_telefono = user.nro_telefono
      self.token = user.token
      self.date_joined = user.date_joined
      self.administrador = user.administrador
      self.transportista = convertTransportista(user.transportista)
      self.fabricante = user.fabricante
      self.direcciones = user.direcciones ? user.direcciones.map(convertDireccion) : []
      self.datos_facturacion = user.datos_facturacion ? user.datos_facturacion.map(convertDatoFacturacion) : []
      self.ruc = user.ruc
      self.razon_social = user.razon_social
      self.imagen_perfil = user.imagen_perfil
      self.fabricante_id = user.fabricante_id
      self.modo_uso_app = user.modo_uso_app
      self.perfil_completo = user.perfil_completo
      self.registro_redes_sociales = user.registro_redes_sociales
      self.fecha_nacimiento = user.fecha_nacimiento
      self.notificaciones_activas = user.notificaciones_activas
      if (process.env.NODE_ENV !== 'development') {
        Sentry.setUser({ id: user.id, username: user.username, email: user.email })
      }
    },
    setDirecciones(direcciones: any) {
      self.direcciones = direcciones
    },
    setDatosFacturacion(datos_facturacion: any) {
      self.datos_facturacion = datos_facturacion
    },
    setOneSignal(userId: string | null, pushToken: string | null) {
      self.onesignal_id = userId
      self.onesignal_token = pushToken
    },
    setModoUsoApp(modo_uso_app: number) {
      self.modo_uso_app = modo_uso_app
    },
    setFabricante(fabricante: any) {
      self.fabricante = fabricante
    },
    setApiToken(token: string | null) {
      const api = self.environment.api.apisauce
      self.token = token
      if (token) {
        api?.setHeader('Authorization', 'Token ' + token)
      } else {
        api?.deleteHeader('Authorization')
      }
    },
    reset() {
      self.id = null
      self.username = null
      self.first_name = null
      self.last_name = null
      self.email = null
      self.nro_documento = null
      self.nro_telefono = null
      self.token = null
      self.fabricante_id = null
      self.imagen_perfil = null
      self.fecha_nacimiento = null
      self.direcciones = [] as any
      self.datos_facturacion = [] as any
      const api = self.environment.api.apisauce
      api?.deleteHeader('Authorization')
      if (process.env.NODE_ENV !== 'development') {
        Sentry.configureScope((scope) => scope.setUser(null))
      }
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type LoginStoreType = Instance<typeof LoginStoreModel>
export interface LoginStore extends LoginStoreType {}
type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>
export interface LoginStoreSnapshot extends LoginStoreSnapshotType {}
