import React from 'react';
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router-dom";
import {openPageNow} from "../helpers";

type LinkButtonProps = {
  href: string,
  onclick?: any,
  className?: string
  urlConditionalClassName?: string
};

export const LinkButton: React.FC<LinkButtonProps> = observer((props) => {
  let history = useHistory();
  const url = history.location.pathname
  const className = props.className || ''

  return <a
    href={props.href}
    className={className + ' ' + (url.indexOf(props.href) !== -1 ? props.urlConditionalClassName + '' : '')}
    onClick={(event) => {
      if (props.onclick)
        props.onclick(event)
      else
        openPageNow(props.href, history, event)
    }}>
    {props.children}
  </a>
});

export default LinkButton;
