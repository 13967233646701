import { AndroidPermissions } from '@ionic-native/android-permissions'
import { LocationAccuracy } from '@ionic-native/location-accuracy'
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'

import {
  BackgroundGeolocation,
  BackgroundGeolocationConfig,
  BackgroundGeolocationEvents,
  BackgroundGeolocationLocationProvider,
  BackgroundGeolocationOriginal,
  BackgroundGeolocationResponse,
} from '@ionic-native/background-geolocation'

export class ServiciosUbicacion {
  distanciaMinimaControl: number
  ultimaUbicacion: any
  geoid: any
  backgroundTracking: boolean

  constructor(distanciaMinimaControl = 20) {
    this.distanciaMinimaControl = distanciaMinimaControl
    this.ultimaUbicacion = null
    this.geoid = null
    this.backgroundTracking = false
  }

  // actualizarUbicacion(otorgarPermisosUbicacion: any, intervalo: number)  {
  //     return setInterval(
  //         function () {
  //             otorgarPermisosUbicacion()
  //         }, intervalo
  //     )
  // }

  calcularDistancia(lat1: number, lon1: number, lat2: number, lon2: number) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0
    } else {
      var radlat1 = (Math.PI * lat1) / 180
      var radlat2 = (Math.PI * lat2) / 180
      var theta = lon1 - lon2
      var radtheta = (Math.PI * theta) / 180
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344 * 1000 // en metros
      return dist
    }
  }
  // Check if application having GPS access permission
  async checkGPSPermission(): Promise<boolean> {
    if (Capacitor.getPlatform() !== 'web') {
      const result = await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
      return result.hasPermission
    } else {
      // El navegador pide automáticamente
      return true
    }
  }

  async askToTurnOnGPS(): Promise<boolean> {
    return (async (): Promise<boolean> => {
      const canRequest = await LocationAccuracy.canRequest()
      if (canRequest) {
        // the accuracy option will be ignored by iOS
        const gotit = await LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
        return gotit.code === 0
      } else {
        return false
      }
    })()
  }

  async askPermissionAndOrTurnOnGPS(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'ios') {
      return true
    }
    if (Capacitor.getPlatform() === 'android') {
      const result = await AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
      // console.log('hasPermission', result.hasPermission)
      if (!result.hasPermission) {
        return false
      }
    }
    if (Capacitor.getPlatform() !== 'web') {
      const isGpsOn = await this.askToTurnOnGPS()
      // console.log('gpsTurnedON', isGpsOn)
      return isGpsOn
    }
    return true
  }

  getUbiLat(ubi: BackgroundGeolocationResponse | GeolocationPosition) {
    return 'coords' in ubi ? ubi.coords.latitude : ubi.latitude
  }

  getUbiLon(ubi: BackgroundGeolocationResponse | GeolocationPosition) {
    return 'coords' in ubi ? ubi.coords.longitude : ubi.longitude
  }

  ubicacionCambioXMetros(ubicacionNueva: any, metros = this.distanciaMinimaControl) {
    if (this.ultimaUbicacion == null) {
      this.ultimaUbicacion = ubicacionNueva
      return true
    } else {
      let distanciaMetros = this.calcularDistancia(
        this.getUbiLat(this.ultimaUbicacion),
        this.getUbiLon(this.ultimaUbicacion),
        this.getUbiLat(ubicacionNueva),
        this.getUbiLon(ubicacionNueva),
      )
      return distanciaMetros > metros
    }
  }

  state_setter: any = null
  setStateSetter(cb: any) {
    this.state_setter = cb
  }

  async configurarBackgroundGeolocation(): Promise<BackgroundGeolocationOriginal> {
    const config: BackgroundGeolocationConfig = {
      locationProvider: BackgroundGeolocationLocationProvider.DISTANCE_FILTER_PROVIDER,
      distanceFilter: 50,
      notificationTitle: 'ABi',
      notificationText: 'ABi esta accediendo a tu ubicación..',
      interval: 10000,
      fastestInterval: 5000,
      activitiesInterval: 10000,
      notificationsEnabled: true,
      startOnBoot: false,
      desiredAccuracy: 10, // medium
      stationaryRadius: 20,
      debug: false, //rootStore.environment.api.config.isDebug, //  enable this hear sounds for background-geolocation life-cycle.
      stopOnTerminate: false, // enable this to clear background location settings when the app terminates
      startForeground: true,
    }
    await BackgroundGeolocation.configure(config)

    return BackgroundGeolocation
  }

  async setupRastreoUsuario(
    callback: (ubicacion: BackgroundGeolocationResponse | null, changed: boolean, bgloc: any) => void,
  ): Promise<any> {
    if (this.backgroundTracking) {
      console.log('backgroundTracking ya está activo, saltando')
      return
    }
    if (this.geoid) {
      await Geolocation.clearWatch({ id: this.geoid })
      this.geoid = null
    } else {
      this.backgroundTracking = false
      BackgroundGeolocation.stop()
    }
    await this.askPermissionAndOrTurnOnGPS()
    await this.configurarBackgroundGeolocation().then(
      () => {
        BackgroundGeolocation.on(BackgroundGeolocationEvents.location).subscribe(
          async (location: BackgroundGeolocationResponse) => {
            if (this.state_setter) this.state_setter(true)
            if (Capacitor.getPlatform() === 'ios') {
              const idtask = await BackgroundGeolocation.startTask()
              callback(location, this.ubicacionCambioXMetros(location), BackgroundGeolocation)
              BackgroundGeolocation.endTask(idtask)
            } else {
              callback(location, this.ubicacionCambioXMetros(location), BackgroundGeolocation)
            }
          },
        )

        BackgroundGeolocation.on(BackgroundGeolocationEvents.authorization).subscribe(async (event) => {
          if (this.state_setter) this.state_setter(false)
          if (Capacitor.getPlatform() === 'ios') {
            const idtask = await BackgroundGeolocation.startTask()
            callback(null, false, BackgroundGeolocation)
            BackgroundGeolocation.endTask(idtask)
          } else {
            callback(null, false, BackgroundGeolocation)
          }
        })

        BackgroundGeolocation.on(BackgroundGeolocationEvents.error).subscribe((error) => {
          if (this.state_setter) this.state_setter(false)

          console.log('[ERROR] ' + error)
        })
        BackgroundGeolocation.on(BackgroundGeolocationEvents.stop).subscribe(() => {
          if (this.state_setter) this.state_setter(false)

          console.log('[INFO] BackgroundGeolocation service has been stopped')
        })

        this.backgroundTracking = true
        this.geoid = null
        BackgroundGeolocation.start()
        console.log('setupRastreoUsuario backgroundTracking iniciado!')
      },
      (error) => {
        this.backgroundTracking = false
        this.geoid = Geolocation.watchPosition({ enableHighAccuracy: true }, (position, error) => {
          if (position && position.coords) {
            const ubi = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            } as BackgroundGeolocationResponse
            callback(ubi, this.ubicacionCambioXMetros(position), Geolocation)
            if (this.state_setter) this.state_setter(true)
          }
        })
        return Geolocation
      },
    )
    return BackgroundGeolocation
  }

  async stopUserTracking() {
    if (this.geoid) {
      await Geolocation.clearWatch({ id: this.geoid })
      this.geoid = null
    } else {
      this.backgroundTracking = false
      BackgroundGeolocation.stop()
    }
  }
}

export default ServiciosUbicacion
