import { types } from 'mobx-state-tree'
import { Ciudad } from './Ciudad'
import Pedido from './Pedido'
import Direccion from './Direccion'

export const TIPOS = { ENTREGA: 10, RETIRO: 20 }

export const ESTADO = {
  PENDIENTE: 0,
  EN_PROCESO: 10,
  FINALIZADO: 20,
}

export const ItinerarioModel = types
  .model('ItinerarioModel')
  .props({
    id: types.number,
    tipo: types.number,
    estado: types.number,
    usuario: types.maybeNull(types.string),
    fabricante: types.maybeNull(types.string),
    fabricante_id : types.maybeNull(types.number),
    fabricante_imagen_perfil: types.maybeNull(types.string),
    orden: types.number,
    fecha_hora_calculada_desde: types.string,
    direccion: types.string,
    ubicacion: types.string,
    ciudad: Ciudad,
    distancia: types.number,
    tiempo_en_transito: types.number,
    tiempo_parada_calculado: types.number,
    referencia_orden: types.string,
    pedidos: types.array(Pedido),
    direccion_itinerario: types.maybeNull(Direccion),
    telefono_itinerario: types.maybeNull(types.string),
  })
  .views((self) => ({}))
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

export default ItinerarioModel
