import {types} from "mobx-state-tree"
import Producto from "./Producto";

export const ProductoPedido = types
  .model("ProductoPedido")
  .props({
    id: types.number,
    producto: Producto,
    compra_mejorada: types.boolean,
    cantidad: types.number,
    precio: types.number,
    total: types.number
  })
  .views(self => ({

  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default ProductoPedido
