import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree'
import Tarjeta from '../types/Tarjeta'

/**
 * Model description here for TypeScript hints.
 */
export const TarjetaStoreModel = types
  .model('TarjetaStore')
  .props({
    tarjetas: types.array(Tarjeta),
  })
  .views((self) => ({
    getTarjeta(card_id: string) {
      // @ts-ignore
      return self.tarjetas.find((b) => b.card_id === card_id)
    },
    get tarjetaDefecto() {
      return self.tarjetas.find((c) => c.default === true)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setTarjetas(tarjetas: any) {
      // @ts-ignore
      self.tarjetas = []
      if (!tarjetas) {
        return
      }
      // @ts-ignore
      self.tarjetas = tarjetas.sort((a, b) => a.default === true)
    },
    setTarjetaDefecto(card_id: number) {
      const tarjetaDefecto = self.tarjetaDefecto
      if (tarjetaDefecto) {
        tarjetaDefecto.default = false
      }
      self.tarjetas.forEach((tarjeta) => {
        if (tarjeta.card_id !== card_id) {
          tarjeta.default = false
        } else {
          tarjeta.default = true
        }
      })
    },
    removeTarjeta(tarjeta: any) {
      self.tarjetas.remove(tarjeta)
    },
    appendTarjeta(tarjeta: any) {
      if (self.tarjetas.length < 1) {
        tarjeta.default = true
      }
      self.tarjetas.push(tarjeta)
    },
    ordenar(tarjeta: any) {
      // Ordena en base a la tarjeta proveída
      // @ts-ignore
      self.tarjetas.sort((a, b) => a.id === tarjeta.id)
    },
    reset() {
      self.tarjetas = cast([])
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type TarjetaStoreType = Instance<typeof TarjetaStoreModel>
export interface TarjetaStore extends TarjetaStoreType {}
type TarjetaStoreSnapshotType = SnapshotOut<typeof TarjetaStoreModel>
export interface TarjetaStoreSnapshot extends TarjetaStoreSnapshotType {}
