import { Instance, types } from 'mobx-state-tree'
import Direccion from './Direccion'
import Cliente from './Cliente'
import ProductoPedido from './ProductoPedido'
import Tarjeta from './Tarjeta'
import { ORDEN_VENTA_ESTADOS_STR, PEDIDO_ESTADOS_STR } from '../../constants'
import { Fabricante } from '.'
import Transportista from './Transportista'

export const getEstadoOrdenVentaDisplay = (estadop: number) => {
  // @ts-ignore
  return ORDEN_VENTA_ESTADOS_STR[estadop]
}
export const getEstadoPedidoDisplay = (estadop: number) => {
  // @ts-ignore
  return PEDIDO_ESTADOS_STR[estadop]
}

export const Pedido = types
  .model('Pedido')
  .props({
    id: types.maybeNull(types.number),
    usuario: types.maybeNull(Cliente),
    direccion: types.maybeNull(Direccion),
    fecha: types.maybeNull(types.string),
    fecha_entrega: types.maybeNull(types.string),
    medio_pago: types.maybeNull(types.number),
    referencia: types.maybeNull(types.string),
    total: types.maybeNull(types.number),
    tarjeta: types.maybeNull(Tarjeta),
    productos: types.array(ProductoPedido),
    transportista: types.maybeNull(Transportista),
    estado: types.maybeNull(types.number),
  })
  .views((self) => ({
    get productosListado() {
      if (self.productos.length > 0) {
        return self.productos.slice(0, 2)
      }
      return []
    },
    cantidadArticulos() {
      return self.productos.reduce(
        (prev: number, current: Instance<typeof ProductoPedido>) => prev + current.cantidad,
        0,
      )
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Pedido
