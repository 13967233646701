import React from 'react'
import { observer } from 'mobx-react-lite'
import { IonModal } from '@ionic/react'
import { Modal } from 'react-bootstrap'
import './ConfirmModal.css'

type ConfirmModalProps = {
  titulo: string
  texto?: string | undefined
  onAceptar: any
  onCancelar?: any
  textoAceptar?: string
  textoCancelar?: string
  mostrarCancelar?: boolean
  visible: boolean
  color?: string
  class_boton_aceptar?: string
  class_boton_cancelar?: string
}

export const ConfirmModal: React.FC<ConfirmModalProps> = observer((props) => {
  const color = props.color || '#303AB2'
  const class_boton_aceptar = props.class_boton_aceptar || 'btn-outline-primary'
  const class_boton_cancelar = props.class_boton_cancelar || 'btn-outline-secondary'

  return (
    <IonModal isOpen={props.visible} swipeToClose={false} backdropDismiss={false} cssClass={'modal-transparent-full'}>
      <Modal.Dialog centered>
        <Modal.Header>
          <Modal.Title style={{ color: color }}>{props.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.texto || props.children || 'Confirmar accion'}</p>
        </Modal.Body>
        <Modal.Footer>
          {(props.mostrarCancelar === undefined || props.mostrarCancelar) && (
            <button
              className={'btn ' + class_boton_cancelar}
              onClick={() => {
                if (props.onCancelar) props.onCancelar()
              }}
            >
              {props.textoAceptar || 'Cancelar'}
            </button>
          )}
          <button
            className={'btn ' + class_boton_aceptar}
            onClick={() => {
              props.onAceptar()
            }}
          >
            {props.textoAceptar || 'Aceptar'}
          </button>
        </Modal.Footer>
      </Modal.Dialog>
    </IonModal>
  )
})

export default ConfirmModal
