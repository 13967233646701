import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree'
import ProductoCarrito from '../types/ProductoCarrito'

export const MEDIOS_PAGO = {
  tarjeta_registrada: 0,
  tarjeta_no_registrada: 1,
  zimple: 2,
  tigo_money: 3,
  billetera_personal: 4,
  test: -1,
}

/**
 * Model description here for TypeScript hints.
 */
// @ts-ignore
export const CarritoStoreModel = types
  .model('CarritoStore')
  .props({
    productos: types.array(ProductoCarrito),
    compra_mejorada: types.optional(types.boolean, false),
    envio_sin_hora: types.optional(types.boolean, true),
    // Pago
    tarjeta: types.maybeNull(types.number),
    medioPago: types.optional(types.number, MEDIOS_PAGO.tarjeta_no_registrada),
    entregaDesde: types.maybeNull(types.string),
    entregaHasta: types.maybeNull(types.string),
    metodoPago: types.optional(types.string, 'tarjeta'),
  })
  .views((self) => ({
    get cantidadProductosCarrito() {
      return self.productos.reduce((suma, prod) => suma + prod.cantidad, 0)
    },
    get total() {
      return self.productos.reduce(
        (suma, prod) => suma + (self.compra_mejorada ? prod.precio_mayorista : prod.variante ? prod.variante.precio : prod.precio) * prod.cantidad,
        0,
      )
    },
    get totalPrecioMejorado() {
      return self.productos.reduce((suma, prod) => suma + prod.precio_mayorista * prod.cantidad, 0)
    },
    get diferenciaPrecio() {
      return self.productos.reduce((suma, prod) => suma + (prod.precio - prod.precio_mayorista) * prod.cantidad, 0)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setTarjeta(tarjeta: number) {
      self.medioPago = MEDIOS_PAGO.tarjeta_registrada
      self.tarjeta = tarjeta
    },
    setMedioPago(medioPago: number) {
      if (medioPago !== MEDIOS_PAGO.tarjeta_registrada) {
        self.tarjeta = null
      }
      self.medioPago = medioPago
    },
    setProductos(productos: any) {
      self.productos = productos || []
    },
    addProducto(producto: any) {
      self.productos.push(producto)
    },
    removeProducto(producto: any) {
      self.productos.remove(producto)
    },
    sumarProducto(producto: any, variante?: any) {
      // @ts-ignore
      self.productos = cast(
        self.productos.map((prod) => {
          if (prod.id == producto.id) {
            if(prod.variante && variante){
              if(prod.variante.id == variante){
                prod.cantidad += 1
              }
            } else {
              prod.cantidad += 1
            }
          }
          return prod
        }),
      )
    },
    restarProducto(producto: any, variante?: any) {
      self.productos = cast(
        self.productos.map((prod) => {
          if (prod.id == producto.id) {
            if (prod.variante && variante){
              if(prod.variante.id == variante){
                prod.cantidad -= 1
              }
            } else {
              prod.cantidad -= 1
            }
          }
          return prod
        }),
      )
    },
    setCompraMejorada(mejorada: boolean) {
      self.compra_mejorada = mejorada
    },
    setProductosAPI(data: any) {
      if (!!data && data.length > 0) {
        self.productos = data.map((prod: any) => {
          return {
            id: prod.producto.id,
            fabricante: prod.producto.fabricante,
            nombre: prod.producto.nombre,
            descripcion: prod.producto.descripcion,
            precio: prod.producto.precio,
            precio_mayorista: prod.producto.precio_mayorista,
            cantidad_disponible: prod.producto.cantidad_disponible,
            imagenes: prod.producto.imagenes,
            categoria: prod.producto.categoria,
            cantidad: prod.cantidad,
            variante: prod.variante,
          }
        })
      }
      // TODO: Desactivado temporalmente, habilitar con nuevas funcionalidades (al cambiar tipo de compra no setea)
      //self.compra_mejorada = data.compra_mejorada
    },
    setEntregaDesde(desde: string) {
      self.entregaDesde = desde
      self.envio_sin_hora = false
    },
    setEntregaHasta(hasta: string) {
      self.entregaHasta = hasta
      self.envio_sin_hora = false
    },
    setEnvioSinHora() {
      self.envio_sin_hora = true
      self.entregaDesde = null
      self.entregaHasta = null
    },
    setEnvioConHora(desde: string, hasta: string) {
      self.envio_sin_hora = false
      self.entregaDesde = desde
      self.entregaHasta = hasta
    },
    setMetodoPago(metodo: string) {
      self.metodoPago = metodo
    },
    reset() {
      self.productos = cast([])
      self.entregaDesde = null
      self.entregaHasta = null
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export const convertProductoCarrito = (prod: any) => {
  return {
    id: prod.id,
    fabricante: prod.fabricante,
    nombre: prod.nombre,
    descripcion: prod.descripcion,
    precio: prod.precio,
    precio_mayorista: prod.precio_mayorista,
    cantidad_disponible: prod.cantidad_disponible,
    imagenes: prod.imagenes,
    categoria: prod.categoria,
    cantidad: prod.cantidad,
    variante: prod.variante,
  }
}

type CarritoStoreType = Instance<typeof CarritoStoreModel>

export interface CarritoStore extends CarritoStoreType {}

type CarritoStoreSnapshotType = SnapshotOut<typeof CarritoStoreModel>

export interface CarritoStoreSnapshot extends CarritoStoreSnapshotType {}
