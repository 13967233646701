import './LoadingApp.css';
import { IonSpinner } from '@ionic/react';

const LoadingApp = () => {
  return (
    <div className="loading">
        <IonSpinner className="spinner-loading-app" name="crescent" />
    </div>
  );
};

export default LoadingApp;

