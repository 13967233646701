import { types } from 'mobx-state-tree'
import { withRootStore } from '../extensions/with-root-store'

export const DatoFacturacion = types
  .model('DatoFacturacion')
  .extend(withRootStore)
  .props({
    id: types.number,
    razon_social: types.string,
    ruc: types.string,
    default: types.boolean,
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars


  export default DatoFacturacion

  export const convertDatoFacturacion = (datof: any) => {
    if (!datof) {
      return null
    }
    return {
      id: datof.id,
      razon_social: datof.razon_social,
      ruc: datof.ruc,
      default: datof.default,
    } as any
  }