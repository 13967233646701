import { types } from 'mobx-state-tree'
import Direccion, { convertDireccion } from './Direccion'
import { nroTelefonoLocal } from '../../helpers'
import { DatoFacturacion as DatoFacturacionApi } from '../../services/api'
import { DatoFacturacion } from './DatoFacturacion'

export const Cliente = types
  .model('Cliente')
  .props({
    id: types.maybeNull(types.number),
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    nombre: types.maybeNull(types.string),
    apellido: types.maybeNull(types.string),
    fecha_nacimiento: types.maybeNull(types.string),
    nro_telefono: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    nro_documento: types.maybeNull(types.string),
    ruc: types.maybeNull(types.string),
    razon_social: types.maybeNull(types.string),
    imagen_perfil: types.maybeNull(types.string),
    datos_facturacion: types.maybeNull(types.array(DatoFacturacion)),
    direcciones: types.array(Direccion),
    genero: types.maybeNull(types.number),
  })
  .views((self) => ({
    get nroTelefonoLocal() {
      // Retornar el numero de telefono como 09 en vez de 5959
      return nroTelefonoLocal(self.nro_telefono)
    },
    get defaultDatoFacturacion() {
      const dato = self.datos_facturacion?.find((d) => d.default)
      return dato ? { ...dato } : { id: 0, ruc: '', razon_social: '', default: false }
    },
  }))
  .actions((self) => ({
    setDirecciones(direcciones: any) {
      // @ts-ignore
      self.direcciones = [...direcciones]
    },
    setFistName(firstName: string) {
      self.first_name = firstName
    },
    setLastName(lastName: string) {
      self.last_name = lastName
    },
    setEmail(email: string) {
      self.email = email
    },
    setNroTelefono(telefono: string) {
      self.nro_telefono = telefono
    },
    setDatosFacturacion(datos: DatoFacturacionApi[]) {
      // @ts-ignore
      self.datos_facturacion = [...datos]
    },
    eliminarDatoFacturacion(id: number) {
      const datos = self.datos_facturacion ? [...self.datos_facturacion] : []
      const filter = datos.filter((e) => e.id !== id)
      // @ts-ignore
      self.datos_facturacion = filter
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Cliente

export const convertCliente = (cli: any) => {
  if (!cli) {
    return null
  }
  return {
    id: cli.id,
    first_name: cli.first_name,
    last_name: cli.last_name,
    fecha_nacimiento: cli.fecha_nacimiento,
    nro_telefono: cli.nro_telefono,
    email: cli.email,
    nro_documento: cli.nro_documento,
    ruc: cli.ruc,
    razon_social: cli.razon_social,
    imagen_perfil: cli.imagen_perfil,
    direcciones: cli.direcciones ? cli.direcciones.map(convertDireccion) : [],
    genero: cli.genero,
  } as any
}
