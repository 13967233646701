import {types} from "mobx-state-tree"

export const Refrigeracion = types
  .model("Refrigeracion")
  .props({
    id: types.number,
    nombre: types.string,
    refrigeracion: types.maybeNull(types.number),
  })
  .views(self => ({
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export default Refrigeracion