import { ApiResponse } from 'apisauce'
import { getGeneralApiProblem } from './api-problem'
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config'
import * as Types from './api.types'
import { ClientesResult, HojaRutaApi, ItinerarioApi, SimplePostResult, SingleResult, Vehiculo } from './api.types'
import { ApiBase } from './api-base'
import { LatLngTuple } from 'leaflet'
import { OrdenVenta } from '.'
import { T } from 'ramda'
import { BASE_URL } from '../../config/env'

/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config)
  }

  async setDevice(userId: string, pushToken: string, activo: boolean): Promise<Types.SetDeviceResult> {
    return this.noresult_post(`/set-device/`, {
      user_id: userId,
      push_token: pushToken,
      activo: activo,
    })
  }

  async logout(device_id: string): Promise<Types.LogoutResult> {
    return this.noresult_post(`/logout/`, { device_id })
  }

  async comprobarCuenta(username: string, password: string): Promise<Types.SimplePostResult> {
    return await this.simple_post(`/autenticar/`, {
      username: username,
      password: password,
    })
  }

  async login(username: string, password: string): Promise<Types.LoginResult> {
    let response = await this.simple_post(`/login/`, {
      username: username,
      password: password,
    })

    if (response.kind === 'ok') {
      try {
        const resultUser: Types.User = {
          id: response.response.id,
          username: response.response.username,
          nombre: response.response.nombre,
          apellido: response.response.apellido,
          first_name: response.response.first_name,
          last_name: response.response.last_name,
          nro_documento: response.response.nro_documento,
          email: response.response.email,
          token: response.response.token,
          nro_telefono: response.response.nro_telefono,
          date_joined: response.response.date_joined,
          administrador: response.response.administrador,
          transportista: response.response.transportista,
          fabricante: response.response.fabricante,
          cliente: response.response.cliente,
          razon_social: response.response.razon_social,
          ruc: response.response.ruc,
          imagen_perfil: response.response.imagen_perfil,
          fabricante_id: response.response.fabricante_id,
          modo_uso_app: response.response.modo_uso_app,
          perfil_completo: response.response.perfil_completo,
          registro_redes_sociales: response.response.registro_redes_sociales,
          fecha_nacimiento: response.response.fecha_nacimiento,
          notificaciones_activas: response.response.notificaciones_activas,
          direcciones: response.response.direcciones,
        }
        return { kind: 'ok', user: resultUser }
      } catch {
        return { kind: 'bad-data' }
      }
    }
    return response
  }

  async guardarTipoVehiculo(datos: any): Promise<Types.GuardarTipoVehiculoResult> {
    return this.simple_id_save_or_create(`/tipo-vehiculo`, datos)
  }

  async tiposVehiculo(page: number, perPage: number, order: string): Promise<Types.TiposVehiculoResult> {
    return this.paginated_list_view(`/tipo-vehiculo/`, page, perPage, order)
  }

  async fabricantes(
    page: number,
    perPage: number,
    order: string,
    search: string,
    moderado: boolean,
    extras?: {},
  ): Promise<Types.FabricantesResult> {
    let params = Object.assign({ moderado: moderado }, extras)
    return this.paginated_list_view(`/fabricante/`, page, perPage, order, search, params)
  }

  async eliminarTipoVehiculo(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/tipo-vehiculo/` + id + '/')
  }

  async eliminarFabricante(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/fabricante/${id}/`)
  }

  async eliminarPuntoRetiro(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/punto-retiro/` + id + '/')
  }

  async eliminarDireccion(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/direccion/${id}/`)
  }

  async eliminarVehiculo(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/vehiculo/${id}/`)
  }

  async getCliente(id: any): Promise<Types.ClienteResult> {
    return (await this.single_casted_get_view(`/cliente/${id}/`, {}, 'cliente')) as Types.ClienteResult
  }

  async guardarCliente(datos: any): Promise<Types.GuardarClienteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] !== typeof null)) {
        continue
      }
      if (key === 'imagen_perfil') {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] || '')
      }
    }
    let url = `/cliente/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }

    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else {
      // @ts-ignore
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      // @ts-ignore
      return { kind: 'ok', user: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async guardarFabricante(datos: any): Promise<Types.GuardarFabricanteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'imagen_perfil') {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }

    let url = `/fabricante/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', fabricante: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async eliminarCliente(id: any): Promise<Types.GenericResponse> {
    return this.simple_delete(`/cliente/${id}/`)
  }

  async clientes(page?: number, perPage?: number, order?: any, search?: string): Promise<Types.ClientesResult> {
    return this.paginated_list_view(`/cliente/`, page, perPage, order, search)
  }

  async getCiudades(): Promise<Types.GetCiudadesResult> {
    return (await this.single_casted_get_view(`/ciudad/`, {}, 'ciudades')) as Types.GetCiudadesResult
  }

  async getClienesTransportista(): Promise<Types.ClientesResult> {
    return this.single_list_view(`/clientes-transportista/`)
  }

  async guardarPuntoRetiro(datos: any): Promise<Types.GuardarPuntoRetiroResult> {
    const response = await this.simple_id_save_or_create(`/punto-retiro`, datos)
    return response.kind === 'ok' ? { kind: 'ok', punto_retiro: response.response } : response
  }

  async getDireccion(id: any): Promise<Types.SingleResult> {
    return this.single_get_view(`/direccion/${id}/`)
  }

  async guardarDireccion(datos: any): Promise<Types.GuardarDireccionResult> {
    const response = await this.simple_id_save_or_create(`/direccion`, datos)
    return response.kind === 'ok' ? { kind: 'ok', direccion: response.response } : response
  }

  async guardarTransportista(datos: any): Promise<Types.GuardarTransportistaResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }
    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'registro_frente' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'registro_dorso' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'constancia_ruc' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'antecedentesj' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'antecedentesp' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'ciudades') {
        fdata.append(key, datos[key].join(','))
        continue
      }
      if (
        key === 'imagen_perfil' ||
        key === 'registro_frente' ||
        key === 'registro_dorso' ||
        key === 'antecedentesj' ||
        key === 'antecedentesp' ||
        key === 'constancia_ruc'
      ) {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }

    let url = `/transportista/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }

    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', transportista: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async transportistas(
    page: number,
    perPage: number,
    order: any,
    search: string,
    moderado: boolean,
  ): Promise<Types.TransportistasResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.get(`/transportista/`, {
      page: page,
      page_size: perPage,
      ordering: order,
      search,
      moderado: moderado,
    })

    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      const rawTransportistas = response.data.results
      const resultTransportistas = rawTransportistas.map((tra: any) => {
        return {
          id: tra.id,
          nombre: tra.nombre,
          apellido: tra.apellido,
          razon_social: tra.usuario.razon_social,
          ruc: tra.usuario.ruc,
          nro_documento: tra.usuario.nro_documento,
          nro_telefono: tra.usuario.nro_telefono,
          imagen_perfil: BASE_URL + tra.imagen_perfil,
          email: tra.usuario.email,
          ciudades: tra.ciudades,
          registro_frente: tra.registro_frente,
          registro_dorso: tra.registro_dorso,
          registro_vencimiento: tra.registro_vencimiento,
          antecedentesj: tra.antecedentesj,
          antecedentesp: tra.antecedentesp,
          aprobado: tra.aprobado,
          bloqueado: tra.bloqueado,
          vehiculos: tra.vehiculos,
        }
      })
      return { kind: 'ok', data: { results: resultTransportistas, count: response.data.count } }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async adminGetTransportista(id: number): Promise<Types.TransportistaResult> {
    return (await this.single_casted_get_view(
      `/transportista/${id}/`,
      {},
      'transportista',
    )) as Types.TransportistaResult
  }

  async eliminarTransportista(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/transportista/${id}/`)
  }

  async getUsuariosFabricante(id: any): Promise<ClientesResult> {
    return this.single_list_view(`/usuarios-fabricante/`, { id: id })
  }

  async rubros(): Promise<Types.RubrosResult> {
    return (await this.single_casted_get_view(`/rubro/`, {}, 'rubros')) as Types.RubrosResult
  }

  async guardarRubro(datos: any) {
    return this.simple_id_save_or_create('rubro', datos)
  }

  async eliminarRubro(id: number) {
    return this.simple_delete(`rubro/${id}/`)
  }

  async preguntasFrecuentes(): Promise<Types.PreguntasFrecuentesResult> {
    return (await this.single_casted_get_view(
      `/preguntas-frecuentes/`,
      {},
      'preguntas',
    )) as Types.PreguntasFrecuentesResult
  }

  async guardarPreguntaFrecuente(datos: any) {
    return this.simple_id_save_or_create('preguntas-frecuentes', datos)
  }

  async eliminarPreguntaFrecuente(id: number) {
    return this.simple_delete(`preguntas-frecuentes/${id}/`)
  }

  async tiposVehiculos(): Promise<Types.TiposVehiculoResult> {
    return this.single_list_view(`/tipo-vehiculo/`)
  }

  async refrigeracion(): Promise<Types.RefrigeracionResult> {
    return this.single_list_view('/refrigeracion/')
  }

  async guardarVehiculo(datos: any, transportistaCrear: boolean): Promise<Types.GuardarVehiculoResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'habilitacion_frente' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'habilitacion_dorso' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'poliza_seguro' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'habilitacion_frente' || key === 'habilitacion_dorso' || key === 'poliza_seguro') {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }

    let url = `/vehiculo/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', vehiculo: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async usuariosStaff(
    page: number,
    perPage: number,
    order: any,
    search: string,
    fabricante_id: any,
  ): Promise<Types.ClientesResult> {
    return this.paginated_list_view(`/usuario_staff/`, page, perPage, order, search, { fabricante_id: fabricante_id })
  }

  async ruteoManualStaff(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/ruteo-ordenes-venta/`, data)
  }

  async guardarUsuarioStaff(datos: any): Promise<Types.GuardarClienteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      fdata.append(key, datos[key] || '')
    }

    let url = `/usuario_staff/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else {
      // @ts-ignore
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return { kind: 'ok' }
  }

  async eliminarUsuarioStaff(id: any, fabricante_id: any): Promise<Types.GuardarTipoVehiculoResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.delete(`/usuario_staff/${id}/`, {
      fabricante_id: fabricante_id,
    })

    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    return { kind: 'ok' }
  }

  async configuraciones(): Promise<Types.ConfiguracionesResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.get(`/configuracion/`)
    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      return { kind: 'ok', configuraciones: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async guardarConfiguracion(datos: any): Promise<Types.ConfiguracionesResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let url = `/configuracion-editar/`
    let response = await this.apisauce.post(url, datos)

    if (!response.ok) {
      if (response.status === 400) {
        // @ts-ignore
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', configuraciones: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async fabricanteRegistro(datos: any): Promise<Types.GuardarClienteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'imagen_perfil') {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }

    let url = `/registro-fabricante/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', user: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async registerB2B(datos: any): Promise<Types.GuardarClienteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }

      fdata.append(key, datos[key] === null ? '' : datos[key])
    }

    let url = `/request-contact-b2b/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', user: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async transportistaRegistro(datos: any): Promise<Types.GuardarTransportistaResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'registro_frente' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'registro_dorso' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'antecedentesj' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'antecedentesp' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'habilitacion_frente' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'habilitacion_dorso' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'poliza_seguro' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'constancia_ruc' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'ciudades') {
        fdata.append(key, datos[key].join(','))
        continue
      }
      if (
        key === 'imagen_perfil' ||
        key === 'registro_frente' ||
        key === 'registro_dorso' ||
        key === 'antecedentesj' ||
        key === 'antecedentesp' ||
        key === 'habilitacion_frente' ||
        key === 'habilitacion_dorso' ||
        key === 'poliza_seguro' ||
        key === 'constancia_ruc'
      ) {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }

    let url = `/registro-transportista/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      // console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', user: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async guardarProducto(datos: any): Promise<Types.GuardarProductoResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'video' && (!datos[key] || typeof datos[key] === 'string')) {
        continue
      }
      if (key === 'video') {
        fdata.append(key, datos[key], 'video.mp4')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }

    let url = `/producto/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }
    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.patch(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      // console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else if (response.status !== 200 && response.status !== 201) {
      return { kind: 'server' }
    }

    try {
      // @ts-ignore
      return { kind: 'ok', response: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async productos(
    page: number,
    perPage: number,
    order: any,
    search: string,
    moderado: boolean,
    extra = {},
  ): Promise<Types.ProductosResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.get(`/producto/`, {
      page: page,
      page_size: perPage,
      ordering: order,
      search,
      moderado: moderado,
      ...extra,
    })

    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      return { kind: 'ok', data: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async comentariosProductos(
    page: number,
    perPage: number,
    order: any,
    search: string,
  ): Promise<Types.ProductosResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.get(`/comentario-producto/`, {
      page: page,
      page_size: perPage,
      ordering: order,
      search,
    })

    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      return { kind: 'ok', data: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async eliminarProducto(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/producto/${id}/`)
  }

  async eliminarComentarioProducto(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/comentario-producto/${id}/`)
  }

  async eliminarImagen(id: number): Promise<Types.EliminarTipoVehiculoResult> {
    return this.simple_delete(`/imagen_producto/${id}/`)
  }

  async clienteRegistro(datos: any): Promise<Types.GuardarClienteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    let fdata = new FormData()
    for (const key in datos) {
      if (key === 'imagen_perfil' && (!datos[key] || typeof datos[key] !== typeof null)) {
        continue
      }
      if (key === 'imagen_perfil') {
        fdata.append(key, datos[key], 'image.png')
      } else {
        fdata.append(key, datos[key] === null ? '' : datos[key])
      }
    }
    let url = `/registro-cliente/`
    let response
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.apisauce.headers.Authorization,
    }

    try {
      if (datos.id) {
        url += datos.id + '/'
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      } else {
        response = await this.apisauce.axiosInstance.post(url, fdata, { headers })
      }
    } catch (e: any) {
      if (e.message.indexOf('status code 400') !== -1) {
        return { kind: 'bad-data', errors: e.response.data }
      }
      console.log(e)
      response = { status: 500 }
    }

    if (response.status === 400) {
      // @ts-ignore
      return { kind: 'bad-data', errors: response.data }
    } else {
      // @ts-ignore
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      // @ts-ignore
      return { kind: 'ok', user: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async productosInicio(tipo: number): Promise<Types.ProductosResult> {
    return this.simple_get(`/productos-inicio/`, { tipo: tipo })
  }

  async productosBuscador(
    page: number,
    perPage: number,
    order: any,
    search: string,
    rango_precio: any,
    empresa: string,
    rubro: string,
    categoria: string,
    calificacion: string,
    masVendidos: boolean,
  ): Promise<Types.ProductosBusquedaResult> {
    return this.simple_get(`/producto_buscador2/`, {
      page: page,
      page_size: perPage,
      ordering: order,
      search,
      rango_precio: rango_precio,
      empresa,
      rubro,
      categoria,
      calificacion,
      mas_vendidos: masVendidos,
    })
  }

  async productosBuscadorSimple(
    page: number,
    perPage: number,
    order: any,
    search: string,
    rango_precio: any,
    empresa: string,
    rubro: string,
    categoria: string,
    calificacion: string,
    masVendidos: boolean,
  ): Promise<Types.ProductosBusquedaResult> {
    return this.simple_get(`/producto_buscador/`, {
      page: page,
      page_size: perPage,
      ordering: order,
      search,
      rango_precio: rango_precio,
      empresa,
      rubro,
      categoria,
      calificacion,
      mas_vendidos: masVendidos,
    })
  }

  async productoDetalle(id: number): Promise<Types.ProductoDetalleResult> {
    return this.simple_get(`/producto_buscador/${id}/`)
  }

  async seleccionarImagenPortada(id: number): Promise<Types.ImagenPortadaResult> {
    return this.noresult_post(`/productos-imagen-portada/`, { imagen_id: id })
  }

  async inicio(): Promise<Types.InicioResult> {
    return this.simple_get(`/inicio/`)
  }

  async getFabricante(id: number): Promise<Types.FabricanteResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.get(`/fabricante/${id}/`)

    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      return { kind: 'ok', fabricante: response.data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async recuperarContrasena(email: string): Promise<Types.RecuperarContrasenaResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.post(`/recuperar-contrasena/`, { email: email })
    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data }
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }
    return { kind: 'ok' }
  }

  async categorias(web = false): Promise<Types.CategoriasResult> {
    return (await this.single_casted_get_view(`/categoria/`, { web }, 'categorias')) as Types.CategoriasResult
  }

  async updateMargen(id: string, margen: string, tipo: string, id_fabricante?: string) {
    return this.simple_patch('/updateMargen/', { id, margen, tipo, id_fabricante })
  }

  /* Control de Errores */
  async getAllErrores() {
    return this.simple_get('/all-errores/')
  }

  async getErrores(
    page: number,
    perPage: number,
    order: string,
    search: string,
    moderado: boolean,
    extras?: {},
  ): Promise<Types.ErrorResult> {
    let params = Object.assign({ moderado: moderado }, extras)
    return this.paginated_list_view(`/errores/`, page, perPage, order, search, params)
  }

  async obtenerError(id: string) {
    return this.simple_get(`/obtener-error/`, { id: id })
  }

  async controlarError(id: string) {
    return this.simple_post(`/controlar-error/`, { id: id })
  }

  async listarMargenes(tipo: number) {
    return this.simple_get('/updateMargen/', { tipo })
  }

  async guardarError(archivo: string, mensaje: string, excepcion: string) {
    return this.simple_post(`/guardar-error/`, { archivo, mensaje, excepcion })
  }

  /* Control de Errores */

  async guardarCategoria(datos: any) {
    return this.simple_id_save_or_create('categoria', datos)
  }

  async eliminarCategoria(id: number) {
    return this.simple_delete(`categoria/${id}/`)
  }

  async categoriasConfiguracion(id: any): Promise<Types.CategoriaConfiguracionResult> {
    let resp = await this.single_get_view(`/configuracion-categorias/`, { id: id })
    if (resp.kind === 'ok')
      try {
        return { kind: 'ok', categorias_configuracion: resp.data.categorias_configuracion }
      } catch {}
    return resp as Types.CategoriaConfiguracionResult
  }

  async modificarCategoriaConfiguracion(
    id: number,
    nombre: string,
    porcentaje: number,
    tipo_iva: number,
    no_apto_menores: boolean,
    eliminarImagen: boolean,
    eliminarIcono: boolean,
  ): Promise<Types.CategoriaConfiguracionModificarResult> {
    const data = {
      id: id,
      nombre: nombre,
      porcentaje: porcentaje,
      tipo_iva: tipo_iva,
      no_apto_menores: no_apto_menores,
      eliminar_imagen: eliminarImagen,
      eliminar_icono: eliminarIcono,
    }
    return this.simple_post(`/configuracion-categorias/`, data)
  }

  /**
   * Solicitar URL para creación de tarjeta
   */
  async crearTarjeta(): Promise<Types.CrearTarjetaResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }
    const response: ApiResponse<any> = await this.apisauce.post(`/bancard/tarjeta/`, {})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: 'ok', url: response.data.url, card_id: response.data.card_id }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Eliminar tarjeta en base a ID
   */
  async deleteTarjeta(card_id: string): Promise<Types.DeleteTarjetaResult> {
    return this.simple_delete(`/bancard/tarjeta/${card_id}/`)
  }

  responseIsOk<T extends Types.GenericResponse>(response: T) {
    return response.kind && response.kind === 'ok'
  }

  /**
   * Obtener las tarjetas registradas
   */
  async obtenerTarjetas(): Promise<Types.ObtenerTarjetasResult> {
    let result = await this.simple_get(`/bancard/tarjeta/`)
    return result.kind === 'ok' ? { kind: 'ok', tarjetas: result.data.tarjetas } : result
  }

  /**
   * Actualizar tarjeta
   */
  async actualizarTarjeta(tarjeta: { card_id: any }): Promise<Types.ActualizarTarjetaResult> {
    return this.simple_patch(`/bancard/tarjeta/${tarjeta.card_id}/`, tarjeta)
  }

  async pagoOcasional(email: any, phoneNumber: any) {
    return this.simple_post(`/pago-ocasional/`, { email, phoneNumber })
  }

  async crearOrdenPago(data: any) {
    return this.simple_post(`/crear-orden-pago/`, data)
  }

  async singleBuy(
    direccion: any,
    medio_pago: number,
    productos: any,
    monto: any,
    compraMejorada: boolean,
    entregaProgramada: boolean,
    hora_inicio: string | null,
    hora_fin: string | null,
    delivery: boolean,
    nroTelefono: string,
    fechaEntrega: string,
    idFacturacion: number | null,
  ): Promise<Types.SingleBuyResult> {
    if (!this.apisauce) {
      return { kind: 'unknown', temporary: true }
    }

    const response: ApiResponse<any> = await this.apisauce.post(`/single-buy/`, {
      direccion: direccion,
      medio_pago: medio_pago,
      productos: productos,
      monto: monto,
      compra_mejorada: compraMejorada,
      entrega_programada: entregaProgramada,
      hora_entrega_inicio: hora_inicio ? hora_inicio : null,
      hora_entrega_fin: hora_fin ? hora_fin : null,
      delivery: delivery,
      nro_telefono: nroTelefono,
      fecha_entrega: fechaEntrega,
      dato_facturacion: idFacturacion,
    })

    if (!response.ok) {
      if (response.status === 400) {
        return { kind: 'bad-data', errors: response.data } as any
      } else {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
    }

    try {
      return {
        kind: 'ok',
        url: response.data.url,
        transaccion_id: response.data.transaccion_id,
        exitoso: response.data.exitoso,
        mensaje_transaccion: response.data.mensaje_transaccion,
      }
    } catch {
      return { kind: 'bad-data' } as any
    }
  }

  async crearPedido(
    direccion: any,
    medio_pago: number,
    productos: any,
    tarjeta: number | null,
    monto: any,
    compraMejorada: boolean,
    entregaProgramada: boolean,
    hora_inicio: string | null,
    hora_fin: string | null,
    delivery: boolean,
    fecha_entrega: string | null,
    idFacturacion: number | null,
  ): Promise<Types.CategoriaConfiguracionModificarResult> {
    const params = {
      direccion: direccion,
      medio_pago: medio_pago,
      productos: productos,
      tarjeta: tarjeta,
      monto: monto,
      compra_mejorada: compraMejorada,
      entrega_programada: entregaProgramada,
      hora_entrega_inicio: hora_inicio,
      hora_entrega_fin: hora_fin,
      delivery: delivery,
      fecha_entrega: fecha_entrega,
      dato_facturacion: idFacturacion,
    }
    return this.simple_post(`/pedido/create/`, params)
  }

  async pedidos(params = {}): Promise<Types.PedidosResult> {
    return (await this.single_casted_get_view(`/pedido/`, params, 'pedidos')) as Types.PedidosResult
  }

  async pedidosChat(): Promise<Types.PedidosResult> {
    return (await this.single_get_view(`/pedidos-chat/`)) as Types.PedidosResult
  }

  async getPedido(id: number): Promise<Types.PedidoResult> {
    return (await this.single_casted_get_view(`/pedido/${id}/`, {}, 'pedido')) as Types.PedidoResult
  }

  async adminPedidos(
    page: number,
    perPage: number,
    order: any,
    search: string,
    extra_params?: {},
  ): Promise<Types.PedidosAdminResult> {
    return this.paginated_list_view(`/pedido_staff/`, page, perPage, order, search, extra_params)
  }

  async adminGetPedido(id: number): Promise<Types.PedidoResult> {
    return (await this.single_casted_get_view(`/pedido_staff/${id}/`, {}, 'pedido')) as Types.PedidoResult
  }

  async aprobarPedido(orden_venta_id: number, punto_retiro_id: number, fecha_retiro: string): Promise<any> {
    const params = {
      orden_venta_id: orden_venta_id,
      punto_retiro_id: punto_retiro_id,
      fecha_retiro: fecha_retiro,
    }
    return this.simple_post(`/orden_venta/aprobar/`, params)
  }

  async adminGetOrdenVenta(id: number): Promise<Types.OrdenVentaResult> {
    return (await this.single_casted_get_view(`/orden_venta/${id}/`, {}, 'orden_venta')) as Types.OrdenVentaResult
  }

  async hojasRuta(page: number, perPage: number, order: any, search: string): Promise<Types.HojasRutaResult> {
    return this.paginated_list_view(`/hoja_ruta/`, page, perPage, order, search)
  }

  async adminGetHojaRuta(id: number): Promise<Types.HojaRutaResult> {
    return (await this.single_casted_get_view(`/hoja_ruta/${id}/`, {}, 'hoja_ruta')) as Types.HojaRutaResult
  }

  async adminGetPausas(page: number, perPage: number): Promise<Types.PausasResult> {
    return (await this.paginated_list_view(`/pausas_fabricantes/`, page, perPage)) as Types.PausasResult
  }

  async adminGetPausa(id: number): Promise<Types.PausaResult> {
    return (await this.simple_get(`/pausas_fabricantes/${id}/`)) as Types.PausaResult
  }

  async adminGuardarPausa(params: Types.Pausas): Promise<Types.PausasResult> {
    if (params.id) {
      return (await this.simple_patch(`/pausas_fabricantes/${params.id}/`, params)) as Types.PausasResult
    }
    return (await this.simple_post(`/pausas_fabricantes/`, params)) as Types.PausasResult
  }

  async adminDeletePausa(id: number) {
    return await this.simple_delete(`/pausas_fabricantes/${id}/`)
  }

  async adminGetFeriados(page: number, perPage: number): Promise<Types.FeriadosResult> {
    return (await this.paginated_list_view(`/fabricante_feriado/`, page, perPage)) as Types.FeriadosResult
  }

  async adminGetFeriado(id: number): Promise<Types.FeriadoResult> {
    return (await this.simple_get(`/fabricante_feriado/${id}/`)) as Types.FeriadoResult
  }

  async adminGuardarFeriado(params: Types.Feriado): Promise<Types.FeriadosResult> {
    if (params.id) {
      return (await this.simple_patch(`/fabricante_feriado/${params.id}/`, params)) as Types.FeriadosResult
    }
    return (await this.simple_post(`/fabricante_feriado/`, params)) as Types.FeriadosResult
  }

  async adminDeleteFeriado(id: number) {
    return await this.simple_delete(`/fabricante_feriado/${id}/`)
  }

  async vehiculos(
    page: number,
    perPage: number,
    order: any,
    search: string,
    moderado: boolean,
  ): Promise<Types.VehiculosResult> {
    return this.paginated_list_view(`/vehiculo/`, page, perPage, order, search, { moderado })
  }

  async adminGetVehiculo(id: number): Promise<Types.VehiculoResult> {
    return (await this.single_casted_get_view(`/vehiculo/${id}/`, {}, 'vehiculo')) as Types.VehiculoResult
  }

  async aprobarVehiculo(vehiculo_id: number): Promise<Types.GenericResponse> {
    return this.noresult_post(`/aprobar-vehiculo/`, { vehiculo_id: vehiculo_id })
  }

  async aprobarTransportista(transportista_id: number): Promise<Types.GenericResponse> {
    return this.noresult_post(`/aprobar-transportista/`, { transportista_id })
  }

  async aprobarFabricante(fabricante_id: number): Promise<Types.GenericResponse> {
    return this.noresult_post(`/aprobar-fabricante/`, { fabricante_id })
  }

  async getPedidoContrasena(id: number): Promise<Types.PedidoContrasenaResult> {
    return this.simple_get(`/pedido-contrasena/`, { id_pedido: id })
  }

  async activarVehiculo(id: number): Promise<Types.SingleResult> {
    let respuesta = await this.simple_put(`/vehiculo/${id}/`, { activar: true })
    // casteo a SingleResult
    return respuesta.kind === 'ok' ? { kind: 'ok', data: respuesta.response } : respuesta
  }

  async oportunidades(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    moderado?: boolean,
  ): Promise<Types.HojasRutaResult> {
    return this.paginated_list_view(`/oportunidades/`, page, perPage, order, search, { moderado })
  }

  async getOportunidad(id: number): Promise<Types.SingleResult> {
    return await this.simple_get(`/oportunidad/${id}`)
  }

  async mis_rutas(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    moderado?: boolean,
  ): Promise<Types.HojasRutaResult> {
    return this.paginated_list_view(`/mis-rutas/`, page, perPage, order, search, { moderado })
  }

  async check_pedido_pin(itinerario: ItinerarioApi, pincode: string): Promise<Types.SimplePostResult> {
    return this.simple_post(`/check-pin/`, { itinerario_id: itinerario.id, pincode })
  }

  async aceptarRuta(oportunidad_id: number): Promise<Types.GenericResponse> {
    return this.simple_post(`/aceptar-ruta/`, { oportunidad_id })
  }

  async empezarRuta(hoja_ruta: HojaRutaApi, vehiculo: Vehiculo): Promise<Types.GenericResponse> {
    return this.simple_post(`/empezar-ruta/`, { ruta_id: hoja_ruta.id, vehiculo_id: vehiculo.id })
  }

  async ejecutarItinerario(
    itinerario: ItinerarioApi,
    confirmaciones: Map<number, string>,
  ): Promise<Types.GenericResponse> {
    return this.simple_post(`/ejecutar-itinerario/`, {
      itinerario_id: itinerario.id,
      // 'confirmaciones': JSON.stringify(Object.fromEntries(confirmaciones))
      pincode: confirmaciones.size > 0 ? confirmaciones.values().next().value : '', // usar solo el primero
    })
  }

  async getHojaRutaActiva(): Promise<Types.SingleResult> {
    let respuesta = await this.single_get_view(`/hoja-ruta-activa/`)
    return respuesta.kind === 'ok' ? { kind: 'ok', data: respuesta.data } : respuesta
  }

  async carrito(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    moderado?: boolean,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/carrito/`, page, perPage, order, search, { moderado })
  }
  async getFavoritos(): Promise<Types.ListResult> {
    return this.single_list_view('/favorito/')
  }
  async addFavorito(fav_id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/favorito/`, {
      producto: fav_id,
    })
  }
  async updateFavorito(id: number, act: boolean): Promise<Types.SingleResult> {
    const respuesta = await this.simple_put(`/favorito/${id}/`, { activo: act })
    return respuesta.kind === 'ok' ? { kind: 'ok', data: respuesta.response } : respuesta
  }

  async fechaEstimada(): Promise<Types.SingleResult> {
    return this.single_get_view(`/fecha-estimada/`)
  }

  async costoEnvio(costoCarrito: number): Promise<Types.SingleResult> {
    return this.single_get_view(`/costo_envio/${costoCarrito}/`)
  }

  async ordenesVenta(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    extras?: {},
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/orden_venta/`, page, perPage, order, search, extras)
  }

  async agregarEliminarProductoCarrito(
    producto_id: number | null,
    precio_mejorado?: boolean | null,
    cantidad?: number,
    eliminar?: boolean,
    variante?: any | null,
  ): Promise<Types.SimplePostResult> {
    return this.simple_post(`/carrito/`, {
      producto_id: producto_id,
      eliminar: eliminar,
      cantidad: cantidad,
      precio_mejorado: precio_mejorado,
      variante: variante,
    })
  }

  async verificarTransaccion(transaccion_id: string, tipo: string): Promise<Types.SingleResult> {
    return this.single_get_view(`/verificar-transaccion/`, { transaccion_id: transaccion_id, tipo: tipo })
  }

  async calificar(
    pedido_id?: number,
    tipo_calificacion?: number,
    id_calificar?: number,
    calificacion?: number,
    comentario?: string,
  ): Promise<Types.GenericResponse> {
    return this.simple_post(`/calificar/`, {
      pedido_id: pedido_id,
      tipo_calificacion: tipo_calificacion,
      id_calificar: id_calificar,
      calificacion: calificacion,
      comentario: comentario,
    })
  }

  async verificar_calificacion(
    pedido_id: number,
    tipo_calificacion: number,
    id_calificar: number,
  ): Promise<Types.PedidoContrasenaResult> {
    return this.simple_get(`/verificar-calificacion/`, {
      pedido_id: pedido_id,
      tipo_calificacion: tipo_calificacion,
      id_calificar: id_calificar,
    })
  }

  async inicioDashboard(): Promise<Types.InicioDashboardResult> {
    return this.simple_get(`/inicio-dashboard/`)
  }

  async cambiar_modo_app(modo: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/cambiar-modo-app/`, { modo: modo })
  }

  async fabricantesList(): Promise<Types.ListResult> {
    return this.single_get_view(`/fabricantes/`)
  }

  async transportistaList(): Promise<Types.ListResult> {
    return this.single_get_view(`/transportistas/`)
  }

  async rendicionStaff(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    tipo?: number,
    id?: number,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/rendicion/`, page, perPage, order, search, { tipo, id })
  }

  async generarFacturaRendicion(
    timbrado: string,
    numeroFacura: string,
    fecha: string,
    ordenes: any,
    tipo: number,
    personaId: number,
  ): Promise<Types.SimplePostResult> {
    return this.simple_post(`/rendicion/`, {
      timbrado: timbrado,
      numero_factura: numeroFacura,
      fecha: fecha,
      ordenes: ordenes,
      tipo: tipo,
      persona_id: personaId,
    })
  }

  async registrarPagoProveedor(
    fund_account: any,
    monto: string,
    facturas: any,
    tipo: number,
    personaId: number,
    fecha: string,
  ): Promise<Types.SimplePostResult> {
    return this.simple_post(`/registrar_pago/`, {
      tipo: tipo,
      persona_id: personaId,
      fund_account: fund_account,
      monto: monto,
      facturas: facturas,
      fecha: fecha,
    })
  }

  async pagoProveedoresStaff(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    tipo?: number,
    id?: number,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/registrar_pago/`, page, perPage, order, search, { tipo, id })
  }

  async facturas(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    cliente?: boolean,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/factura/`, page, perPage, order, search, { cliente })
  }

  async pagos(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    cliente?: boolean,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/pago/`, page, perPage, order, search, { cliente })
  }

  async getFactura(id: number): Promise<SingleResult> {
    return this.single_get_view(`/factura/${id}/`)
  }

  async getFacturaPdf(id: number): Promise<SingleResult> {
    return this.single_get_view(`/pdf_download/${id}/`, {}, { responseType: 'arraybuffer' })
  }

  getUrlFacturaPdf(id: number): string {
    return `${this.apisauce?.getBaseURL()}/pdf_download/${id}`
  }

  async balanceList(
    page?: any,
    perPage?: any,
    order?: any,
    search?: any,
    cobrado?: boolean,
    facturado?: boolean,
    tipo?: number,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/balance/`, page, perPage, order, search, { cobrado, facturado, tipo })
  }
  async balanceListTransportista(
    cobrado?: boolean,
    facturado?: boolean,
    tipo?: number,
    fecha__lt?: string | null,
    fecha__gt?: string | null,
  ): Promise<Types.ListResult> {
    return this.single_get_view(`/balance/`, { cobrado, facturado, tipo, fecha__lt, fecha__gt })
  }

  async movimientosTransportista(
    page?: number,
    perPage?: number,
    order?: any,
    search?: string,
    moderado?: boolean,
  ): Promise<Types.ListResult> {
    return this.paginated_list_view(`/transportista/movimientos/`, page, perPage, order, search, { moderado })
  }

  async getBalanceDetail(tipo: number, fecha__lt?: string | null, fecha__gt?: string | null): Promise<SingleResult> {
    // TODO limpiar este 0
    let respuesta = await this.single_get_view(`/balance/` + 0 + '/', { tipo, fecha__lt, fecha__gt })
    return respuesta.kind === 'ok' ? { kind: 'ok', data: respuesta.data } : respuesta
  }

  async getTiempoConectadoTransportista(
    FechaFin?: string | null,
    FechaInicio?: string | null,
  ): Promise<Types.TiempoConectadoResult> {
    return await this.single_get_view('/tiempo-activo-transportista/', { fecha__lt: FechaFin, fecha__gt: FechaInicio })
  }

  async setConexionTransportista(activo: boolean): Promise<Types.SetConexionTransportistaResult> {
    if (activo) {
      return await this.simple_post('/conexion-transportista/')
    } else {
      return await this.simple_patch('/conexion-transportista/')
    }
  }

  async accionApi(accion: string, datos: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api-config/`, { accion, datos })
  }

  async verApi(): Promise<SingleResult> {
    return this.single_get_view(`/api-status/`)
  }

  async getHeaderToken(): Promise<SingleResult> {
    return this.simple_get_header(`/api-status/`)
  }

  async getProductoAdmin(id: number): Promise<SingleResult> {
    return await this.single_get_view(`/producto/${id}/`)
  }

  async adminFabricanteVentas(
    search: string,
    estado?: number | null,
    fecha__gt?: string | null,
    fecha__lt?: string | null,
  ): Promise<Types.PedidosAdminResult> {
    return this.single_get_view(`/orden_venta/`, { search, estado, fecha__gt, fecha__lt })
  }

  async adminProductosVentasResumen(
    fecha__gt?: string | null,
    fecha__lt?: string | null,
  ): Promise<Types.ProductosVentaResumenResult> {
    return this.single_get_view(`/productos/ventas/resumen/`, { fecha__gt, fecha__lt })
  }

  async adminProductosVentasListado(filtros?: any): Promise<Types.ProductosVentaListResult> {
    return this.single_get_view(`/productos/ventas/listado/`, filtros)
  }

  async adminFabricanteCatalogo(filtros: any): Promise<Types.ProductosResult> {
    return this.single_get_view(`/producto/`, filtros)
  }

  async adminGetFabricante(id: number): Promise<Types.FabricanteResult> {
    return (await this.single_casted_get_view(`/fabricante/${id}/`, {}, 'fabricante')) as Types.FabricanteResult
  }

  async adminGetTipoVehiculo(id: number): Promise<Types.TipoVehiculoResult> {
    return this.single_get_view(`/tipo-vehiculo/${id}/`)
  }

  async adminGetPuntoRetiro(id: number): Promise<Types.GuardarPuntoRetiroResult> {
    return (await this.single_casted_get_view(
      `/punto-retiro/${id}/`,
      {},
      'punto_retiro',
    )) as Types.GuardarPuntoRetiroResult
  }

  async fabricanteGetOrdenVenta(id: number): Promise<Types.OrdenVentaResult> {
    return (await this.single_casted_get_view(`/orden_venta/${id}/`, {}, 'orden_venta')) as Types.OrdenVentaResult
  }

  async rastrearUsuario(datos: any): Promise<Types.NoResponsePostResult> {
    return this.noresult_post('/rastreo-usuario/', datos)
  }

  async rastrearPedido(id: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/rastrear-pedido/${id}/`)
  }

  async reportarIncidente(
    tipo_incidente: number,
    hoja_ruta: number,
    itinerario: number,
    vehiculo: number,
    ubicacion: string,
  ): Promise<Types.SimplePostResult> {
    return this.simple_post(`/reportar-incidente/`, {
      tipo_incidente: tipo_incidente,
      hoja_ruta: hoja_ruta,
      itinerario: itinerario,
      vehiculo: vehiculo,
      ubicacion: ubicacion,
    })
  }

  async loginFacebook(accessToken: string): Promise<Types.LoginResult> {
    let res = await this.simple_post(`/fb-login/`, { accessToken: accessToken })
    return res.kind === 'ok' ? { kind: 'ok', user: res.response } : res
  }

  async loginGoogle(data: object, token: string): Promise<Types.LoginResult> {
    let res = await this.simple_post(`/google-login/`, {
      data: data,
      token: token,
    })
    return res.kind === 'ok' ? { kind: 'ok', user: res.response } : res
  }

  async loginApple(token: string): Promise<Types.LoginResult> {
    let res = await this.simple_post(`/apple-login/`, {
      data: token,
    })
    return res.kind === 'ok' ? { kind: 'ok', user: res.response } : res
  }

  async getReportes(tipo: number, filtros: any): Promise<SimplePostResult> {
    return this.simple_post(`/exportar-reporte/`, { tipo: tipo, filtros: filtros }, { responseType: 'arraybuffer' })
  }

  async getReportesPedidos(): Promise<Types.SimplePostResult> {
    return this.simple_post(`/exportar-reporte-pedidos/`)
  }

  async getFundAccounts(): Promise<Types.FundAccountsResult> {
    return this.single_list_view(`/fund-accounts/`)
  }

  async getCategoriasHeader(): Promise<any> {
    const result = await this.simple_get('/inicio-header/')
    return result.kind === 'ok' ? { kind: 'ok', data: result.data } : result
  }

  async verificarUsuario(email: string): Promise<Types.NoResponsePostResult> {
    return this.simple_post(`/user-fab-verificar/`, { email })
  }

  async agregarUsuarioAFabricante(idFab: number, email: string): Promise<Types.NoResponsePostResult> {
    return this.simple_post(`/user-fab-agregar/`, { fabricante: idFab, email })
  }

  async guardarDatoFacturacionUsuario(params: {
    id: null | number
    ruc: string
    razon_social: string
  }): Promise<Types.SimplePostResult> {
    if (params.id) {
      return this.simple_patch(`/datos-facturacion/${params.id}/`, params)
    }
    return this.simple_post(`/datos-facturacion/`, params)
  }

  async eliminarDatoFacturacion(id: number) {
    return this.simple_delete(`/datos-facturacion/${id}/`)
  }

  async consultarRuc(params: {}): Promise<Types.SimplePostResult> {
    return this.simple_post(`/consulta-ruc/`, params)
  }

  async infoCalle(lat: number, lon: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/info-calle/`, { lat, lon })
  }

  async infoPunto(calle: string, ubicacion?: LatLngTuple): Promise<Types.SimplePostResult> {
    return this.simple_post(`/info-calle/`, { calle, ubicacion })
  }

  async searchInfoCalle(search: string, ubicacion?: LatLngTuple): Promise<Types.SimplePostResult> {
    return this.simple_post(`/info-calle/`, { search, ubicacion })
  }

  async torre_de_control(params: {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/torre-de-control/`, params)
  }

  async getContrato(tipo: string): Promise<SingleResult> {
    return this.single_get_view(`/descargar-contrato/`, { tipo }, { responseType: 'arraybuffer' })
  }

  async aceptarContrato(tipo: string): Promise<SimplePostResult> {
    return this.simple_post(`/aceptar-contrato/`, { tipo })
  }

  async calificacionesList(id: number, tipo: string): Promise<Types.ListResult> {
    return this.single_list_view(`/obtener-calificaciones/`, { id: id, tipo: tipo })
  }

  async preguntas_frecuentes_list(search: string): Promise<Types.ListResult> {
    return this.single_list_view(`/preguntas-frecuentes/`, { search })
  }

  async enviar_mensaje(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/enviar-mensaje/`, { data })
  }
  async getMensajesPredeterminados(): Promise<Types.ListResult> {
    return this.single_list_view(`/chat/mensajes/predeterminados/`)
  }
  async getMensajesChat(idPedido: number, tipoChat: number, idFabricante: number): Promise<Types.MensajesResult> {
    return this.simple_get(`/chat/mensajes/${idPedido}/${tipoChat}/${idFabricante}/`)
  }

  async busquedas(page?: number, perPage?: number, order?: any, search?: string): Promise<Types.ClientesResult> {
    return this.paginated_list_view(`/busqueda/`, page, perPage, order, search)
  }

  async horariosProveedores(fabricantes_ids: Array<String>) {
    return this.simple_get('horarios-fabricantes', { fabricantes_ids })
  }

  async cargarArchivo(archivo: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/cargar-archivo/`, { archivo })
  }

  async borrarCuenta(borrarDatos: boolean): Promise<Types.SimplePostResult> {
    return this.simple_post(`/borrar-cuenta/`, { borrarDatos })
  }

  async getListadoProductos(): Promise<Types.SimplePostResult> {
    return this.simple_post(`/listado-productos/`)
  }

  async getGruposMedidas(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/grupos-medidas/`)
  }

  async getUnidadesMedidas(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/unidades-medidas/`)
  }

  async getVariantesProducto(id: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/variantes-productos/`, { id })
  }

  async crearVarianteProducto(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/variantes-productos/`, data)
  }

  async eliminarVarianteProducto(id: any): Promise<Types.GenericResponse> {
    return this.simple_delete(`/borrar-variantes-productos/${id}`)
  }

  async modificarVarianteProducto(data: any): Promise<Types.GenericResponse> {
    return this.simple_patch(`/modificar-variante/`, { data })
  }
}
