import { types } from 'mobx-state-tree'

export const VarianteCarrito = types
.model('VarianteCarrito')
.props({
    id: types.number,
    color: types.maybeNull(types.string),
    medida: types.maybeNull(types.string),
    precio: types.number,
    cantidad_disponible: types.number,
    imagenes: types.maybeNull(types.string),
    cantidad: types.optional(types.number, 0),
})
.views((self) => ({}))
.actions((self) => ({
    setCantidad(cantidad: number) {
        self.cantidad = cantidad
    },
})) // eslint-disable-line @typescript-eslint/no-unused-vars