import {types} from "mobx-state-tree"

export const PedidoCalificacionModel = types
    .model("PedidoCalificacionModel")
    .props({
        id_pedido: types.number,
        id_calificar: types.number,
        tipo_calificacion: types.number,
        nombre: types.string,
        imagen: types.maybeNull(types.string),
    })
    .views(self => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

export default PedidoCalificacionModel
