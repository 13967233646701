import { SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { withRootStore } from '..'
import TipoVehiculo from './TipoVehiculo'
import { convertCliente } from './Cliente'

export interface VehiculoType extends SnapshotOut<typeof Vehiculo> {}

export const Vehiculo = types
  .model('Vehiculo')
  .extend(withRootStore)
  .props({
    id: types.number,
    transportista: types.maybeNull(types.number),
    tipo_vehiculo: TipoVehiculo,
    marca: types.string,
    modelo: types.string,
    ano: types.number,
    color: types.string,
    numero_chapa: types.string,
    numero_chassis: types.string,
    kilometraje: types.number,
    poliza_seguro: types.maybeNull(types.string),
    habilitacion_frente: types.string,
    habilitacion_dorso: types.string,
    habilitacion_vencimiento: types.string,
    peso_maximo: types.maybeNull(types.number),
    espacio_alto_cm: types.maybeNull(types.number),
    espacio_ancho_cm: types.maybeNull(types.number),
    espacio_largo_cm: types.maybeNull(types.number),
    aprobado: types.boolean,
    activo: types.boolean,
    fecha_agregado: types.string,
    cantidad_viajes: types.number,
    ultimo_viaje: types.maybeNull(types.string),
  })
  .views((self) => ({
    get tipoVehiculo() {
      return self.rootStore.tiposVehiculos.find((tveh: any) => tveh.id === self.tipo_vehiculo).nombre
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars 


export default Vehiculo


export const convertVehiculo = (veh: any) => {
  if (!veh) {
    return null
  }
  return {
    id: veh.id,
    transportista: veh.transportista,
    tipo_vehiculo: veh.tipo_vehiculo,
    marca: veh.marca,
    modelo: veh.modelo,
    ano: veh.ano,
    color: veh.color,
    numero_chapa: veh.numero_chapa,
    numero_chassis: veh.numero_chassis,
    kilometraje: veh.kilometraje,
    poliza_seguro: veh.poliza_seguro,
    habilitacion_frente: veh.habilitacion_frente,
    habilitacion_dorso: veh.habilitacion_dorso,
    habilitacion_vencimiento: veh.habilitacion_vencimiento,
    aprobado: veh.aprobado,
    activo: veh.activo,
    fecha_agregado: veh.fecha_agregado,
    cantidad_viajes: veh.cantidad_viajes,
    ultimo_viaje: veh.ultimo_viaje,
  } as any
}
